import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  Grid,
  InlineStack,
  Page,
  Text,
  TextField,
  BlockStack,
  ChoiceList,
  RadioButton,
  Icon,
} from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import TagsPicker from "../components/back-in-stock/tags-picker";

const BackInStock_setting_conditions = () => {
  let navigate = useNavigate();
  let handleBackAction = () => {
    navigate("/back-in-stock");
  };

  let [trackQty, setTrackQty] = useState([]);
  let [inventoryPolicy, setInventoryPolicy] = useState([]);
  let [showBackInStock, setShowBackInStock] = useState([]);
  let [showTags, setShowTags] = useState([]);
  let [hideTags, setHideTags] = useState([]);

  let handleSubmit = () => {
    let data = {
      trackQty: trackQty[0],
      inventoryPolicy: inventoryPolicy[0],
      showBackInStock: {
        selected: showBackInStock[0],
        data: showTags,
      },
      hideBackInStock: {
        tags: hideTags,
      },
    };
    console.log(data);
  };

  return (
    <Page title="Conditions" backAction={{ onAction: handleBackAction }}>
      <BlockStack gap={"300"}>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              With track quantity
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <ChoiceList
                title={
                  <h1 className="h-16">
                    Display only out-of-stock products with track quantity.
                  </h1>
                }
                choices={[
                  { label: "Track quantity", value: "track" },
                  { label: "Untrack quantity", value: "untrack" },
                  { label: "Both", value: "both" },
                ]}
                selected={trackQty}
                onChange={setTrackQty}
              />
            </Card>
          </Grid.Cell>
        </Grid>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              With inventory policy
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <ChoiceList
                title={
                  <h1 className="h-16">
                    Display only out-of-stock products with an active inventory
                    policy.
                  </h1>
                }
                choices={[
                  {
                    label: "Stop selling when the product is out of stock",
                    value: "stop",
                  },
                  {
                    label: "Continue selling when the product is out of stock",
                    value: "continue",
                  },
                  { label: "Both", value: "both" },
                ]}
                selected={inventoryPolicy}
                onChange={setInventoryPolicy}
              />
            </Card>
          </Grid.Cell>
        </Grid>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              Show back in stock
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <ChoiceList
                title={
                  <h1 className="h-16">
                    Display products marked as back in stock.
                  </h1>
                }
                choices={[
                  {
                    label: "All products out of stock ",
                    value: "all",
                  },
                  {
                    label: "Choose products",
                    value: "products",
                    helpText: showBackInStock.includes("products") && (
                      <TextField
                        type="search"
                        prefix={<Icon source={SearchIcon} />}
                        placeholder="Search"
                      />
                    ),
                  },
                  {
                    label: "Tag(s)",
                    value: "tags",
                    helpText: showBackInStock.includes("tags") && (
                      <TagsPicker
                        placeholder="Enter tags"
                        tags={showTags}
                        onChange={setShowTags}
                      />
                    ),
                  },
                  {
                    label: "Collection(s)",
                    value: "collections",
                    helpText: showBackInStock.includes("collections") && (
                      <TextField
                        type="search"
                        prefix={<Icon source={SearchIcon} />}
                        placeholder="Search"
                      />
                    ),
                  },
                ]}
                selected={showBackInStock}
                onChange={setShowBackInStock}
              />
            </Card>
          </Grid.Cell>
        </Grid>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              Hide back in stock
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <BlockStack gap={"300"}>
                <h1 className="h-16">Hide products marked as back in stock.</h1>
                <Text as="p" variant="bodyMd">
                  Select products you don’t want to display as back in stock
                  based on tag and collection.
                </Text>
                <TagsPicker
                  label="Tag(s)"
                  placeholder="Enter tags"
                  tags={hideTags}
                  onChange={setHideTags}
                />
                <TextField
                  label="Collection(s)"
                  placeholder="Search"
                  prefix={<Icon source={SearchIcon} />}
                />
              </BlockStack>
            </Card>
          </Grid.Cell>
        </Grid>
        <InlineStack align="end">
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </InlineStack>
      </BlockStack>
    </Page>
  );
};

export default BackInStock_setting_conditions;
