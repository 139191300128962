import {
  BlockStack,
  Box,
  Button,
  Checkbox,
  Collapsible,
  InlineStack,
  Modal,
  Select,
  Text,
  TextField,
} from "@shopify/polaris";
import { DragHandleIcon, DeleteIcon, PlusIcon } from "@shopify/polaris-icons";
import { useEffect, useState } from "react";
import StringToHtml from "../../utils/StringToHtml";

const FieldSettingItem = ({
  index,
  field,
  onFieldChange,
  onFieldRemove,
  isLastItem,
  isOpen,
  onToggle,
  onDragCustomStart,
  onCustomDrop,
}) => {
  const countryOptions = [
    { label: "Pakistan", value: "pk" },
    { label: "India", value: "in" },
    { label: "United States", value: "us" },
  ];

  let [selectOptions, setSelectedOptions] = useState([]);

  let handleOptionChange = (property, optionIndex, value) => {
    let newOptions = [...field[property]];
    newOptions[optionIndex] = value;
    onFieldChange(index, property, newOptions);
  };

  let handleOptionRemove = (property, optionIndex) => {
    let newOptions = [...field[property]];
    newOptions.splice(optionIndex, 1);
    onFieldChange(index, property, newOptions);
  };

  useEffect(() => {
    if (field.options) {
      let options = [{ label: "Please select", value: "" }];
      let newOptions = field.options.map((option) => ({
        label: option,
        value: option,
      }));
      setSelectedOptions(options.concat(newOptions));
    }
  }, [field]);

  let [newOptionValue, setNewOptionValue] = useState("option value");
  let [isAddOptionModalOpen, setIsAddOptionModalOpen] = useState(false);
  let toggleAddOptionModal = () => {
    setIsAddOptionModalOpen(!isAddOptionModalOpen);
  };
  let addOptionSubmit = () => {
    if (newOptionValue) {
      onFieldChange(index, "options", field.options.concat(newOptionValue));
      setNewOptionValue("option value");
      toggleAddOptionModal();
    }
  };

  return (
    <Box
      paddingInline={"300"}
      paddingBlock={"200"}
      borderColor="border"
      borderBlockEndWidth={isLastItem ? "0" : "025"}
    >
      <InlineStack align="space-between" wrap={false} blockAlign="start">
        <Text as="p" variant="bodyMd">
          <StringToHtml htmlString={field.label} />
        </Text>
        <InlineStack gap={"200"} wrap={false} blockAlign="center">
          <div
            draggable
            onDragStart={() => onDragCustomStart(index)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={() => onCustomDrop(index)}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button icon={DragHandleIcon} variant="monochromePlain" />
          </div>
          <Button
            icon={DeleteIcon}
            variant="monochromePlain"
            onClick={() => onFieldRemove(index)}
            disabled={field.type == "email"}
          />
          <Button
            variant="monochromePlain"
            disclosure={isOpen ? "up" : "down"}
            onClick={onToggle}
          />
        </InlineStack>
      </InlineStack>
      <Collapsible
        open={isOpen}
        transition={{
          duration: "500ms",
          timingFunction: "ease-in-out",
        }}
      >
        <Box paddingBlockStart={"300"}>
          <BlockStack gap={"300"}>
            {"showLabel" in field && (
              <Checkbox
                label="Show label"
                checked={field.showLabel}
                onChange={(value) => onFieldChange(index, "showLabel", value)}
              />
            )}
            {field.showLabel && (
              <TextField
                label="Label"
                value={field.label}
                onChange={(value) => onFieldChange(index, "label", value)}
              />
            )}
            {"placeholder" in field && (
              <TextField
                label="Placeholder"
                value={field.placeholder}
                onChange={(value) => onFieldChange(index, "placeholder", value)}
              />
            )}
            {field.type == "phone" && (
              <Select
                label="Select default country"
                options={countryOptions}
                value={field.defaultSelected}
                onChange={(value) =>
                  onFieldChange(index, "defaultSelected", value)
                }
              />
            )}
            {field?.options?.map((option, optionIndex) => (
              <TextField
                key={optionIndex}
                label={`Option ${optionIndex + 1}`}
                value={option}
                onChange={(value) =>
                  handleOptionChange("options", optionIndex, value)
                }
                labelAction={{
                  content: "Delete",
                  onAction: () => handleOptionRemove("options", optionIndex),
                }}
              />
            ))}
            {"options" in field && (
              <>
                <InlineStack align="start">
                  <Button icon={PlusIcon} onClick={toggleAddOptionModal}>
                    Add option
                  </Button>
                </InlineStack>
                <Select
                  label="Select default option"
                  options={selectOptions}
                  value={field.defaultSelected}
                  onChange={(value) =>
                    onFieldChange(index, "defaultSelected", value)
                  }
                />
              </>
            )}
            {"optionsPerLine" in field && (
              <TextField
                type="number"
                min={1}
                label="Number of options per line"
                value={field.optionsPerLine}
                onChange={(value) =>
                  onFieldChange(index, "optionsPerLine", value)
                }
              />
            )}
            {field.type == "accept-terms" && (
              <>
                <TextField
                  label="Label"
                  value={field.label}
                  onChange={(value) => onFieldChange(index, "label", value)}
                />
                <TextField
                  label="Value"
                  value={field.defaultValue}
                  onChange={(value) =>
                    onFieldChange(index, "defaultValue", value)
                  }
                />
                <Checkbox
                  label="Default selected"
                  checked={field.defaultSelected}
                  onChange={(value) =>
                    onFieldChange(index, "defaultSelected", value)
                  }
                />
              </>
            )}
            {"required" in field && (
              <Checkbox
                label="Required"
                checked={field.required}
                onChange={(value) => onFieldChange(index, "required", value)}
              />
            )}
            {"showRequired" in field && (
              <Checkbox
                label="Show required"
                checked={field.showRequired}
                onChange={(value) =>
                  onFieldChange(index, "showRequired", value)
                }
              />
            )}
            {field.type == "hidden" && (
              <>
                <TextField
                  label="Label"
                  value={field.label}
                  onChange={(value) => onFieldChange(index, "label", value)}
                />
                <TextField
                  label="Value"
                  value={field.defaultValue}
                  onChange={(value) =>
                    onFieldChange(index, "defaultValue", value)
                  }
                />
              </>
            )}
          </BlockStack>
        </Box>
      </Collapsible>
      {/* add options modal */}
      <Modal
        title="Add option"
        primaryAction={{ content: "Add", onAction: addOptionSubmit }}
        open={isAddOptionModalOpen}
        onClose={toggleAddOptionModal}
      >
        <Box padding={"400"}>
          <TextField
            label="New option"
            value={newOptionValue}
            onChange={setNewOptionValue}
            error={newOptionValue == "" && "This field is required"}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default FieldSettingItem;
