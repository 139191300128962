import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Page,
  Button,
  LegacyCard,
  Checkbox,
  RadioButton,
  TextField,
  Select,
  Toast,
  Modal,
  Text,
  Tooltip,
  Icon,
  LegacyStack,
  Layout,
  AppProvider,
} from "@shopify/polaris";
import { handlePageRefresh } from "../helpers/commonFunctions";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ApiCall, ShopifyCall } from "../helpers/axios";
import { setSingeProductData } from "../redux/singleProductSlice";
import noimg from "../assets/images/noimg.jpg";
import {
  htmlSpecialCharacterEncode,
  htmlspecialchars_decode,
} from "../helpers/commonFunctions";
import Footer from "./Footer";
import { InfoIcon } from "@shopify/polaris-icons";
import {
  Provider as AppBridgeProvider,
  TitleBar,
} from "@shopify/app-bridge-react";
import { config } from "../config";

const EditSingleProduct = () => {
  const [checked, setChecked] = useState([false]);
  const navigate = useNavigate();
  let dispatch = useDispatch();
  let { product_id } = useParams();
  const [settingType, setSettingType] = useState([false]);
  const [isOpen, setIsOpen] = useState([true]);
  const [activeToast, setActiveToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [activeModal, setActiveModal] = useState(false);
  const [varientIndex, setVarientIndex] = useState("");
  const [dbData, setDbData] = useState([]);
  const single_product_data = useSelector(
    (state) => state.singleProductReducer.single_product_data
  );
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const store_name = shop_data.store_name;
  const [variant, setvariant] = useState([]);
  let header = { authentication: shopinfo.auth_token };
  const [productImage, setProductImage] = useState({});
  const [productData, setProductData] = useState({});
  const [productVariants, setProductVariants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openTab, setOpenTab] = useState();

  const [initialState, setInitialState] = useState({
    text: [],
    message: [],
    message_placement: [],
    enable_inventary: [],
    method: [],
    order_start_date: [],
    order_end_date: [],
    cart_label_text: [],
    inventory_management: [],
    inventory_quantity: [],
    variant_name: [],
    prevent_customers: [],
    order_remain_message: [],
    order_message: [],
    notify_customers: [],
    buy_now: [],
  });

  const formSchema = Yup.object().shape({
    text: Yup.string().required("Button text is required"),
    cart_label_text: Yup.string().required("Cart label text is required"),
  });

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    onSubmit: (values) => {
      productSave(values);
    },
  });

  useEffect(() => {
    if (!product_id && Object.keys(single_product_data).length === 0) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (single_product_data) {
      window.addEventListener("beforeunload", handlePageRefresh);
      setvariant(
        Object.keys(single_product_data).length > 0 &&
          single_product_data.product_settings &&
          single_product_data.product_settings !== ""
          ? Object.values(JSON.parse(single_product_data.product_settings))
          : ""
      );
      setOpenTab(
        Object.keys(single_product_data).length > 0 &&
          single_product_data.product_settings &&
          Object.values(JSON.parse(single_product_data.product_settings))[0]
            .product_variant_name
      );
    } else {
      window.removeEventListener("beforeunload", handlePageRefresh);
    }
  }, [single_product_data]);

  const getProductDetail = async (id) => {
    setLoading(true);
    if (Object.keys(shop_data).length > 0) {
      let data = {
        store_client_id: store_client_id,
        shop_info: shop_data,
        shop: store_name,
      };
      let res = await ShopifyCall(
        "POST",
        `/product-detail?product_id=${id}`,
        data,
        header
      );

      if (res.data.status === "success" && res.data.statusCode === 200) {
        setProductData(res.data.data);
        dispatch(setSingeProductData(res?.data?.data.db_data));
        setDbData(res?.data?.data.db_data);
        setProductImage(res.data.data.image);
        setProductVariants(res.data.data.variants);
        setOpenTab(res.data.data.variants[0].title);
        setLoading(false);
      } else if (res.data.status === "error") {
        setProductData([]);
        setProductImage({});
        setProductVariants([]);
        setLoading(true);
      }
    }
  };

  useEffect(() => {
    if (product_id) {
      getProductDetail(product_id);
    }
  }, [product_id, shop_data]);

  const productSave = async (values) => {
    setLoading(true);
    if (productData && Object.keys(productData).length > 0) {
      let product_data = {
        image: productData.image ? productData.image.src : "",
        tags: productData.tags,
        title: productData.title,
      };
      let data = {
        store_client_id: store_client_id,
        product_id: product_id,
        product_title: productData.title,
        product_data: product_data,
        is_variant_product: productVariants.length > 1 ? 1 : 0,
      };
      let status_arr = [];

      let obj = {};
      if (productVariants && productVariants.length > 0) {
        productVariants.map((item, index) => {
          obj[item.id] = {
            is_enable: checked[index] ? "1" : "0",
            product_inventory_quantity:
              values.inventory_quantity[index] !== undefined
                ? values.inventory_quantity[index]
                : "",
            product_inventory_management:
              values.inventory_management[index] !== undefined
                ? values.inventory_management[index]
                : "",
            product_variant_name:
              values.variant_name[index] !== undefined
                ? values.variant_name[index]
                : "Default Title",
          };
          status_arr.push(obj[item.id].is_enable);
          if (checked[index] && settingType[index]) {
            obj[item.id] = {
              is_enable: "1",
              is_custome_setting: settingType[index] ? "1" : "0",
              product_text:
                values.text[index] !== undefined
                  ? htmlSpecialCharacterEncode(values.text[index])
                  : "",
              product_message:
                values.message[index] !== undefined
                  ? htmlSpecialCharacterEncode(values.message[index])
                  : "",
              product_message_placement:
                values.message_placement[index] !== undefined
                  ? values.message_placement[index]
                  : "1",
              product_enable_inventary:
                values.enable_inventary[index] !== undefined
                  ? values.enable_inventary[index]
                  : "",
              product_method:
                values.method[index] !== undefined ? values.method[index] : "0",
              product_order_start_date:
                values.order_start_date[index] !== undefined
                  ? values.order_start_date[index]
                  : "",
              product_order_end_date:
                values.order_end_date[index] !== undefined
                  ? values.order_end_date[index]
                  : "",
              product_cart_label_text:
                values.cart_label_text[index] !== undefined
                  ? htmlSpecialCharacterEncode(values.cart_label_text[index])
                  : "",
              product_variant_name:
                values.variant_name[index] !== undefined
                  ? values.variant_name[index]
                  : "Default Title",
              product_inventory_management:
                values.inventory_management[index] !== undefined
                  ? values.inventory_management[index]
                  : "",
              product_inventory_quantity:
                values.inventory_quantity[index] !== undefined
                  ? values.inventory_quantity[index]
                  : "",
              product_prevent_customers:
                values.prevent_customers[index] !== undefined
                  ? values.prevent_customers[index]
                  : "",
              product_order_remain_message:
                values.order_remain_message[index] !== undefined
                  ? htmlSpecialCharacterEncode(
                      values.order_remain_message[index]
                    )
                  : "",
              product_order_message:
                values.order_message[index] !== undefined
                  ? htmlSpecialCharacterEncode(values.order_message[index])
                  : "",
              product_notify_customers:
                values.notify_customers[index] !== undefined
                  ? values.notify_customers[index]
                  : "",
              product_buy_now:
                values.buy_now[index] !== undefined
                  ? values.buy_now[index]
                  : "",
            };
          }
          if (checked[index]) {
            obj[item.id].is_custome_setting = settingType[index] ? "1" : "0";
          }
        });
        data.setting_data = obj;
        if (status_arr.includes("0") && status_arr.includes("1")) {
          data.product_variant_status = "2";
        } else if (status_arr.includes("1")) {
          data.product_variant_status = "1";
        } else if (status_arr.includes("0")) {
          data.product_variant_status = "0";
        }
      }
      let res = await ApiCall("POST", "/single-product", data, header);
      if (res.data.statusCode === 200) {
        setToastMessage(res.data.message);
        toggleActiveToast();
        setLoading(false);
        setTimeout(() => navigate("/"), 2000);
      }
    } else {
      let data = {
        id: single_product_data.id
          ? parseInt(single_product_data.id)
          : product_id,
      };
      if (single_product_data && Object.keys(single_product_data).length > 0) {
        let obj = {};
        let status_arr = [];
        if (single_product_data.product_settings !== "") {
          Object.keys(JSON.parse(single_product_data.product_settings)).map(
            (item, index) => {
              obj[item] = {
                is_enable: checked[index] ? "1" : "0",
                product_inventory_quantity:
                  values.inventory_quantity[index] !== undefined
                    ? values.inventory_quantity[index]
                    : "",
                product_inventory_management:
                  values.inventory_management[index] !== undefined
                    ? values.inventory_management[index]
                    : "",
                product_variant_name:
                  values.variant_name[index] !== undefined
                    ? values.variant_name[index]
                    : "Default Title",
              };
              status_arr.push(obj[item].is_enable);
              if (checked[index]) {
                obj[item].is_custome_setting = settingType[index] ? "1" : "0";
              }
              if (checked[index] && settingType[index]) {
                obj[item] = {
                  is_enable: "1",
                  is_custome_setting: "1",
                  product_text:
                    values.text[index] !== undefined
                      ? htmlSpecialCharacterEncode(values.text[index])
                      : "",
                  product_message:
                    values.message[index] !== undefined
                      ? htmlSpecialCharacterEncode(values.message[index])
                      : "",
                  product_message_placement:
                    values.message_placement[index] !== undefined
                      ? values.message_placement[index]
                      : "1",
                  product_enable_inventary:
                    values.enable_inventary[index] !== undefined
                      ? values.enable_inventary[index]
                      : "",
                  product_method:
                    values.method[index] !== undefined
                      ? values.method[index]
                      : "0",
                  product_order_start_date:
                    values.order_start_date[index] !== undefined
                      ? values.order_start_date[index]
                      : "",
                  product_order_end_date:
                    values.order_end_date[index] !== undefined
                      ? values.order_end_date[index]
                      : "",
                  product_cart_label_text:
                    values.cart_label_text[index] !== undefined
                      ? htmlSpecialCharacterEncode(
                          values.cart_label_text[index]
                        )
                      : "",
                  product_inventory_quantity:
                    values.inventory_quantity[index] !== undefined
                      ? values.inventory_quantity[index]
                      : "",
                  product_inventory_management:
                    values.inventory_management[index] !== undefined
                      ? values.inventory_management[index]
                      : "",
                  product_variant_name:
                    values.variant_name[index] !== undefined
                      ? values.variant_name[index]
                      : "Default Title",
                  product_prevent_customers:
                    values.prevent_customers[index] !== undefined
                      ? values.prevent_customers[index]
                      : "",
                  product_order_remain_message:
                    values.order_remain_message[index] !== undefined
                      ? htmlSpecialCharacterEncode(
                          values.order_remain_message[index]
                        )
                      : "",
                  product_order_message:
                    values.order_message[index] !== undefined
                      ? htmlSpecialCharacterEncode(values.order_message[index])
                      : "",
                  product_notify_customers:
                    values.notify_customers[index] !== undefined
                      ? values.notify_customers[index]
                      : "",
                  product_buy_now:
                    values.buy_now[index] !== undefined
                      ? values.buy_now[index]
                      : "",
                };
              }
            }
          );
        }
        data.setting_data = obj;
        if (status_arr.includes("0") && status_arr.includes("1")) {
          data.product_variant_status = "2";
        } else if (status_arr.includes("1")) {
          data.product_variant_status = "1";
        } else if (status_arr.includes("0")) {
          data.product_variant_status = "0";
        }
      }
      let res = await ApiCall("POST", "/single-product", data, header);

      if (res.data.statusCode === 200) {
        setToastMessage(res.data.message);
        toggleActiveToast();
        setLoading(false);
        setTimeout(() => navigate("/"), 2000);
      }
    }
  };

  useEffect(() => {
    let data = "";
    if (
      product_id &&
      dbData.length == 0 &&
      productVariants &&
      productVariants.length > 0
    ) {
      productVariants.map((item, index) => {
        initialState.text = [...initialState.text, data];
        initialState.message = [...initialState.message, data];
        initialState.message_placement = [
          ...initialState.message_placement,
          data,
        ];
        initialState.enable_inventary = [
          ...initialState.enable_inventary,
          false,
        ];
        initialState.method = [...initialState.method, 0];
        initialState.order_start_date = [
          ...initialState.order_start_date,
          data,
        ];
        initialState.order_end_date = [...initialState.order_end_date, data];
        initialState.cart_label_text = [...initialState.cart_label_text, data];
        initialState.inventory_management = [
          ...initialState.inventory_management,
          item.inventory_management,
        ];
        initialState.inventory_quantity = [
          ...initialState.inventory_quantity,
          item.inventory_quantity,
        ];
        initialState.variant_name = [...initialState.variant_name, item.title];
        initialState.prevent_customers = [
          ...initialState.prevent_customers,
          false,
        ];
        initialState.order_remain_message = [
          ...initialState.order_remain_message,
          "",
        ];
        initialState.order_message = [...initialState.order_message, ""];
        initialState.notify_customers = [
          ...initialState.notify_customers,
          false,
        ];
        initialState.buy_now = [...initialState.buy_now, false];
      });
      setInitialState(initialState);
    }
  }, [productVariants]);

  useEffect(() => {
    if (
      !product_id &&
      Object.keys(single_product_data).length > 0 &&
      single_product_data.product_settings !== ""
    ) {
      Object.values(JSON.parse(single_product_data.product_settings)).map(
        (item, index) => {
          checked[index] = item.is_enable === "1" ? true : false;
          settingType[index] = item.is_custome_setting === "1" ? true : false;
          initialState.text = [
            ...initialState.text,
            htmlspecialchars_decode(item.product_text),
          ];
          initialState.message = [
            ...initialState.message,
            htmlspecialchars_decode(item.product_message),
          ];
          initialState.message_placement = [
            ...initialState.message_placement,
            item.product_message_placement,
          ];
          initialState.enable_inventary = [
            ...initialState.enable_inventary,
            item.product_enable_inventary,
          ];
          initialState.method = [...initialState.method, item.product_method];
          initialState.order_start_date = [
            ...initialState.order_start_date,
            item.product_order_start_date !== "" &&
            item.product_order_start_date
              ? new Date(item.product_order_start_date)
              : "",
          ];
          initialState.order_end_date = [
            ...initialState.order_end_date,
            item.product_order_end_date !== "" && item.product_order_end_date
              ? new Date(item.product_order_end_date)
              : "",
          ];
          initialState.cart_label_text = [
            ...initialState.cart_label_text,
            htmlspecialchars_decode(item.product_cart_label_text),
          ];
          initialState.inventory_management = [
            ...initialState.inventory_management,
            item.product_inventory_management,
          ];
          initialState.inventory_quantity = [
            ...initialState.inventory_quantity,
            item.product_inventory_quantity,
          ];
          initialState.variant_name = [
            ...initialState.variant_name,
            item.product_variant_name,
          ];
          initialState.prevent_customers = [
            ...initialState.prevent_customers,
            item.product_prevent_customers,
          ];
          initialState.order_remain_message = [
            ...initialState.order_remain_message,
            htmlspecialchars_decode(item.product_order_remain_message),
          ];
          initialState.order_message = [
            ...initialState.order_message,
            htmlspecialchars_decode(item.product_order_message),
          ];
          initialState.notify_customers = [
            ...initialState.notify_customers,
            item.product_notify_customers,
          ];
          initialState.buy_now = [
            ...initialState.buy_now,
            item.product_buy_now,
          ];
        }
      );
      setInitialState(initialState);
    }
  }, [single_product_data]);

  useEffect(() => {
    if (Object.keys(dbData).length > 0 && dbData.product_settings !== "") {
      let arr = [];
      Object.values(JSON.parse(dbData.product_settings)).map((item, index) => {
        arr.push(item.is_enable == "1" ? true : false);
        settingType[index] = item.is_custome_setting === "1" ? true : false;
        initialState.text = [
          ...initialState.text,
          htmlspecialchars_decode(item.product_text),
        ];
        initialState.message = [
          ...initialState.message,
          htmlspecialchars_decode(item.product_message),
        ];
        initialState.message_placement = [
          ...initialState.message_placement,
          item.product_message_placement,
        ];
        initialState.enable_inventary = [
          ...initialState.enable_inventary,
          item.product_enable_inventary,
        ];
        initialState.method = [...initialState.method, item.product_method];
        initialState.order_start_date = [
          ...initialState.order_start_date,
          item.product_order_start_date !== "" && item.product_order_start_date
            ? new Date(item.product_order_start_date)
            : "",
        ];
        initialState.order_end_date = [
          ...initialState.order_end_date,
          item.product_order_end_date !== "" && item.product_order_end_date
            ? new Date(item.product_order_end_date)
            : "",
        ];
        initialState.cart_label_text = [
          ...initialState.cart_label_text,
          htmlspecialchars_decode(item.product_cart_label_text),
        ];
        initialState.inventory_management = [
          ...initialState.inventory_management,
          item.product_inventory_management,
        ];
        initialState.inventory_quantity = [
          ...initialState.inventory_quantity,
          item.product_inventory_quantity,
        ];
        initialState.variant_name = [
          ...initialState.variant_name,
          item.product_variant_name,
        ];
        initialState.prevent_customers = [
          ...initialState.prevent_customers,
          item.product_prevent_customers ? true : false,
        ];
        initialState.order_remain_message = [
          ...initialState.order_remain_message,
          htmlspecialchars_decode(item.product_order_remain_message),
        ];
        initialState.order_message = [
          ...initialState.order_message,
          htmlspecialchars_decode(item.product_order_message),
        ];
        initialState.notify_customers = [
          ...initialState.notify_customers,
          item.product_notify_customers ? true : false,
        ];
        initialState.buy_now = [
          ...initialState.buy_now,
          item.product_buy_now ? true : false,
        ];
      });
      setChecked(arr);
      setInitialState(initialState);
    }
  }, [dbData]);

  const placementOptions = [
    { label: "Below Add to Cart Button", value: "1" },
    { label: "Above Add to Cart Button", value: "2" },
  ];

  const ribbonOptions = [
    { label: "Ribbon", value: "0" },
    { label: "Rectangle", value: "1" },
    { label: "Circle", value: "2" },
  ];

  const handleChange = (_checked, newValue, index) => {
    let type = [...settingType];
    type[index] = !type[index];
    setSettingType(type);
  };

  let toggle = (index) => {
    let open = [...isOpen];
    if (!open[index] === true) {
      let arr = [];
      for (let i = 0; i < open.length; i++) {
        arr.push(false);
      }
      open = arr;
    }
    open[index] = !open[index];
    setIsOpen(open);
  };

  let handleCheck = (index) => {
    let check = [...checked];
    check[index] = !check[index];
    setChecked(check);
  };

  const toggleActiveToast = useCallback(
    () => setActiveToast((active) => !active),
    []
  );

  const toastMarkup = activeToast ? (
    <Toast
      content={toastMessage}
      onDismiss={toggleActiveToast}
      duration={1000}
    />
  ) : null;

  const copyVarientsSetting = (findIndex) => {
    if (single_product_data && Object.keys(single_product_data).length > 0) {
      if (single_product_data.product_settings !== "") {
        Object.values(JSON.parse(single_product_data.product_settings)).map(
          (item, index) => {
            checked[index] = checked[findIndex];
            settingType[index] = settingType[findIndex];
            formik.values.message[index] = htmlspecialchars_decode(
              formik.values.message[findIndex]
            );
            formik.values.text[index] = htmlspecialchars_decode(
              formik.values.text[findIndex]
            );
            formik.values.message_placement[index] =
              formik.values.message_placement[findIndex];
            formik.values.enable_inventary[index] =
              formik.values.enable_inventary[findIndex];
            formik.values.method[index] = formik.values.method[findIndex];
            formik.values.order_start_date[index] =
              formik.values.order_start_date[findIndex];
            formik.values.order_end_date[index] =
              formik.values.order_end_date[findIndex];
            formik.values.prevent_customers[index] =
              formik.values.prevent_customers[findIndex];
            formik.values.order_remain_message[index] = htmlspecialchars_decode(
              formik.values.order_remain_message[findIndex]
            );
            formik.values.order_message[index] = htmlspecialchars_decode(
              formik.values.order_message[findIndex]
            );
            formik.values.notify_customers[index] =
              formik.values.notify_customers[findIndex];
            formik.values.buy_now[index] = initialState.buy_now[findIndex];
            formik.values.cart_label_text[index] = htmlspecialchars_decode(
              formik.values.cart_label_text[findIndex]
            );
          }
        );
      }
      setActiveModal(!activeModal);
    }

    if (
      product_id &&
      productData &&
      Object.keys(productData).length > 0 &&
      productVariants &&
      productVariants.length > 0
    ) {
      productVariants.map((item, index) => {
        checked[index] = checked[findIndex];
        settingType[index] = settingType[findIndex];
        formik.values.message[index] = htmlspecialchars_decode(
          formik.values.message[findIndex]
        );
        formik.values.text[index] = htmlspecialchars_decode(
          formik.values.text[findIndex]
        );
        formik.values.message_placement[index] =
          formik.values.message_placement[findIndex];
        formik.values.enable_inventary[index] =
          formik.values.enable_inventary[findIndex];
        formik.values.method[index] = formik.values.method[findIndex];
        formik.values.order_start_date[index] =
          formik.values.order_start_date[findIndex];
        formik.values.order_end_date[index] =
          formik.values.order_end_date[findIndex];
        formik.values.order_remain_message[index] = htmlspecialchars_decode(
          formik.values.order_remain_message[findIndex]
        );
        formik.values.order_message[index] = htmlspecialchars_decode(
          formik.values.order_message[findIndex]
        );
        formik.values.buy_now[index] = formik.values.buy_now[findIndex];
        formik.values.cart_label_text[index] = htmlspecialchars_decode(
          formik.values.cart_label_text[findIndex]
        );
      });
      setActiveModal(!activeModal);
    }
  };

  const handleModal = (index, flag) => {
    setVarientIndex(index);
    if (flag) {
      copyVarientsSetting(varientIndex);
      setToastMessage("Copy settings Successfully..!");
      toggleActiveToast();
    } else {
      setActiveModal(!activeModal);
    }
  };

  return (
    <>
      {window.location.hostname !== "localhost" ? (
        <>
          <AppProvider>
            {/* <AppBridgeProvider config={config}> */}
            <TitleBar
              title={!product_id ? "Edit product" : "Add product"}
              breadcrumbs={[
                { content: "Single product", onAction: () => navigate("/") },
              ]}
            />
            {!product_id ? (
              <Page
                backAction={{
                  content: "Settings",
                  onAction: () => navigate("/"),
                }}
                title={
                  Object.keys(single_product_data).length > 0 &&
                  single_product_data.product_detail !== ""
                    ? JSON.parse(single_product_data.product_detail).title
                    : ""
                }
              >
                <Layout>
                  <Layout.Section secondary>
                    <LegacyCard sectioned>
                      <LegacyStack wrap={false} alignment="center">
                        <LegacyStack.Item>
                          <div className="text-start product-edit-img">
                            <img
                              src={
                                Object.keys(single_product_data).length > 0 &&
                                single_product_data.product_detail !== ""
                                  ? JSON.parse(
                                      single_product_data.product_detail
                                    ).image
                                  : `${noimg}`
                              }
                              alt="demo"
                              height={"90px"}
                              width={"80px"}
                            />
                          </div>
                        </LegacyStack.Item>
                        <LegacyStack.Item fill>
                          <Text variant="bodyLg" as="p">
                            {Object.keys(single_product_data).length > 0 &&
                            single_product_data.product_detail !== ""
                              ? JSON.parse(single_product_data.product_detail)
                                  .title
                              : ""}
                          </Text>
                        </LegacyStack.Item>
                      </LegacyStack>
                    </LegacyCard>

                    <LegacyCard>
                      <div className="custom-single-product-header-title-Variant  py-3">
                        Variant
                      </div>
                      <ul className="custom-single-product-header-title-Variant_ul">
                        {Object.keys(single_product_data).length > 0 &&
                        single_product_data.product_settings &&
                        single_product_data.product_settings !== ""
                          ? Object.values(
                              JSON.parse(single_product_data.product_settings)
                            ).map((item, index) => {
                              return (
                                <>
                                  <div className="Polaris-LegacyCard__Section--subdued Polaris-border-radius polaris-card-section polaris-tabs-stylish">
                                    <li
                                      className={`product_variant_name ps-4 ${
                                        item.product_variant_name == openTab
                                          ? "variant_active"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        setOpenTab(item.product_variant_name)
                                      }
                                    >
                                      {item.product_variant_name}
                                    </li>
                                  </div>
                                </>
                              );
                            })
                          : ""}
                      </ul>
                    </LegacyCard>
                  </Layout.Section>
                  <Layout.Section>
                    <div>
                      {Object.keys(single_product_data).length > 0 &&
                        single_product_data.product_settings &&
                        single_product_data.product_settings !== "" &&
                        Object.values(
                          JSON.parse(single_product_data.product_settings)
                        ).map((item, index) => {
                          return (
                            <div
                              className={`border-top-edit ${
                                item.product_variant_name == openTab
                                  ? "d-block"
                                  : "d-none"
                              }`}
                            >
                              <div key={index} className="edit_product">
                                <LegacyCard title={item.product_variant_name}>
                                  {Object.values(
                                    JSON.parse(
                                      single_product_data.product_settings
                                    )
                                  ).length > 1 ? (
                                    <div className="text-end p-3">
                                      <Button
                                        primary
                                        onClick={() => {
                                          setVarientIndex(index);
                                          copyVarientsSetting(index);
                                        }}
                                      >
                                        Copy settings to all variants
                                      </Button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="row mx-3 border-top">
                                    {/* <div className="col-lg-6 px-1">
                                      <h1 className="font-weight-600 py-3">
                                        Variant details
                                      </h1>
                                      <p>
                                        If product details in Pre-order Now are out of
                                        sync with <br />
                                        Shopify, click here to force re-sync Pre-order
                                        Now.
                                      </p>
                                    </div> */}
                                    <div className="col-lg-12 inventory">
                                      <div className="pt-3 product-table">
                                        <LegacyCard key={`mainfirst-${index}`}>
                                          <table className="w-100 table">
                                            <tbody>
                                              <tr>
                                                <th>Keeping track of stock</th>
                                                <td>
                                                  {item.product_inventory_management !==
                                                  ""
                                                    ? "Enable"
                                                    : "Disable"}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>Status of Inventory</th>
                                                <td>
                                                  {
                                                    item.product_inventory_quantity
                                                  }
                                                </td>
                                              </tr>{" "}
                                              <tr>
                                                <th>Quantity Oversell</th>
                                                <td>
                                                  {item.product_inventory_management !==
                                                  ""
                                                    ? "Enable"
                                                    : "Disable"}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </LegacyCard>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="border-bottom">
                                    <div className="row">
                                      <div className="col-lg-4">
                                        <h1 className="font-weight-600 enable-title">
                                          Enable
                                        </h1>
                                      </div>
                                      <div className="col-lg-8 status-block">
                                        <Checkbox
                                          label="Enable pre-order for this variant"
                                          checked={checked[index]}
                                          onChange={() => handleCheck(index)}
                                          name="is_enable"
                                        />
                                        <div className="d-flex pt-3 row pb-3">
                                          <div className="col-lg-6">
                                            <RadioButton
                                              label="Use default settings"
                                              checked={!settingType[index]}
                                              disabled={!checked[index]}
                                              onChange={(_checked, newValue) =>
                                                handleChange(
                                                  _checked,
                                                  newValue,
                                                  index
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="col-lg-6">
                                            <RadioButton
                                              label="Use settings you choose."
                                              checked={settingType[index]}
                                              disabled={!checked[index]}
                                              onChange={(_checked, newValue) =>
                                                handleChange(
                                                  _checked,
                                                  newValue,
                                                  index
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </LegacyCard>
                                {checked[index] && settingType[index] && (
                                  <div className="row">
                                    <div className="card-border-setting">
                                      <LegacyCard title="Button" sectioned>
                                        <div className="row">
                                          <div className="col-lg-4">
                                            <TextField
                                              label="Text on button "
                                              value={formik.values.text[index]}
                                              placeholder="Preorder now"
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `text.${index}`,
                                                  value
                                                );
                                              }}
                                              autoComplete="off"
                                              onBlur={formik.handleBlur}
                                              error={
                                                formik.errors.text &&
                                                formik.touched.text
                                                  ? formik.errors.text
                                                  : ""
                                              }
                                            />
                                          </div>
                                          <div className="col-lg-4 mob-pt-1">
                                            <TextField
                                              label="Pre-order message"
                                              value={
                                                formik.values.message[index]
                                              }
                                              placeholder="Preorder message"
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `message.${index}`,
                                                  value
                                                );
                                              }}
                                              autoComplete="off"
                                            />
                                          </div>
                                          <div className="col-lg-4 mob-pt-1">
                                            <Select
                                              label="message placements"
                                              options={placementOptions}
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `message_placement.${index}`,
                                                  value
                                                );
                                              }}
                                              value={
                                                formik.values.message_placement[
                                                  index
                                                ]
                                              }
                                            />
                                          </div>
                                        </div>
                                      </LegacyCard>
                                    </div>
                                    {/* Stock Method Card */}
                                    <div className="card-border-setting">
                                      <LegacyCard
                                        title="Stock"
                                        sectioned
                                        key={`mainsec-${index}`}
                                      >
                                        <Checkbox
                                          label="Make it possible to control stock"
                                          checked={
                                            formik.values.enable_inventary[
                                              index
                                            ]
                                          }
                                          onChange={() =>
                                            formik.setFieldValue(
                                              `enable_inventary.${index}`,
                                              !formik.values.enable_inventary[
                                                index
                                              ]
                                            )
                                          }
                                        />
                                        {formik.values.enable_inventary[
                                          index
                                        ] && (
                                          <>
                                            <div className="stock-question">
                                              <h1>
                                                What do you want pre-order now
                                                to do?
                                              </h1>
                                            </div>
                                            <div>
                                              <div className="border-bottom pb-2">
                                                <RadioButton
                                                  label={
                                                    <div>
                                                      <strong>
                                                        Method 1:&nbsp;
                                                      </strong>
                                                      <span>
                                                        Your pre-order button
                                                        will show up in Shopify
                                                        when the number of items
                                                        in stock is greater than
                                                        0. when the stock
                                                        reaches 0, the pre-order
                                                        button won'twork until
                                                        there are more than 0.
                                                      </span>
                                                    </div>
                                                  }
                                                  checked={
                                                    formik.values.method[
                                                      index
                                                    ] === 0
                                                  }
                                                  id={`method_1.${index}`}
                                                  onChange={(
                                                    _checked,
                                                    newValue
                                                  ) => {
                                                    formik.setFieldValue(
                                                      `method.${index}`,
                                                      0
                                                    );
                                                  }}
                                                />
                                              </div>
                                              <div className="py-2">
                                                <RadioButton
                                                  label={
                                                    <div>
                                                      <strong>
                                                        Method 2:&nbsp;
                                                      </strong>
                                                      <span>
                                                        Shopify's add-to-cart
                                                        button will show up when
                                                        the number of items in
                                                        stock is greater than 0.
                                                        Your pre-order button
                                                        will show up when the
                                                        number of items left is
                                                        less than or equal to 0.
                                                      </span>
                                                    </div>
                                                  }
                                                  checked={
                                                    formik.values.method[
                                                      index
                                                    ] === 1
                                                  }
                                                  id={`method_2.${index}`}
                                                  onChange={(
                                                    _checked,
                                                    newValue
                                                  ) =>
                                                    formik.setFieldValue(
                                                      `method.${index}`,
                                                      1
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="stock-note">
                                              <p>
                                                <strong>Note:&nbsp;</strong>
                                                For this feature to work, you
                                                must set your Shopify product
                                                settings' 'Inventory policy' to
                                                'Shopify tracks this product's
                                                inventory' and set an inventory
                                                amount. For way 2 to work, you
                                                must check the box in your
                                                Shopify product settings that
                                                says 'Allow customers to buy
                                                this product when it's out of
                                                stock'
                                              </p>
                                            </div>
                                          </>
                                        )}
                                      </LegacyCard>
                                    </div>
                                    <div className="card-border-setting">
                                      <LegacyCard
                                        title="Pre-order period"
                                        sectioned
                                        key={`mainfour-${index}`}
                                      >
                                        <div>
                                          <p>Timezone: Asia/Kolkata</p>
                                          <p className="py-2">
                                            The pre-order time tells you when
                                            your pre-order settings will turn on
                                            and/or off. If you want your
                                            pre-order settings to take effect
                                            right away, leave the pre-order
                                            start date blank or choose a date in
                                            the past.
                                          </p>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6 start_date mob-pb-1">
                                            <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular ">
                                              Pre-order start date
                                            </span>
                                            <Datetime
                                              value={
                                                formik.values.order_start_date[
                                                  index
                                                ]
                                              }
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `order_start_date.${index}`,
                                                  value._d
                                                );
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  readOnly
                                                />
                                              )}
                                            />
                                          </div>
                                          <div className="col-lg-6 end_date">
                                            <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular">
                                              Pre-order end date
                                            </span>
                                            <Datetime
                                              value={
                                                formik.values.order_end_date[
                                                  index
                                                ]
                                              }
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `order_end_date.${index}`,
                                                  value._d
                                                );
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  readOnly
                                                />
                                              )}
                                            />
                                          </div>
                                        </div>
                                      </LegacyCard>
                                    </div>
                                    <div className="card-border-setting">
                                      <LegacyCard
                                        title="A stock alert"
                                        sectioned
                                      >
                                        <div className="row">
                                          <div className="display-flex">
                                            <Checkbox
                                              label="Customers shouldn't be able to buy more than the amount of pre-order stock."
                                              checked={
                                                formik.values.prevent_customers[
                                                  index
                                                ]
                                              }
                                              onChange={() =>
                                                formik.setFieldValue(
                                                  `prevent_customers.${index}`,
                                                  !formik.values
                                                    .prevent_customers[index]
                                                )
                                              }
                                            />
                                            <div className="mt-1 ml-1">
                                              <Tooltip
                                                content={`Only works with Inventory Management Setting #1 and Specific Pre-order Stock. This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions.`}
                                                dismissOnMouseOut
                                                borderRadius={1}
                                                width="wide"
                                              >
                                                <Icon
                                                  source={InfoIcon}
                                                  color="base"
                                                />
                                              </Tooltip>
                                            </div>
                                            {/* <br />
                                      <span className="stock-warning-color">
                                        Only works with Inventory Management
                                        Setting #1 and Specific Pre-order Stock.
                                        This function might not work with every
                                        theme. After you turn this feature on,
                                        we suggest that you check your shop's
                                        "add to cart" and "checkout" functions.
                                      </span> */}
                                          </div>
                                          <div className="row pt-2">
                                            <TextField
                                              label="Not enough items left to pre-order message"
                                              value={
                                                formik.values
                                                  .order_remain_message[index]
                                              }
                                              placeholder=""
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `order_remain_message.${index}`,
                                                  value
                                                );
                                              }}
                                              autoComplete="off"
                                            />
                                            <div className="stock-warning-color">
                                              <p>
                                                Note: We only have qty unit(s)
                                                left. number of units will be
                                                filled right away, and the rest
                                                will have to be pre-ordered.
                                              </p>
                                              <p className="font-12">
                                                Variables: qty. Your Shopify
                                                stock will replace the amount..
                                              </p>
                                            </div>
                                          </div>
                                          <div className="pt-2">
                                            <div className="display-flex">
                                              <Checkbox
                                                label="Tell people when a part of their order will be on pre-order."
                                                checked={
                                                  formik.values
                                                    .notify_customers[index]
                                                }
                                                onChange={() =>
                                                  formik.setFieldValue(
                                                    `notify_customers.${index}`,
                                                    !formik.values
                                                      .notify_customers[index]
                                                  )
                                                }
                                              />
                                              <div className="mt-1 ml-1">
                                                <Tooltip
                                                  content={`Only for Setting #2 of Inventory Management. This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions`}
                                                  dismissOnMouseOut
                                                  borderRadius={1}
                                                  width="wide"
                                                >
                                                  <Icon
                                                    source={InfoIcon}
                                                    color="base"
                                                  />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row pt-2">
                                            <TextField
                                              label="Not enough items left to pre-order message"
                                              value={
                                                formik.values.order_message[
                                                  index
                                                ]
                                              }
                                              placeholder=""
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `order_message.${index}`,
                                                  value
                                                );
                                              }}
                                              autoComplete="off"
                                            />
                                            <div className="stock-warning-color">
                                              <p>
                                                Note: We only have qty unit(s)
                                                left. number of units will be
                                                filled right away, and the rest
                                                will have to be pre-ordered.
                                              </p>
                                              <p className="font-12">
                                                Variables: qty. Your Shopify
                                                stock will replace the amount..
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </LegacyCard>
                                    </div>
                                    <div className="card-border-setting">
                                      <LegacyCard
                                        title={`Product page`}
                                        sectioned
                                      >
                                        <Checkbox
                                          label="Hide the 'buy now' buttons when the current version is on pre-order."
                                          checked={formik.values.buy_now[index]}
                                          onChange={() =>
                                            formik.setFieldValue(
                                              `buy_now.${index}`,
                                              !formik.values.buy_now[index]
                                            )
                                          }
                                        />
                                      </LegacyCard>
                                    </div>
                                    <div className="card-border-setting">
                                      <LegacyCard
                                        title="Cart label"
                                        sectioned
                                        key={`mainsev-${index}`}
                                      >
                                        <div className="row">
                                          <div className="col-lg-12">
                                            <TextField
                                              label="Cart label text"
                                              value={
                                                formik.values.cart_label_text[
                                                  index
                                                ]
                                              }
                                              placeholder="Pre-order item"
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `cart_label_text.${index}`,
                                                  value
                                                );
                                              }}
                                              autoComplete="off"
                                              error={
                                                formik.errors.cart_label_text &&
                                                formik.touched.cart_label_text
                                                  ? formik.errors
                                                      .cart_label_text
                                                  : ""
                                              }
                                            />
                                          </div>
                                        </div>
                                      </LegacyCard>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      <div className="text-end pt-3">
                        <Button
                          primary
                          onClick={() => formik.handleSubmit()}
                          loading={loading}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Layout.Section>
                </Layout>
                <Footer />
              </Page>
            ) : productData && Object.keys(productData).length > 0 ? (
              <Page
                backAction={{
                  content: "Settings",
                  onAction: () => navigate("/"),
                }}
                title={Object.keys(productData).length > 0 && productData.title}
              >
                <Layout>
                  <Layout.Section secondary>
                    <LegacyCard key={`cardmain`} sectioned>
                      {/* <div className="custom-single-product-header-title-Product ">
                    Product
                  </div> */}
                      <LegacyStack wrap={false} alignment="center">
                        <LegacyStack.Item>
                          <div className="text-start product-edit-img">
                            <img
                              src={
                                productImage && productImage?.src
                                  ? productImage?.src
                                  : `${noimg}`
                              }
                              alt="demo"
                              height={"90px"}
                              width={"100px"}
                            />
                          </div>
                        </LegacyStack.Item>
                        <LegacyStack.Item fill>
                          <Text variant="bodyLg" as="p">
                            {Object.keys(productData).length > 0 &&
                              productData.title}
                          </Text>
                        </LegacyStack.Item>
                      </LegacyStack>
                    </LegacyCard>
                    <LegacyCard>
                      <div className="custom-single-product-header-title-Variant  pt-3 pb-3">
                        Variant
                      </div>
                      <ul className="custom-single-product-header-title-Variant_ul">
                        {productVariants &&
                          productVariants.map((x) => {
                            return (
                              <>
                                <div className="Polaris-LegacyCard__Section--subdued Polaris-border-radius polaris-card-section polaris-tabs-stylish">
                                  <li
                                    className={`product_variant_name ps-4 ${
                                      x.title == openTab ? "variant_active" : ""
                                    }`}
                                    onClick={() => setOpenTab(x.title)}
                                  >
                                    {x.title}
                                  </li>
                                </div>
                              </>
                            );
                          })}
                      </ul>
                    </LegacyCard>
                  </Layout.Section>
                  <Layout.Section>
                    <div>
                      <div className="card-border-setting-padding">
                        {productVariants &&
                          productVariants?.map((item, index) => {
                            return (
                              <>
                                <div
                                  className={`edit_pro ${
                                    item.title == openTab ? "d-block" : "d-none"
                                  }`}
                                >
                                  <LegacyCard sectioned>
                                    <LegacyStack alignment="center">
                                      <LegacyStack.Item fill>
                                        <div class="Polaris-LegacyCard__Header p-0">
                                          <h2 class="Polaris-Text--root Polaris-Text--headingMd">
                                            {item.title}
                                          </h2>
                                        </div>
                                      </LegacyStack.Item>
                                      <LegacyStack.Item>
                                        <div className="text-end">
                                          {productVariants.length > 1 && (
                                            <Button
                                              onClick={() => {
                                                setVarientIndex(index);
                                                copyVarientsSetting(index);
                                              }}
                                              primary
                                            >
                                              Copy settings to all variants
                                            </Button>
                                          )}
                                        </div>
                                      </LegacyStack.Item>
                                    </LegacyStack>
                                    <div className="border-top product-edit-variant-detail">
                                      <LegacyStack
                                        alignment="center"
                                        distribution="equalSpacing"
                                        wrap={false}
                                      >
                                        <LegacyStack.Item fill>
                                          <div className=" px-1">
                                            <h1 className="font-weight-600 py-3">
                                              Variant details
                                            </h1>
                                            <p>
                                              If the details of the products in
                                              Pre-order Now aren't matching{" "}
                                              <br />
                                              up with Shopify, click here to
                                              quickly fix it.
                                            </p>
                                          </div>
                                        </LegacyStack.Item>
                                        <LegacyStack.Item fill>
                                          <div className=" inventory">
                                            <div className="pt-3 product-table">
                                              <LegacyCard
                                                key={`mainfirst-${index}`}
                                              >
                                                <table className="w-100 table">
                                                  <tbody>
                                                    <tr>
                                                      <th>
                                                        Keeping track of stock
                                                      </th>
                                                      <td>
                                                        {item.inventory_management !==
                                                        ""
                                                          ? "Enable"
                                                          : "Disable"}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th>
                                                        Status of Inventory
                                                      </th>
                                                      <td>
                                                        {
                                                          item.inventory_quantity
                                                        }
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th>Quantity Oversell</th>
                                                      <td>
                                                        {item.inventory_management !==
                                                        ""
                                                          ? "Enable"
                                                          : "Disable"}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </LegacyCard>
                                            </div>
                                          </div>
                                        </LegacyStack.Item>
                                      </LegacyStack>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-3">
                                        <h1 className="font-weight-600 enable-title p-0">
                                          Enable
                                        </h1>
                                      </div>
                                      <div className="col-lg-9 status-block">
                                        <Checkbox
                                          label="Enable pre-order for this variant"
                                          checked={checked[index]}
                                          onChange={() => handleCheck(index)}
                                          name="is_enable"
                                        />
                                        <div className="d-flex pt-3 row">
                                          <div className="col-lg-6">
                                            <RadioButton
                                              label="Use default settings"
                                              checked={!settingType[index]}
                                              disabled={!checked[index]}
                                              onChange={(_checked, newValue) =>
                                                handleChange(
                                                  _checked,
                                                  newValue,
                                                  index
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="col-lg-6">
                                            <RadioButton
                                              label="Use settings you choose."
                                              checked={settingType[index]}
                                              disabled={!checked[index]}
                                              onChange={(_checked, newValue) =>
                                                handleChange(
                                                  _checked,
                                                  newValue,
                                                  index
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </LegacyCard>
                                  {checked[index] && settingType[index] && (
                                    <div className="row">
                                      {/* Button Card */}
                                      <div className="card-border-setting">
                                        <LegacyCard
                                          title="Button"
                                          sectioned
                                          key={`cardtwo-${index}`}
                                        >
                                          <div className="row">
                                            <div className="col-lg-4">
                                              <TextField
                                                label="Text on button "
                                                value={
                                                  formik.values.text[index]
                                                }
                                                placeholder="Preorder now"
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `text.${index}`,
                                                    value
                                                  );
                                                }}
                                                autoComplete="off"
                                                onBlur={formik.handleBlur}
                                                error={
                                                  formik.errors.text &&
                                                  formik.touched.text
                                                    ? formik.errors.text
                                                    : ""
                                                }
                                              />
                                            </div>
                                            <div className="col-lg-4 mob-pt-1">
                                              <TextField
                                                label="Pre-order message"
                                                value={
                                                  formik.values.message[index]
                                                }
                                                placeholder="hello"
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `message.${index}`,
                                                    value
                                                  );
                                                }}
                                                autoComplete="off"
                                              />
                                            </div>
                                            <div className="col-lg-4 mob-pt-1">
                                              <Select
                                                label="message placements"
                                                options={placementOptions}
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `message_placement.${index}`,
                                                    value
                                                  );
                                                }}
                                                value={
                                                  formik.values
                                                    .message_placement[index]
                                                }
                                              />
                                            </div>
                                          </div>
                                        </LegacyCard>
                                      </div>
                                      {/* Stock Method Card */}
                                      <div className="card-border-setting">
                                        <LegacyCard
                                          title="Stock"
                                          sectioned
                                          key={`cardthird-${index}`}
                                        >
                                          <Checkbox
                                            label="Make it possible to control stock"
                                            checked={
                                              formik.values.enable_inventary[
                                                index
                                              ]
                                            }
                                            onChange={() =>
                                              formik.setFieldValue(
                                                `enable_inventary.${index}`,
                                                !formik.values.enable_inventary[
                                                  index
                                                ]
                                              )
                                            }
                                          />
                                          {formik.values.enable_inventary[
                                            index
                                          ] && (
                                            <>
                                              <div className="stock-question">
                                                <h1>
                                                  What do you want pre-order now
                                                  to do?
                                                </h1>
                                              </div>
                                              <div>
                                                <div className="border-bottom pb-2">
                                                  <RadioButton
                                                    label={
                                                      <div>
                                                        <strong>
                                                          Method 1:&nbsp;
                                                        </strong>
                                                        <span>
                                                          Your pre-order button
                                                          will show up in
                                                          Shopify when the
                                                          number of items in
                                                          stock is greater than
                                                          0. when the stock
                                                          reaches 0, the
                                                          pre-order button
                                                          won'twork until there
                                                          are more than 0.
                                                        </span>
                                                      </div>
                                                    }
                                                    checked={
                                                      formik.values.method[
                                                        index
                                                      ] === 0
                                                    }
                                                    id={`method_1.${index}`}
                                                    onChange={(
                                                      _checked,
                                                      newValue
                                                    ) => {
                                                      formik.setFieldValue(
                                                        `method.${index}`,
                                                        0
                                                      );
                                                    }}
                                                  />
                                                </div>
                                                <div className="py-2">
                                                  <RadioButton
                                                    label={
                                                      <div>
                                                        <strong>
                                                          Method 2:&nbsp;
                                                        </strong>
                                                        <span>
                                                          Shopify's add-to-cart
                                                          button will show up
                                                          when the number of
                                                          items in stock is
                                                          greater than 0. Your
                                                          pre-order button will
                                                          show up when the
                                                          number of items left
                                                          is less than or equal
                                                          to 0.
                                                        </span>
                                                      </div>
                                                    }
                                                    checked={
                                                      formik.values.method[
                                                        index
                                                      ] === 1
                                                    }
                                                    id={`method_2.${index}`}
                                                    onChange={(
                                                      _checked,
                                                      newValue
                                                    ) =>
                                                      formik.setFieldValue(
                                                        `method.${index}`,
                                                        1
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="stock-note">
                                                <p>
                                                  <strong>Note:&nbsp;</strong>
                                                  For this feature to work, you
                                                  must set your Shopify product
                                                  settings' 'Inventory policy'
                                                  to 'Shopify tracks this
                                                  product's inventory' and set
                                                  an inventory amount. For way 2
                                                  to work, you must check the
                                                  box in your Shopify product
                                                  settings that says 'Allow
                                                  customers to buy this product
                                                  when it's out of stock'
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        </LegacyCard>
                                      </div>
                                      {/* Product Image Card */}
                                      {/* Pre-order Period Card */}
                                      <div className="card-border-setting">
                                        <LegacyCard
                                          title="Pre-order period"
                                          sectioned
                                          key={`cardsix-${index}`}
                                        >
                                          <div className="row ">
                                            <div>
                                              <p>Timezone: Asia/Kolkata</p>
                                              <p className="py-2">
                                                The pre-order time tells you
                                                when your pre-order settings
                                                will turn on and/or off. If you
                                                want your pre-order settings to
                                                take effect right away, leave
                                                the pre-order start date blank
                                                or choose a date in the past.
                                              </p>
                                            </div>
                                            <div className="col-lg-6 start_date mob-pb-1">
                                              <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular mob-pb-1">
                                                Pre-order start date
                                              </span>
                                              <Datetime
                                                value={
                                                  formik.values
                                                    .order_start_date[index]
                                                }
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `order_start_date.${index}`,
                                                    value._d
                                                  );
                                                }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    readOnly
                                                  />
                                                )}
                                              />
                                            </div>
                                            <div className="col-lg-6 end_date">
                                              <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular">
                                                Pre-order end date
                                              </span>
                                              <Datetime
                                                value={
                                                  formik.values.order_end_date[
                                                    index
                                                  ]
                                                }
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `order_end_date.${index}`,
                                                    value._d
                                                  );
                                                }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    readOnly
                                                  />
                                                )}
                                              />
                                            </div>
                                          </div>
                                        </LegacyCard>
                                      </div>
                                      <div className="card-border-setting">
                                        <LegacyCard
                                          title="A stock alert"
                                          sectioned
                                        >
                                          <div className="row">
                                            <div className="display-flex">
                                              <Checkbox
                                                label="Prevent customers from ordering more than the pre-order stock available"
                                                checked={
                                                  formik.values
                                                    .prevent_customers[index]
                                                }
                                                onChange={() =>
                                                  formik.setFieldValue(
                                                    `prevent_customers.${index}`,
                                                    !formik.values
                                                      .prevent_customers[index]
                                                  )
                                                }
                                              />
                                              <div className="mt-1 ml-1">
                                                <Tooltip
                                                  content={`Only works with Inventory Management Setting #1 and Specific Pre-order Stock. This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions.`}
                                                  dismissOnMouseOut
                                                  borderRadius={1}
                                                  width="wide"
                                                >
                                                  <Icon
                                                    source={InfoIcon}
                                                    color="base"
                                                  />
                                                </Tooltip>
                                              </div>
                                            </div>
                                            <div className="row pt-2">
                                              <TextField
                                                label="Not enough items left to pre-order message"
                                                value={
                                                  formik.values
                                                    .order_remain_message[index]
                                                }
                                                placeholder=""
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `order_remain_message.${index}`,
                                                    value
                                                  );
                                                }}
                                                autoComplete="off"
                                              />
                                              <div className="stock-warning-color">
                                                <p>
                                                  Note: We only have qty unit(s)
                                                  left. number of units will be
                                                  filled right away, and the
                                                  rest will have to be
                                                  pre-ordered.
                                                </p>
                                                <p className="font-12">
                                                  Variables: qty. Your Shopify
                                                  stock will replace the amount.
                                                </p>
                                              </div>
                                            </div>
                                            <div className="pt-2">
                                              <div className="display-flex">
                                                <Checkbox
                                                  label="Tell people when a part of their order will be on pre-order."
                                                  checked={
                                                    formik.values
                                                      .notify_customers[index]
                                                  }
                                                  onChange={() =>
                                                    formik.setFieldValue(
                                                      `notify_customers.${index}`,
                                                      !formik.values
                                                        .notify_customers[index]
                                                    )
                                                  }
                                                />
                                                <div className="mt-1 ml-1">
                                                  <Tooltip
                                                    content={`Only works with make it possible to control stock for Setting #2 . This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions.`}
                                                    dismissOnMouseOut
                                                    borderRadius={1}
                                                    width="wide"
                                                  >
                                                    <Icon
                                                      source={InfoIcon}
                                                      color="base"
                                                    />
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row pt-2">
                                              <TextField
                                                label="Not enough items left to pre-order message"
                                                value={
                                                  formik.values.order_message[
                                                    index
                                                  ]
                                                }
                                                placeholder=""
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `order_message.${index}`,
                                                    value
                                                  );
                                                }}
                                                autoComplete="off"
                                              />
                                              <div className="stock-warning-color">
                                                <p>
                                                  Note: We only have qty unit(s)
                                                  left. number of units will be
                                                  filled right away, and the
                                                  rest will have to be
                                                  pre-ordered.
                                                </p>
                                                <p className="font-12">
                                                  Variables: qty. Your Shopify
                                                  stock will replace the amount.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </LegacyCard>
                                      </div>
                                      <div className="card-border-setting">
                                        <LegacyCard
                                          title={`Product page`}
                                          sectioned
                                        >
                                          <Checkbox
                                            label="Hide the 'buy now' buttons when the current version is on pre-order."
                                            checked={
                                              formik.values.buy_now[index]
                                            }
                                            onChange={() =>
                                              formik.setFieldValue(
                                                `buy_now.${index}`,
                                                !formik.values.buy_now[index]
                                              )
                                            }
                                          />
                                        </LegacyCard>
                                      </div>
                                      {/* Label Card /> */}
                                      <div className="card-border-setting">
                                        <LegacyCard
                                          title="Cart label"
                                          sectioned
                                          key={`cardeight-${index}`}
                                        >
                                          <div className="row">
                                            <div className="col-lg-12">
                                              <TextField
                                                label="Cart label text"
                                                value={
                                                  formik.values.cart_label_text[
                                                    index
                                                  ]
                                                }
                                                placeholder=""
                                                onChange={(value) => {
                                                  formik.setFieldValue(
                                                    `cart_label_text.${index}`,
                                                    value
                                                  );
                                                }}
                                                autoComplete="off"
                                                error={
                                                  formik.errors
                                                    .cart_label_text &&
                                                  formik.touched.cart_label_text
                                                    ? formik.errors
                                                        .cart_label_text
                                                    : ""
                                                }
                                              />
                                            </div>
                                          </div>
                                        </LegacyCard>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            );
                          })}
                      </div>
                      <div className="text-end">
                        <Button
                          primary
                          onClick={() => formik.handleSubmit()}
                          loading={loading}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Layout.Section>
                </Layout>
                <Footer />
              </Page>
            ) : null}
            {/* </AppBridgeProvider> */}
          </AppProvider>
        </>
      ) : (
        <>
          {!product_id ? (
            <Page
              backAction={{
                content: "Settings",
                onAction: () => navigate("/"),
              }}
              title={
                Object.keys(single_product_data).length > 0 &&
                single_product_data.product_detail !== ""
                  ? JSON.parse(single_product_data.product_detail).title
                  : ""
              }
            >
              <Layout>
                <Layout.Section secondary>
                  <LegacyCard sectioned>
                    <LegacyStack wrap={false} alignment="center">
                      <LegacyStack.Item>
                        <div className="text-start product-edit-img">
                          <img
                            src={
                              Object.keys(single_product_data).length > 0 &&
                              single_product_data.product_detail !== ""
                                ? JSON.parse(single_product_data.product_detail)
                                    .image
                                : `${noimg}`
                            }
                            alt="demo"
                            height={"90px"}
                            width={"80px"}
                          />
                        </div>
                      </LegacyStack.Item>
                      <LegacyStack.Item fill>
                        <Text variant="bodyLg" as="p">
                          {Object.keys(single_product_data).length > 0 &&
                          single_product_data.product_detail !== ""
                            ? JSON.parse(single_product_data.product_detail)
                                .title
                            : ""}
                        </Text>
                      </LegacyStack.Item>
                    </LegacyStack>
                  </LegacyCard>

                  <LegacyCard>
                    <div className="custom-single-product-header-title-Variant  py-3">
                      Variant
                    </div>
                    <ul className="custom-single-product-header-title-Variant_ul">
                      {Object.keys(single_product_data).length > 0 &&
                      single_product_data.product_settings &&
                      single_product_data.product_settings !== ""
                        ? Object.values(
                            JSON.parse(single_product_data.product_settings)
                          ).map((item, index) => {
                            return (
                              <>
                                <div className="Polaris-LegacyCard__Section--subdued Polaris-border-radius polaris-card-section polaris-tabs-stylish">
                                  <li
                                    className={`product_variant_name ps-4 ${
                                      item.product_variant_name == openTab
                                        ? "variant_active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      setOpenTab(item.product_variant_name)
                                    }
                                  >
                                    {item.product_variant_name}
                                  </li>
                                </div>
                              </>
                            );
                          })
                        : ""}
                    </ul>
                  </LegacyCard>
                </Layout.Section>
                <Layout.Section>
                  <div>
                    {Object.keys(single_product_data).length > 0 &&
                      single_product_data.product_settings &&
                      single_product_data.product_settings !== "" &&
                      Object.values(
                        JSON.parse(single_product_data.product_settings)
                      ).map((item, index) => {
                        return (
                          <div
                            className={`border-top-edit ${
                              item.product_variant_name == openTab
                                ? "d-block"
                                : "d-none"
                            }`}
                          >
                            <div key={index} className="edit_product">
                              <LegacyCard title={item.product_variant_name}>
                                {Object.values(
                                  JSON.parse(
                                    single_product_data.product_settings
                                  )
                                ).length > 1 ? (
                                  <div className="text-end p-3">
                                    <Button
                                      primary
                                      onClick={() => {
                                        setVarientIndex(index);
                                        copyVarientsSetting(index);
                                      }}
                                    >
                                      Copy settings to all variants
                                    </Button>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="row mx-3 border-top">
                                  <div className="col-lg-6 px-1">
                                    <h1 className="font-weight-600 py-3">
                                      Variant details
                                    </h1>
                                    <p>
                                      If product details in Pre-order Now are
                                      out of sync with <br />
                                      Shopify, click here to force re-sync
                                      Pre-order Now.
                                    </p>
                                  </div>
                                  <div className="col-lg-6 inventory">
                                    <div className="pt-3 product-table">
                                      <LegacyCard key={`mainfirst-${index}`}>
                                        <table className="w-100 table">
                                          <tbody>
                                            <tr>
                                              <th>Keeping track of stock</th>
                                              <td>
                                                {item.product_inventory_management !==
                                                ""
                                                  ? "Enable"
                                                  : "Disable"}
                                              </td>
                                            </tr>
                                            <tr>
                                              <th>Status of Inventory</th>
                                              <td>
                                                {
                                                  item.product_inventory_quantity
                                                }
                                              </td>
                                            </tr>{" "}
                                            <tr>
                                              <th>Quantity Oversell</th>
                                              <td>
                                                {item.product_inventory_management !==
                                                ""
                                                  ? "Enable"
                                                  : "Disable"}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </LegacyCard>
                                    </div>
                                  </div>
                                </div>
                                <div className="border-bottom">
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <h1 className="font-weight-600 enable-title">
                                        Enable
                                      </h1>
                                    </div>
                                    <div className="col-lg-8 status-block">
                                      <Checkbox
                                        label="Enable pre-order for this variant"
                                        checked={checked[index]}
                                        onChange={() => handleCheck(index)}
                                        name="is_enable"
                                      />
                                      <div className="d-flex pt-3 row pb-3">
                                        <div className="col-lg-6">
                                          <RadioButton
                                            label="Use default settings"
                                            checked={!settingType[index]}
                                            disabled={!checked[index]}
                                            onChange={(_checked, newValue) =>
                                              handleChange(
                                                _checked,
                                                newValue,
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-lg-6">
                                          <RadioButton
                                            label="Use settings you choose."
                                            checked={settingType[index]}
                                            disabled={!checked[index]}
                                            onChange={(_checked, newValue) =>
                                              handleChange(
                                                _checked,
                                                newValue,
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </LegacyCard>
                              {checked[index] && settingType[index] && (
                                <div className="row">
                                  <div className="card-border-setting">
                                    <LegacyCard title="Button" sectioned>
                                      <div className="row">
                                        <div className="col-lg-4">
                                          <TextField
                                            label="Text on button "
                                            value={formik.values.text[index]}
                                            placeholder="Preorder now"
                                            onChange={(value) => {
                                              formik.setFieldValue(
                                                `text.${index}`,
                                                value
                                              );
                                            }}
                                            autoComplete="off"
                                            onBlur={formik.handleBlur}
                                            error={
                                              formik.errors.text &&
                                              formik.touched.text
                                                ? formik.errors.text
                                                : ""
                                            }
                                          />
                                        </div>
                                        <div className="col-lg-4 mob-pt-1">
                                          <TextField
                                            label="Pre-order message"
                                            value={formik.values.message[index]}
                                            placeholder="Preorder message"
                                            onChange={(value) => {
                                              formik.setFieldValue(
                                                `message.${index}`,
                                                value
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                        </div>
                                        <div className="col-lg-4 mob-pt-1">
                                          <Select
                                            label="message placements"
                                            options={placementOptions}
                                            onChange={(value) => {
                                              formik.setFieldValue(
                                                `message_placement.${index}`,
                                                value
                                              );
                                            }}
                                            value={
                                              formik.values.message_placement[
                                                index
                                              ]
                                            }
                                          />
                                        </div>
                                      </div>
                                    </LegacyCard>
                                  </div>
                                  {/* Stock Method Card */}
                                  <div className="card-border-setting">
                                    <LegacyCard
                                      title="Stock"
                                      sectioned
                                      key={`mainsec-${index}`}
                                    >
                                      <Checkbox
                                        label="Make it possible to control stock"
                                        checked={
                                          formik.values.enable_inventary[index]
                                        }
                                        onChange={() =>
                                          formik.setFieldValue(
                                            `enable_inventary.${index}`,
                                            !formik.values.enable_inventary[
                                              index
                                            ]
                                          )
                                        }
                                      />
                                      {formik.values.enable_inventary[
                                        index
                                      ] && (
                                        <>
                                          <div className="stock-question">
                                            <h1>
                                              What do you want pre-order now to
                                              do?
                                            </h1>
                                          </div>
                                          <div>
                                            <div className="border-bottom pb-2">
                                              <RadioButton
                                                label={
                                                  <div>
                                                    <strong>
                                                      Method 1:&nbsp;
                                                    </strong>
                                                    <span>
                                                      Your pre-order button will
                                                      show up in Shopify when
                                                      the number of items in
                                                      stock is greater than 0.
                                                      when the stock reaches 0,
                                                      the pre-order button
                                                      won'twork until there are
                                                      more than 0.
                                                    </span>
                                                  </div>
                                                }
                                                checked={
                                                  formik.values.method[
                                                    index
                                                  ] === 0
                                                }
                                                id={`method_1.${index}`}
                                                onChange={(
                                                  _checked,
                                                  newValue
                                                ) => {
                                                  formik.setFieldValue(
                                                    `method.${index}`,
                                                    0
                                                  );
                                                }}
                                              />
                                            </div>
                                            <div className="py-2">
                                              <RadioButton
                                                label={
                                                  <div>
                                                    <strong>
                                                      Method 2:&nbsp;
                                                    </strong>
                                                    <span>
                                                      Shopify's add-to-cart
                                                      button will show up when
                                                      the number of items in
                                                      stock is greater than 0.
                                                      Your pre-order button will
                                                      show up when the number of
                                                      items left is less than or
                                                      equal to 0.
                                                    </span>
                                                  </div>
                                                }
                                                checked={
                                                  formik.values.method[
                                                    index
                                                  ] === 1
                                                }
                                                id={`method_2.${index}`}
                                                onChange={(
                                                  _checked,
                                                  newValue
                                                ) =>
                                                  formik.setFieldValue(
                                                    `method.${index}`,
                                                    1
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="stock-note">
                                            <p>
                                              <strong>Note:&nbsp;</strong>For
                                              this feature to work, you must set
                                              your Shopify product settings'
                                              'Inventory policy' to 'Shopify
                                              tracks this product's inventory'
                                              and set an inventory amount. For
                                              way 2 to work, you must check the
                                              box in your Shopify product
                                              settings that says 'Allow
                                              customers to buy this product when
                                              it's out of stock'
                                            </p>
                                          </div>
                                        </>
                                      )}
                                    </LegacyCard>
                                  </div>
                                  <div className="card-border-setting">
                                    <LegacyCard
                                      title="Pre-order period"
                                      sectioned
                                      key={`mainfour-${index}`}
                                    >
                                      <div>
                                        <p>Timezone: Asia/Kolkata</p>
                                        <p className="py-2">
                                          The pre-order time tells you when your
                                          pre-order settings will turn on and/or
                                          off. If you want your pre-order
                                          settings to take effect right away,
                                          leave the pre-order start date blank
                                          or choose a date in the past.
                                        </p>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-6 start_date mob-pb-1">
                                          <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular ">
                                            Pre-order start date
                                          </span>
                                          <Datetime
                                            value={
                                              formik.values.order_start_date[
                                                index
                                              ]
                                            }
                                            onChange={(value) => {
                                              formik.setFieldValue(
                                                `order_start_date.${index}`,
                                                value._d
                                              );
                                            }}
                                            renderInput={(params) => (
                                              <TextField {...params} readOnly />
                                            )}
                                          />
                                        </div>
                                        <div className="col-lg-6 end_date">
                                          <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular">
                                            Pre-order end date
                                          </span>
                                          <Datetime
                                            value={
                                              formik.values.order_end_date[
                                                index
                                              ]
                                            }
                                            onChange={(value) => {
                                              formik.setFieldValue(
                                                `order_end_date.${index}`,
                                                value._d
                                              );
                                            }}
                                            renderInput={(params) => (
                                              <TextField {...params} readOnly />
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </LegacyCard>
                                  </div>
                                  <div className="card-border-setting">
                                    <LegacyCard title="A stock alert" sectioned>
                                      <div className="row">
                                        <div className="display-flex">
                                          <Checkbox
                                            label="Customers shouldn't be able to buy more than the amount of pre-order stock."
                                            checked={
                                              formik.values.prevent_customers[
                                                index
                                              ]
                                            }
                                            onChange={() =>
                                              formik.setFieldValue(
                                                `prevent_customers.${index}`,
                                                !formik.values
                                                  .prevent_customers[index]
                                              )
                                            }
                                          />
                                          <div className="mt-1 ml-1">
                                            <Tooltip
                                              content={`Only works with Inventory Management Setting #1 and Specific Pre-order Stock. This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions.`}
                                              dismissOnMouseOut
                                              borderRadius={1}
                                              width="wide"
                                            >
                                              <Icon
                                                source={InfoIcon}
                                                color="base"
                                              />
                                            </Tooltip>
                                          </div>
                                          {/* <br />
                                      <span className="stock-warning-color">
                                        Only works with Inventory Management
                                        Setting #1 and Specific Pre-order Stock.
                                        This function might not work with every
                                        theme. After you turn this feature on,
                                        we suggest that you check your shop's
                                        "add to cart" and "checkout" functions.
                                      </span> */}
                                        </div>
                                        <div className="row pt-2">
                                          <TextField
                                            label="Not enough items left to pre-order message"
                                            value={
                                              formik.values
                                                .order_remain_message[index]
                                            }
                                            placeholder=""
                                            onChange={(value) => {
                                              formik.setFieldValue(
                                                `order_remain_message.${index}`,
                                                value
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <div className="stock-warning-color">
                                            <p>
                                              Note: We only have qty unit(s)
                                              left. number of units will be
                                              filled right away, and the rest
                                              will have to be pre-ordered.
                                            </p>
                                            <p className="font-12">
                                              Variables: qty. Your Shopify stock
                                              will replace the amount..
                                            </p>
                                          </div>
                                        </div>
                                        <div className="pt-2">
                                          <div className="display-flex">
                                            <Checkbox
                                              label="Tell people when a part of their order will be on pre-order."
                                              checked={
                                                formik.values.notify_customers[
                                                  index
                                                ]
                                              }
                                              onChange={() =>
                                                formik.setFieldValue(
                                                  `notify_customers.${index}`,
                                                  !formik.values
                                                    .notify_customers[index]
                                                )
                                              }
                                            />
                                            <div className="mt-1 ml-1">
                                              <Tooltip
                                                content={`Only for Setting #2 of Inventory Management. This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions`}
                                                dismissOnMouseOut
                                                borderRadius={1}
                                                width="wide"
                                              >
                                                <Icon
                                                  source={InfoIcon}
                                                  color="base"
                                                />
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row pt-2">
                                          <TextField
                                            label="Not enough items left to pre-order message"
                                            value={
                                              formik.values.order_message[index]
                                            }
                                            placeholder=""
                                            onChange={(value) => {
                                              formik.setFieldValue(
                                                `order_message.${index}`,
                                                value
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <div className="stock-warning-color">
                                            <p>
                                              Note: We only have qty unit(s)
                                              left. number of units will be
                                              filled right away, and the rest
                                              will have to be pre-ordered.
                                            </p>
                                            <p className="font-12">
                                              Variables: qty. Your Shopify stock
                                              will replace the amount..
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </LegacyCard>
                                  </div>
                                  <div className="card-border-setting">
                                    <LegacyCard
                                      title={`Product page`}
                                      sectioned
                                    >
                                      <Checkbox
                                        label="Hide the 'buy now' buttons when the current version is on pre-order."
                                        checked={formik.values.buy_now[index]}
                                        onChange={() =>
                                          formik.setFieldValue(
                                            `buy_now.${index}`,
                                            !formik.values.buy_now[index]
                                          )
                                        }
                                      />
                                    </LegacyCard>
                                  </div>
                                  <div className="card-border-setting">
                                    <LegacyCard
                                      title="Cart label"
                                      sectioned
                                      key={`mainsev-${index}`}
                                    >
                                      <div className="row">
                                        <div className="col-lg-12">
                                          <TextField
                                            label="Cart label text"
                                            value={
                                              formik.values.cart_label_text[
                                                index
                                              ]
                                            }
                                            placeholder="Pre-order item"
                                            onChange={(value) => {
                                              formik.setFieldValue(
                                                `cart_label_text.${index}`,
                                                value
                                              );
                                            }}
                                            autoComplete="off"
                                            error={
                                              formik.errors.cart_label_text &&
                                              formik.touched.cart_label_text
                                                ? formik.errors.cart_label_text
                                                : ""
                                            }
                                          />
                                        </div>
                                      </div>
                                    </LegacyCard>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    <div className="text-end pt-3">
                      <Button
                        primary
                        onClick={() => formik.handleSubmit()}
                        loading={loading}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Layout.Section>
              </Layout>
              <Footer />
            </Page>
          ) : productData && Object.keys(productData).length > 0 ? (
            <Page
              backAction={{
                content: "Settings",
                onAction: () => navigate("/"),
              }}
              title={Object.keys(productData).length > 0 && productData.title}
            >
              <Layout>
                <Layout.Section secondary>
                  <LegacyCard key={`cardmain`} sectioned>
                    {/* <div className="custom-single-product-header-title-Product ">
                    Product
                  </div> */}
                    <LegacyStack wrap={false} alignment="center">
                      <LegacyStack.Item>
                        <div className="text-start product-edit-img">
                          <img
                            src={
                              productImage && productImage?.src
                                ? productImage?.src
                                : `${noimg}`
                            }
                            alt="demo"
                            height={"90px"}
                            width={"100px"}
                          />
                        </div>
                      </LegacyStack.Item>
                      <LegacyStack.Item fill>
                        <Text variant="bodyLg" as="p">
                          {Object.keys(productData).length > 0 &&
                            productData.title}
                        </Text>
                      </LegacyStack.Item>
                    </LegacyStack>
                  </LegacyCard>
                  <LegacyCard>
                    <div className="custom-single-product-header-title-Variant  pt-3 pb-3">
                      Variant
                    </div>
                    <ul className="custom-single-product-header-title-Variant_ul">
                      {productVariants &&
                        productVariants.map((x) => {
                          return (
                            <>
                              <div className="Polaris-LegacyCard__Section--subdued Polaris-border-radius polaris-card-section polaris-tabs-stylish">
                                <li
                                  className={`product_variant_name ps-4 ${
                                    x.title == openTab ? "variant_active" : ""
                                  }`}
                                  onClick={() => setOpenTab(x.title)}
                                >
                                  {x.title}
                                </li>
                              </div>
                            </>
                          );
                        })}
                    </ul>
                  </LegacyCard>
                </Layout.Section>
                <Layout.Section>
                  <div>
                    <div className="card-border-setting-padding">
                      {productVariants &&
                        productVariants?.map((item, index) => {
                          return (
                            <>
                              <div
                                className={`edit_pro ${
                                  item.title == openTab ? "d-block" : "d-none"
                                }`}
                              >
                                <LegacyCard sectioned>
                                  <LegacyStack alignment="center">
                                    <LegacyStack.Item fill>
                                      <div class="Polaris-LegacyCard__Header p-0">
                                        <h2 class="Polaris-Text--root Polaris-Text--headingMd">
                                          {item.title}
                                        </h2>
                                      </div>
                                    </LegacyStack.Item>
                                    <LegacyStack.Item>
                                      <div className="text-end">
                                        {productVariants.length > 1 && (
                                          <Button
                                            onClick={() => {
                                              setVarientIndex(index);
                                              copyVarientsSetting(index);
                                            }}
                                            primary
                                          >
                                            Copy settings to all variants
                                          </Button>
                                        )}
                                      </div>
                                    </LegacyStack.Item>
                                  </LegacyStack>
                                  <div className="border-top product-edit-variant-detail">
                                    <LegacyStack
                                      alignment="center"
                                      distribution="equalSpacing"
                                      wrap={false}
                                    >
                                      <LegacyStack.Item fill>
                                        <div className=" px-1">
                                          <h1 className="font-weight-600 py-3">
                                            Variant details
                                          </h1>
                                          <p>
                                            If the details of the products in
                                            Pre-order Now aren't matching <br />
                                            up with Shopify, click here to
                                            quickly fix it.
                                          </p>
                                        </div>
                                      </LegacyStack.Item>
                                      <LegacyStack.Item fill>
                                        <div className=" inventory">
                                          <div className="pt-3 product-table">
                                            <LegacyCard
                                              key={`mainfirst-${index}`}
                                            >
                                              <table className="w-100 table">
                                                <tbody>
                                                  <tr>
                                                    <th>
                                                      Keeping track of stock
                                                    </th>
                                                    <td>
                                                      {item.inventory_management !==
                                                      ""
                                                        ? "Enable"
                                                        : "Disable"}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th>Status of Inventory</th>
                                                    <td>
                                                      {item.inventory_quantity}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th>Quantity Oversell</th>
                                                    <td>
                                                      {item.inventory_management !==
                                                      ""
                                                        ? "Enable"
                                                        : "Disable"}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </LegacyCard>
                                          </div>
                                        </div>
                                      </LegacyStack.Item>
                                    </LegacyStack>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <h1 className="font-weight-600 enable-title p-0">
                                        Enable
                                      </h1>
                                    </div>
                                    <div className="col-lg-9 status-block">
                                      <Checkbox
                                        label="Enable pre-order for this variant"
                                        checked={checked[index]}
                                        onChange={() => handleCheck(index)}
                                        name="is_enable"
                                      />
                                      <div className="d-flex pt-3 row">
                                        <div className="col-lg-6">
                                          <RadioButton
                                            label="Use default settings"
                                            checked={!settingType[index]}
                                            disabled={!checked[index]}
                                            onChange={(_checked, newValue) =>
                                              handleChange(
                                                _checked,
                                                newValue,
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-lg-6">
                                          <RadioButton
                                            label="Use settings you choose."
                                            checked={settingType[index]}
                                            disabled={!checked[index]}
                                            onChange={(_checked, newValue) =>
                                              handleChange(
                                                _checked,
                                                newValue,
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </LegacyCard>
                                {checked[index] && settingType[index] && (
                                  <div className="row">
                                    {/* Button Card */}
                                    <div className="card-border-setting">
                                      <LegacyCard
                                        title="Button"
                                        sectioned
                                        key={`cardtwo-${index}`}
                                      >
                                        <div className="row">
                                          <div className="col-lg-4">
                                            <TextField
                                              label="Text on button "
                                              value={formik.values.text[index]}
                                              placeholder="Preorder now"
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `text.${index}`,
                                                  value
                                                );
                                              }}
                                              autoComplete="off"
                                              onBlur={formik.handleBlur}
                                              error={
                                                formik.errors.text &&
                                                formik.touched.text
                                                  ? formik.errors.text
                                                  : ""
                                              }
                                            />
                                          </div>
                                          <div className="col-lg-4 mob-pt-1">
                                            <TextField
                                              label="Pre-order message"
                                              value={
                                                formik.values.message[index]
                                              }
                                              placeholder="hello"
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `message.${index}`,
                                                  value
                                                );
                                              }}
                                              autoComplete="off"
                                            />
                                          </div>
                                          <div className="col-lg-4 mob-pt-1">
                                            <Select
                                              label="message placements"
                                              options={placementOptions}
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `message_placement.${index}`,
                                                  value
                                                );
                                              }}
                                              value={
                                                formik.values.message_placement[
                                                  index
                                                ]
                                              }
                                            />
                                          </div>
                                        </div>
                                      </LegacyCard>
                                    </div>
                                    {/* Stock Method Card */}
                                    <div className="card-border-setting">
                                      <LegacyCard
                                        title="Stock"
                                        sectioned
                                        key={`cardthird-${index}`}
                                      >
                                        <Checkbox
                                          label="Make it possible to control stock"
                                          checked={
                                            formik.values.enable_inventary[
                                              index
                                            ]
                                          }
                                          onChange={() =>
                                            formik.setFieldValue(
                                              `enable_inventary.${index}`,
                                              !formik.values.enable_inventary[
                                                index
                                              ]
                                            )
                                          }
                                        />
                                        {formik.values.enable_inventary[
                                          index
                                        ] && (
                                          <>
                                            <div className="stock-question">
                                              <h1>
                                                What do you want pre-order now
                                                to do?
                                              </h1>
                                            </div>
                                            <div>
                                              <div className="border-bottom pb-2">
                                                <RadioButton
                                                  label={
                                                    <div>
                                                      <strong>
                                                        Method 1:&nbsp;
                                                      </strong>
                                                      <span>
                                                        Your pre-order button
                                                        will show up in Shopify
                                                        when the number of items
                                                        in stock is greater than
                                                        0. when the stock
                                                        reaches 0, the pre-order
                                                        button won'twork until
                                                        there are more than 0.
                                                      </span>
                                                    </div>
                                                  }
                                                  checked={
                                                    formik.values.method[
                                                      index
                                                    ] === 0
                                                  }
                                                  id={`method_1.${index}`}
                                                  onChange={(
                                                    _checked,
                                                    newValue
                                                  ) => {
                                                    formik.setFieldValue(
                                                      `method.${index}`,
                                                      0
                                                    );
                                                  }}
                                                />
                                              </div>
                                              <div className="py-2">
                                                <RadioButton
                                                  label={
                                                    <div>
                                                      <strong>
                                                        Method 2:&nbsp;
                                                      </strong>
                                                      <span>
                                                        Shopify's add-to-cart
                                                        button will show up when
                                                        the number of items in
                                                        stock is greater than 0.
                                                        Your pre-order button
                                                        will show up when the
                                                        number of items left is
                                                        less than or equal to 0.
                                                      </span>
                                                    </div>
                                                  }
                                                  checked={
                                                    formik.values.method[
                                                      index
                                                    ] === 1
                                                  }
                                                  id={`method_2.${index}`}
                                                  onChange={(
                                                    _checked,
                                                    newValue
                                                  ) =>
                                                    formik.setFieldValue(
                                                      `method.${index}`,
                                                      1
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="stock-note">
                                              <p>
                                                <strong>Note:&nbsp;</strong>For
                                                this feature to work, you must
                                                set your Shopify product
                                                settings' 'Inventory policy' to
                                                'Shopify tracks this product's
                                                inventory' and set an inventory
                                                amount. For way 2 to work, you
                                                must check the box in your
                                                Shopify product settings that
                                                says 'Allow customers to buy
                                                this product when it's out of
                                                stock'
                                              </p>
                                            </div>
                                          </>
                                        )}
                                      </LegacyCard>
                                    </div>
                                    {/* Product Image Card */}
                                    {/* Pre-order Period Card */}
                                    <div className="card-border-setting">
                                      <LegacyCard
                                        title="Pre-order period"
                                        sectioned
                                        key={`cardsix-${index}`}
                                      >
                                        <div className="row ">
                                          <div>
                                            <p>Timezone: Asia/Kolkata</p>
                                            <p className="py-2">
                                              The pre-order time tells you when
                                              your pre-order settings will turn
                                              on and/or off. If you want your
                                              pre-order settings to take effect
                                              right away, leave the pre-order
                                              start date blank or choose a date
                                              in the past.
                                            </p>
                                          </div>
                                          <div className="col-lg-6 start_date mob-pb-1">
                                            <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular mob-pb-1">
                                              Pre-order start date
                                            </span>
                                            <Datetime
                                              value={
                                                formik.values.order_start_date[
                                                  index
                                                ]
                                              }
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `order_start_date.${index}`,
                                                  value._d
                                                );
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  readOnly
                                                />
                                              )}
                                            />
                                          </div>
                                          <div className="col-lg-6 end_date">
                                            <span className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--regular">
                                              Pre-order end date
                                            </span>
                                            <Datetime
                                              value={
                                                formik.values.order_end_date[
                                                  index
                                                ]
                                              }
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `order_end_date.${index}`,
                                                  value._d
                                                );
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  readOnly
                                                />
                                              )}
                                            />
                                          </div>
                                        </div>
                                      </LegacyCard>
                                    </div>
                                    <div className="card-border-setting">
                                      <LegacyCard
                                        title="A stock alert"
                                        sectioned
                                      >
                                        <div className="row">
                                          <div className="display-flex">
                                            <Checkbox
                                              label="Prevent customers from ordering more than the pre-order stock available"
                                              checked={
                                                formik.values.prevent_customers[
                                                  index
                                                ]
                                              }
                                              onChange={() =>
                                                formik.setFieldValue(
                                                  `prevent_customers.${index}`,
                                                  !formik.values
                                                    .prevent_customers[index]
                                                )
                                              }
                                            />
                                            <div className="mt-1 ml-1">
                                              <Tooltip
                                                content={`Only works with Inventory Management Setting #1 and Specific Pre-order Stock. This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions.`}
                                                dismissOnMouseOut
                                                borderRadius={1}
                                                width="wide"
                                              >
                                                <Icon
                                                  source={InfoIcon}
                                                  color="base"
                                                />
                                              </Tooltip>
                                            </div>
                                          </div>
                                          <div className="row pt-2">
                                            <TextField
                                              label="Not enough items left to pre-order message"
                                              value={
                                                formik.values
                                                  .order_remain_message[index]
                                              }
                                              placeholder=""
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `order_remain_message.${index}`,
                                                  value
                                                );
                                              }}
                                              autoComplete="off"
                                            />
                                            <div className="stock-warning-color">
                                              <p>
                                                Note: We only have qty unit(s)
                                                left. number of units will be
                                                filled right away, and the rest
                                                will have to be pre-ordered.
                                              </p>
                                              <p className="font-12">
                                                Variables: qty. Your Shopify
                                                stock will replace the amount.
                                              </p>
                                            </div>
                                          </div>
                                          <div className="pt-2">
                                            <div className="display-flex">
                                              <Checkbox
                                                label="Tell people when a part of their order will be on pre-order."
                                                checked={
                                                  formik.values
                                                    .notify_customers[index]
                                                }
                                                onChange={() =>
                                                  formik.setFieldValue(
                                                    `notify_customers.${index}`,
                                                    !formik.values
                                                      .notify_customers[index]
                                                  )
                                                }
                                              />
                                              <div className="mt-1 ml-1">
                                                <Tooltip
                                                  content={`Only works with make it possible to control stock for Setting #2 . This function might not work with every theme. After you turn this feature on, we suggest that you check your shop's "add to cart" and "checkout" functions.`}
                                                  dismissOnMouseOut
                                                  borderRadius={1}
                                                  width="wide"
                                                >
                                                  <Icon
                                                    source={InfoIcon}
                                                    color="base"
                                                  />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row pt-2">
                                            <TextField
                                              label="Not enough items left to pre-order message"
                                              value={
                                                formik.values.order_message[
                                                  index
                                                ]
                                              }
                                              placeholder=""
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `order_message.${index}`,
                                                  value
                                                );
                                              }}
                                              autoComplete="off"
                                            />
                                            <div className="stock-warning-color">
                                              <p>
                                                Note: We only have qty unit(s)
                                                left. number of units will be
                                                filled right away, and the rest
                                                will have to be pre-ordered.
                                              </p>
                                              <p className="font-12">
                                                Variables: qty. Your Shopify
                                                stock will replace the amount.
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </LegacyCard>
                                    </div>
                                    <div className="card-border-setting">
                                      <LegacyCard
                                        title={`Product page`}
                                        sectioned
                                      >
                                        <Checkbox
                                          label="Hide the 'buy now' buttons when the current version is on pre-order."
                                          checked={formik.values.buy_now[index]}
                                          onChange={() =>
                                            formik.setFieldValue(
                                              `buy_now.${index}`,
                                              !formik.values.buy_now[index]
                                            )
                                          }
                                        />
                                      </LegacyCard>
                                    </div>
                                    {/* Label Card /> */}
                                    <div className="card-border-setting">
                                      <LegacyCard
                                        title="Cart label"
                                        sectioned
                                        key={`cardeight-${index}`}
                                      >
                                        <div className="row">
                                          <div className="col-lg-12">
                                            <TextField
                                              label="Cart label text"
                                              value={
                                                formik.values.cart_label_text[
                                                  index
                                                ]
                                              }
                                              placeholder=""
                                              onChange={(value) => {
                                                formik.setFieldValue(
                                                  `cart_label_text.${index}`,
                                                  value
                                                );
                                              }}
                                              autoComplete="off"
                                              error={
                                                formik.errors.cart_label_text &&
                                                formik.touched.cart_label_text
                                                  ? formik.errors
                                                      .cart_label_text
                                                  : ""
                                              }
                                            />
                                          </div>
                                        </div>
                                      </LegacyCard>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                    </div>
                    <div className="text-end">
                      <Button
                        primary
                        onClick={() => formik.handleSubmit()}
                        loading={loading}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Layout.Section>
              </Layout>
              <Footer />
            </Page>
          ) : null}
        </>
      )}
      <div className="destructive_btn">
        <Modal
          open={activeModal}
          onClose={handleModal}
          title="Confirm"
          primaryAction={{
            content: "Yes",
            destructive: false,
            onAction: () => {
              handleModal(varientIndex, true);
            },
          }}
          secondaryActions={[
            {
              content: "No",
              onAction: handleModal,
            },
          ]}
        >
          <Modal.Section>
            <Text>
              <p>
                Are you sure you'd like to apply these pre-order settings to all
                variants?
              </p>
            </Text>
          </Modal.Section>
        </Modal>
      </div>
      {toastMarkup}
    </>
  );
};

export default EditSingleProduct;

