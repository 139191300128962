import { FooterHelp, Link } from "@shopify/polaris";
import React from "react";
import { Crisp } from "crisp-sdk-web";
import { useSelector } from 'react-redux';

const Footer = () => {

  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;


  const handleIntercomClick = (e) => {  
    e.preventDefault();  
    Crisp.chat.open(); 
};  


  return (
    <FooterHelp>
    The Pre Order Button may not appear for some themes or it may require some customization.
  {" "} 
      <Link url="#" onClick={handleIntercomClick}>
      {" "}Contact Us
      </Link>
    </FooterHelp>
  );
};
export default Footer;
