import {
  ColorPicker as PolarisColorPicker,
  hexToRgb,
  hsbToHex,
  InlineStack,
  Popover,
  rgbToHsb,
  TextField,
} from "@shopify/polaris";
import { useState } from "react";

const ColorPicker = ({ label, value, onChange }) => {
  let [active, setActive] = useState(false);

  const handleColorInputChange = (value) => {
    if (value.charAt(0) == "#" || value == "") {
      onChange(value);
    }
  };

  let togglePopover = () => setActive(!active);

  return (
    <div>
      <TextField
        label={label}
        placeholder="HEX"
        autoComplete="off"
        maxLength={7}
        value={value}
        onChange={handleColorInputChange}
        suffix={
          <Popover
            active={active}
            activator={<ColorBox color={value} handleClick={togglePopover} />}
            onClose={togglePopover}
            preferredAlignment="right"
            preferredPosition="below"
          >
            <div style={{ width: "240px" }}>
              <PolarisColorPicker
                fullWidth
                color={rgbToHsb(hexToRgb(value))}
                onChange={(hsb) => onChange(hsbToHex(hsb))}
              />
            </div>
          </Popover>
        }
      />
    </div>
  );
};

export default ColorPicker;
const ColorBox = ({ color, handleClick }) => (
  <InlineStack gap={"150"}>
    <div
      style={{
        width: "1px",
        height: "20px",
        background: "var(--p-color-input-border)",
      }}
    />
    <div
      onClick={handleClick}
      style={{
        width: "26px",
        height: "20px",
        background: color,
        border: "1px solid var(--p-color-input-border)",
        borderRadius: "6px",
        cursor: "pointer",
      }}
    />
  </InlineStack>
);
