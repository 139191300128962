import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  Grid,
  InlineStack,
  Page,
  Text,
  TextField,
  BlockStack,
} from "@shopify/polaris";

const BackInStock_setting_additional = () => {
  let [css, setCss] = useState("");

  let navigate = useNavigate();
  let handleBackAction = () => {
    navigate("/back-in-stock");
  };

  let handleSubmit = () => {
    console.log(css);
  };

  return (
    <Page title="Additional" backAction={{ onAction: handleBackAction }}>
      <BlockStack gap={"300"}>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              Additional CSS
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <TextField
                type="text"
                label="Custom CSS"
                value={css}
                onChange={setCss}
                multiline={6}
              />
            </Card>
          </Grid.Cell>
        </Grid>
        <InlineStack align="end">
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </InlineStack>
      </BlockStack>
    </Page>
  );
};

export default BackInStock_setting_additional;
