import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  Page,
  Layout,
  Button,
  Toast,
  LegacyCard,
  AppProvider,
} from "@shopify/polaris";
import { useFormik } from "formik";
import CustomSettingButton from "../components/SettingPageComponents/CustomSettingButton";
import CustomSettingMethod from "../components/SettingPageComponents/CustomSettingMethod";
import CustomImageBadge from "../components/SettingPageComponents/CustomImageBadge";
import CustomOrderPeriod from "../components/SettingPageComponents/CustomOrderPeriod";
import CustomStockWarning from "../components/SettingPageComponents/CustomStockWarning";
import CustomCartLabel from "../components/SettingPageComponents/CustomCartLabel";
import CustomProductPage from "../components/SettingPageComponents/CustomProductPage";
import { useSelector } from "react-redux";
import { ApiCall, GetApiCall } from "../helpers/axios";
import {
  htmlSpecialCharacterEncode,
  htmlspecialchars_decode,
} from "../helpers/commonFunctions";
import {
  Provider as AppBridgeProvider,
  TitleBar,
} from "@shopify/app-bridge-react";
import { config } from "../config";

const SingleProductSetting = ({ onboardingFormik = undefined }) => {
  let location = useLocation();
  const navigate = useNavigate();
  let { product_id, type, variant_id } = useParams();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const token_data = shopinfo.auth_token;
  const shop_data = shopinfo.shopInfo;
  const store_client_id = shop_data.store_client_id;
  const [toastMessage, setToastMessage] = useState("");
  const [activeToast, setActiveToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variantTitle, setVariantTitle] = useState("");
  const [initialState, setInitialState] = useState({
    text: "",
    message: "",
    message_placement: "",
    enable_inventary: false,
    method: "method_1",
    badge_enabled: false,
    badge_shape: "Ribbon",
    badge_text: "Ribbon",
    order_start_date: "",
    order_end_date: "",
    prevent_customers: false,
    notify_customers: false,
    diplay_order_items: false,
    order_remain_message: "",
    order_message: "",
    cart_warning_title: "",
    cart_warnig_text: "",
    product_tag: "",
    sync_inventary: false,
    hide_buy_button: false,
    display_badge_homepage: false,
    show_badge_varient: false,
    display_button_homepage: false,
    cart_label_text: "",
    variant_name: "",
  });

  const toggleActiveToast = useCallback(
    () => setActiveToast((active) => !active),
    []
  );

  const toastMarkup = activeToast ? (
    <Toast
      content={toastMessage}
      onDismiss={toggleActiveToast}
      duration={1000}
    />
  ) : null;

  useEffect(() => {
    if (product_id && type) {
      getdashboardProductdata(product_id, type);
    }
  }, [product_id, shop_data]);

  const getdashboardProductdata = async (product_id, type) => {
    if (store_client_id) {
      let res = await GetApiCall(
        "GET",
        `/getdashordProsettings?store_client_id=${store_client_id}&id=${product_id}&type=${type}`,
        { authentication: token_data }
      );
      if (res.status == 200) {
        let productSetting = res.data.data;
        let default_settings = JSON.parse(productSetting.product_settings);
        const final_data = {};
        final_data.text = htmlspecialchars_decode(
          default_settings[variant_id].product_text
        );
        final_data.message = htmlspecialchars_decode(
          default_settings[variant_id].product_message
        );
        final_data.message_placement =
          default_settings[variant_id].product_message_placement;
        final_data.enable_inventary =
          default_settings[variant_id].product_enable_inventary;
        final_data.method = default_settings[variant_id].product_method;
        final_data.badge_enabled =
          default_settings[variant_id].product_badge_enabled;
        final_data.badge_shape =
          default_settings[variant_id].product_badge_shape;
        final_data.badge_text = htmlspecialchars_decode(
          default_settings[variant_id].product_badge_text
        );
        final_data.order_start_date =
          default_settings[variant_id].product_order_start_date;
        final_data.order_end_date =
          default_settings[variant_id].product_order_end_date;
        final_data.cart_label_text = htmlspecialchars_decode(
          default_settings[variant_id].product_cart_label_text
        );
        final_data.hide_buy_button =
          default_settings[variant_id].product_buy_now;
        final_data.prevent_customers =
          default_settings[variant_id].product_prevent_customers;
        final_data.order_remain_message = htmlspecialchars_decode(
          default_settings[variant_id].product_order_remain_message
        );
        final_data.notify_customers =
          default_settings[variant_id].product_notify_customers;
        final_data.order_message = htmlspecialchars_decode(
          default_settings[variant_id].product_order_message
        );
        setVariantTitle(default_settings[variant_id].product_variant_name);
        setInitialState(final_data);
      }
    }
  };
  const singleProductSettingFormik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    onSubmit: (values) => {
      productEdit(values);
    },
  });

  const productEdit = async (values) => {
    setLoading(true);
    if (Object.keys(values).length > 0) {
      let data = {
        is_enable: "1",
        is_custome_setting: "1",
        product_text: htmlSpecialCharacterEncode(values.text),
        product_message: htmlSpecialCharacterEncode(values.message),
        product_message_placement: values.message_placement,
        product_enable_inventary: values.enable_inventary,
        product_method: values.method,
        product_badge_enabled: values.badge_enabled,
        product_badge_shape: values.badge_shape,
        product_badge_text: htmlSpecialCharacterEncode(values.badge_text),
        product_order_start_date: values.order_start_date,
        product_order_end_date: values.order_end_date,
        product_buy_now: values.hide_buy_button,
        product_prevent_customers: values.prevent_customers,
        product_order_remain_message: htmlSpecialCharacterEncode(
          values.order_remain_message
        ),
        product_notify_customers: values.notify_customers,
        product_order_message: htmlSpecialCharacterEncode(values.order_message),
        product_cart_label_text: htmlSpecialCharacterEncode(
          values.cart_label_text
        ),
        product_variant_name: variantTitle,
      };
      let obj = {};
      const res_arr = {};
      res_arr[variant_id] = data;
      obj["data"] = res_arr;
      obj["product_id"] = product_id;
      obj["store_client_id"] = store_client_id;
      let res = await ApiCall("PUT", "/dashboard-single-product", obj, {
        authentication: token_data,
      });
      if (res.data.statusCode === 200) {
        navigate("/");
        setToastMessage(res.data.message);
        toggleActiveToast();
        setLoading(false);
      }
    }
  };

  let formik =
    onboardingFormik === undefined
      ? singleProductSettingFormik
      : onboardingFormik;

  const placementOptions = [
    { label: "Below Add to Cart Button", value: "1" },
    { label: "Above Add to Cart Button", value: "2" },
  ];

  const page_html = (
    <>
      <Page
        backAction={{ content: "Products", onAction: () => navigate("/") }}
        title={`Pre-order setting`}
      >
        <LegacyCard>
          <CustomSettingButton
            formik={formik}
            placementOptions={placementOptions}
          />

          <CustomSettingMethod
            formik={formik}
            title={"Stock"}
            label={"Enable inventory management"}
            head={"What do you want pre-order now to do?"}
            method_1={
              "Your pre-order button will show up in Shopify when the number of items in stock is greater than 0. when the stock reaches 0, the pre-order button won'twork until there are more than 0."
            }
            method_2={
              "Shopify's add-to-cart button will show up when the number of items in stock is greater than 0. Your pre-order button will show up when the number of items left is less than or equal to 0."
            }
            note={
              "For this feature to work, you must set your Shopify product settings' 'Inventory policy' to 'Shopify tracks this product's inventory' and set an inventory amount. For way 2 to work, you must check the box in your Shopify product settings that says 'Allow customers to buy this product when it's out of stock'"
            }
          />

          <CustomImageBadge
            title={"Product image badge"}
            formik={formik}
            label={"Badge enabled"}
            shape={"Badge shape"}
            text={"Badge text"}
            strong={"Badge doesn't show up?"}
            p={
              "For this feature to work with your theme, it may need to be set up in a certain way. Contact support if you need help."
            }
          />

          <CustomOrderPeriod
            title={"Pre-order period"}
            formik={formik}
            p={`The pre-order time tells you when your pre-order settings will turn on and/or off. If you want your pre-order settings to take effect right away, leave the pre-order start date blank or choose a date in the past.`}
          />

          <CustomStockWarning title={"Stock warning"} formik={formik} />

          <CustomProductPage title={"Product pages"} formik={formik} />

          <CustomCartLabel title={"Cart label"} formik={formik} />
        </LegacyCard>
        {location.pathname.includes("/single-product") && (
          <div className="text-end py-3">
            <Button
              primary
              onClick={() => formik.handleSubmit()}
              loading={loading}
            >
              Save
            </Button>
          </div>
        )}
      </Page>
    </>
  );

  return location.pathname.includes("/single-product") ? (
    <>
      {window.location.hostname !== "localhost" ? (
        <>
          <AppProvider>
            {/* <AppBridgeProvider config={config}> */}
            <TitleBar
              title="Edit product"
              breadcrumbs={[
                { content: "Dashboard", onAction: () => navigate("/") },
              ]}
            />
            {page_html}
            {/* </AppBridgeProvider> */}
          </AppProvider>
        </>
      ) : (
        page_html
      )}
    </>
  ) : (
    <Layout>
      <Layout.Section>
        <CustomSettingButton
          formik={formik}
          placementOptions={placementOptions}
        />

        <CustomSettingMethod
          formik={formik}
          title={"Stock"}
          label={"Enable inventory management"}
          head={"What do you want pre-order now to do?"}
          method_1={
            "Your pre-order button will show up in Shopify when the number of items in stock is greater than 0. when the stock reaches 0, the pre-order button won'twork until there are more than 0."
          }
          method_2={
            "Shopify's add-to-cart button will show up when the number of items in stock is greater than 0. Your pre-order button will show up when the number of items left is less than or equal to 0."
          }
          note={
            "For this feature to work, you must set your Shopify product settings' 'Inventory policy' to 'Shopify tracks this product's inventory' and set an inventory amount. For way 2 to work, you must check the box in your Shopify product settings that says 'Allow customers to buy this product when it's out of stock'"
          }
        />

        <CustomImageBadge
          title={"Product image badge"}
          formik={formik}
          label={"Badge enabled"}
          shape={"Badge shape"}
          text={"Badge text"}
          strong={"Badge doesn't show up?"}
          p={
            "For this feature to work with your theme, it may need to be set up in a certain way. Contact support if you need help."
          }
        />

        <CustomOrderPeriod
          title={"Pre-order period"}
          formik={formik}
          p={`The pre-order time tells you when your pre-order settings will turn on and/or off. If you want your pre-order settings to take effect right away, leave the pre-order start date blank or choose a date in the past.`}
        />

        <CustomStockWarning title={"Stock warning"} formik={formik} />

        <CustomProductPage title={"Product pages"} formik={formik} />

        <CustomCartLabel title={"Cart label"} formik={formik} />

        {location.pathname.includes("/single-product") && (
          <div className="text-end py-3">
            <Button primary loading={loading}>
              Save
            </Button>
          </div>
        )}
      </Layout.Section>
    </Layout>
  );
};

export default SingleProductSetting;
