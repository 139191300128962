import { useCallback, useEffect, useState } from "react";
import {
  IndexTable,
  Card,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  ChoiceList,
  Badge,
  IndexFiltersMode,
  Modal,
  Box,
} from "@shopify/polaris";
import { useNavigate } from "react-router-dom";

function MainTable() {
  let navigate = useNavigate();
  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];
  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);

  const [statusFilter, setStatusFilter] = useState(undefined);
  const [queryValue, setQueryValue] = useState("");

  const handleStatusFilterChange = useCallback(
    (value) => setStatusFilter(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleStatusFilterRemove = useCallback(
    () => setStatusFilter(undefined),
    []
  );
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleStatusFilterRemove();
    handleQueryValueRemove();
  }, [handleStatusFilterRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "status",
      label: "Status",
      filter: (
        <ChoiceList
          choices={[
            { label: "All submissions", value: "all" },
            { label: "Email sent", value: "email-sent" },
            { label: "Email pending", value: "email-pending" },
          ]}
          selected={statusFilter || []}
          onChange={handleStatusFilterChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (statusFilter && !isEmpty(statusFilter)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, statusFilter[0]),
      onRemove: handleStatusFilterRemove,
    });
  }

  const [tableData, setTableData] = useState([]);
  const resourceName = {
    singular: "result",
    plural: "results",
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(tableData);

  const tableHeadings = [
    { title: "Subscriber" },
    { title: "Product" },
    { title: "Status" },
    { title: "Subscribed date" },
  ];

  const dummyData = [
    {
      id: "1",
      subscriber: "abc@abc.com",
      product: "swiss knife",
      type: "pending",
      date: "Nov 25 at 02:26 AM",
    },
    {
      id: "2",
      subscriber: "xyz@xyz.com",
      product: "shoes",
      type: "sent",
      date: "Nov 25 at 02:26 AM",
    },
    {
      id: "3",
      subscriber: "test@test.com",
      product: "coat",
      type: "pending",
      date: "Nov 25 at 02:26 AM",
    },
  ];

  useEffect(() => {
    setTableData(dummyData);
  }, []);

  useEffect(() => {
    setTableData(dummyData);
  }, []);

  const [isDeleteProductModalOpen, setIsDeleteProductModalOpen] =
    useState(false);
  const toggleDeleteProductModal = () => {
    setIsDeleteProductModalOpen(!isDeleteProductModalOpen);
  };

  let handleRemoveTableRecord = () => {
    toggleDeleteProductModal();
  };

  let handleRemoveTableRecordSubmit = () => {
    console.log("items deleted", selectedResources);
    clearSelection();
    toggleDeleteProductModal();
  };

  const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState(false);
  const toggleSendEmailModal = () => {
    setIsSendEmailModalOpen(!isSendEmailModalOpen);
  };

  let sendEmail = () => {
    toggleSendEmailModal();
  };

  let handleSendEmailSubmit = () => {
    console.log("email sent to: ", selectedResources);
    clearSelection();
    toggleSendEmailModal();
  };

  let handleExportSubmission = () => {
    console.log("handleExportSubmission", selectedResources);
    clearSelection();
  };

  let openSubmission = (id) => {
    navigate(`/back-in-stock/submission/${id}`);
  };

  return (
    <Card padding={"0"}>
      <IndexFilters
        sortOptions={sortOptions}
        sortSelected={sortSelected}
        onSort={setSortSelected}
        queryValue={queryValue}
        queryPlaceholder="Search by customer email, product title, variant title, sku"
        onQueryChange={handleFiltersQueryChange}
        onQueryClear={() => setQueryValue("")}
        tabs={[]}
        filters={filters}
        appliedFilters={appliedFilters}
        onClearAll={handleFiltersClearAll}
        mode={mode}
        setMode={setMode}
      />
      <IndexTable
        resourceName={resourceName}
        itemCount={tableData.length}
        selectedItemsCount={
          allResourcesSelected ? "All" : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
        headings={tableHeadings}
        promotedBulkActions={[
          {
            content: "Send mail",
            onAction: sendEmail,
          },
          {
            content: "Export submissions",
            onAction: handleExportSubmission,
          },
          {
            content: "Delete",
            onAction: handleRemoveTableRecord,
          },
        ]}
      >
        {tableData.map((item, index) => (
          <IndexTable.Row
            id={item.id}
            key={item.id}
            selected={selectedResources.includes(item.id)}
            position={index}
          >
            <IndexTable.Cell>
              <div onClick={() => openSubmission(item.id)}>
                {item.subscriber}
              </div>
            </IndexTable.Cell>
            <IndexTable.Cell>{item.product}</IndexTable.Cell>
            <IndexTable.Cell>
              {item.type == "pending" && <Badge>Email pending</Badge>}
              {item.type == "sent" && <Badge tone="success">Email sent</Badge>}
            </IndexTable.Cell>
            <IndexTable.Cell>{item.date}</IndexTable.Cell>
          </IndexTable.Row>
        ))}
      </IndexTable>

      {/* delete confirmation modal */}
      <Modal
        open={isDeleteProductModalOpen}
        onClose={toggleDeleteProductModal}
        title="Delete submission?"
        primaryAction={{
          content: "Delete",
          destructive: true,
          onAction: handleRemoveTableRecordSubmit,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: toggleDeleteProductModal,
          },
        ]}
      >
        <Box padding={"5"}>
          All submissions data also are removed. Once deleted, it cannot be
          completed. Are you sure you want to delete?
        </Box>
      </Modal>
      {/* email confirmation modal */}
      <Modal
        open={isSendEmailModalOpen}
        onClose={toggleSendEmailModal}
        title="Are you sure you want to send email?"
        primaryAction={{
          content: "Send",
          onAction: handleSendEmailSubmit,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: toggleSendEmailModal,
          },
        ]}
      >
        <Box padding={"5"}>
          You're about to send an email to the selected submission(s), are you
          sure you want to send it?
        </Box>
      </Modal>
    </Card>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "status":
        if (value == "all") return `Status: All submissions`;
        if (value == "email-sent") return `Status: Email sent`;
        if (value == "email-pending") return `Status: Email pending`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
}

export default MainTable;
