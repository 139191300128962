import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { AppProvider, Frame } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import { RecoilRoot } from "recoil";
// import { IntercomProvider } from 'react-use-intercom';

const root = ReactDOM.createRoot(document.getElementById("root"));
// const INTERCOM_APP_ID = 'jy9hfhd2';

root.render(
  <AppProvider i18n={enTranslations}>
    <Frame>
      {/* <IntercomProvider appId={INTERCOM_APP_ID} autoBoot> */}
      <RecoilRoot>
        <Provider store={store}>
          <App />
        </Provider>
      </RecoilRoot>
      {/* </IntercomProvider> */}
    </Frame>
  </AppProvider>
);
