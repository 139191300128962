import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Card,
  Button,
  Checkbox,
  Grid,
  InlineStack,
  Page,
  Select,
  Text,
  TextField,
  BlockStack,
} from "@shopify/polaris";

const BackInStock_setting_activity = () => {
  let navigate = useNavigate();

  let [isActivityUpdate, setIsActivityUpdate] = useState(false);
  let [activityNotificationQty, setActivityNotificationQty] = useState(1);
  let [activityEmails, setActivityEmails] = useState("");

  let [isSummaryReports, setIsSummaryReports] = useState(false);
  let [summaryReportsEmails, setSummaryReportsEmails] = useState("");
  let [summaryReportsNotificationOrder, setSummaryReportsNotificationOrder] =
    useState("daily");
  let [summaryReportsDeliveryInterval, setSummaryReportsDeliveryInterval] =
    useState("today");

  let [isCustomerSubscribe, setIsCustomerSubscribe] = useState(false);
  let [customerSubscribeEmails, setCustomerSubscribeEmails] = useState("");

  let [isThankYouMsg, setIsThankYouMsg] = useState(false);

  let handleBackAction = () => {
    navigate("/back-in-stock");
  };

  let handleSubmit = () => {
    let data = {
      activity_update: {
        isChecked: isActivityUpdate,
        notification_qty: activityNotificationQty,
        emails: activityEmails,
      },
      summary_reports: {
        isChecked: isSummaryReports,
        emails: summaryReportsEmails,
        notification_order: summaryReportsNotificationOrder,
        delivery_intervals: summaryReportsDeliveryInterval,
      },
      customers_subscribe: {
        isChecked: isCustomerSubscribe,
        emails: customerSubscribeEmails,
      },
      thank_you_msg: {
        isChecked: isThankYouMsg,
      },
    };
    console.log(data);
  };

  return (
    <Page title="Activity updates" backAction={{ onAction: handleBackAction }}>
      <BlockStack gap={"300"}>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              Activity updates
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <BlockStack gap={"300"}>
                <Checkbox
                  label="Automatically send an email to you when the product is running out of stock"
                  checked={isActivityUpdate}
                  onChange={setIsActivityUpdate}
                />
                {isActivityUpdate && (
                  <>
                    <TextField
                      type="number"
                      value={activityNotificationQty}
                      onChange={setActivityNotificationQty}
                      min={1}
                      helpText="Quantity at which you will receive a notification when a
                      product is nearing out-of-stock status."
                    />
                    <TextField
                      type="text"
                      value={activityEmails}
                      onChange={setActivityEmails}
                      helpText={
                        <Text as="p" variant="bodyMd">
                          You can enter multiple email addresses separated by
                          commas, or leave the field blank to receive
                          notifications at your Shopify registered email. A
                          maximum of five email addresses is allowed; if you
                          need more, please <Link>contact us</Link> to unlock
                          additional options.
                        </Text>
                      }
                    />
                  </>
                )}
              </BlockStack>
            </Card>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              Summary reports
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <BlockStack gap={"300"}>
                <Checkbox
                  label="Automatically send a summary of notifications to the admin."
                  checked={isSummaryReports}
                  onChange={setIsSummaryReports}
                />
                {isSummaryReports && (
                  <>
                    <TextField
                      label="Send to"
                      value={summaryReportsEmails}
                      onChange={setSummaryReportsEmails}
                      helpText={
                        <Text as="p" variant="bodyMd">
                          You can enter multiple email addresses separated by
                          commas, or leave the field blank to receive
                          notifications at your Shopify registered email. A
                          maximum of five email addresses is allowed; if you
                          need more, please <Link>contact us</Link> to unlock
                          additional options.
                        </Text>
                      }
                    />
                    <Select
                      label="Notification order"
                      options={[
                        {
                          label: "Daily",
                          value: "daily",
                        },
                        {
                          label: "Weakly",
                          value: "weakly",
                        },
                      ]}
                      value={summaryReportsNotificationOrder}
                      onChange={setSummaryReportsNotificationOrder}
                    />
                    <Select
                      label="Delivery interval"
                      options={[
                        {
                          label: "Today",
                          value: "today",
                        },
                        {
                          label: "Yesterday",
                          value: "yesterday",
                        },
                      ]}
                      value={summaryReportsDeliveryInterval}
                      onChange={setSummaryReportsDeliveryInterval}
                    />
                  </>
                )}
              </BlockStack>
            </Card>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              Customers subscribe
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <BlockStack gap={"300"}>
                <Checkbox
                  label="Auto-send notification email to the admin when the customer registers back in stock"
                  checked={isCustomerSubscribe}
                  onChange={setIsCustomerSubscribe}
                />
                {isCustomerSubscribe && (
                  <>
                    <TextField
                      type="text"
                      label="Send to"
                      helpText={
                        <Text as="p" variant="bodyMd">
                          You can enter multiple email addresses separated by
                          commas, or leave the field blank to receive
                          notifications at your Shopify registered email. A
                          maximum of five email addresses is allowed; if you
                          need more, please <Link>contact us</Link> to unlock
                          additional options.
                        </Text>
                      }
                      value={customerSubscribeEmails}
                      onChange={customerSubscribeEmails}
                    />
                  </>
                )}
              </BlockStack>
            </Card>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              Thank you message
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <Checkbox
                label="Auto-send email to the customer when they subscribe for a product"
                checked={isThankYouMsg}
                onChange={setIsThankYouMsg}
              />
            </Card>
          </Grid.Cell>
        </Grid>
        <InlineStack align="end">
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </InlineStack>
      </BlockStack>
    </Page>
  );
};

export default BackInStock_setting_activity;
