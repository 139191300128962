import axios from "axios";
import { tokenEndpoint, apiEndpoint } from '../config'
import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge/utilities";
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
const host = params?.host;
const app = createApp({
  apiKey: "f066071a58a555a0e220626c961a85ad", 
  host: host, 
});

const axiosCall = (method, data = null, extra_headers = null, call_limit_counter = null) => {
    return new Promise(async (resolve, reject) => {
        let counter = 0
        /* this condition is use for limite api call - it is preventing recursive api call - here we are giving limit 2 */
        const sessionToken = await getSessionToken(app);
       
        if (5 >= call_limit_counter) {
            let full_url = `${apiEndpoint}`;
            let security_token = data['shopData']["security_token"];
            let common_headers = { 'Content-Type': 'application/json', 'authentication': security_token ,  Authorization: `Bearer ${sessionToken}`  }
            let final_headers = extra_headers ? Object.assign(common_headers, extra_headers) : { 'Content-Type': 'application/json', 'authentication': security_token,  Authorization: `Bearer ${sessionToken}` };
            const pre_shop_info = data['shopData']["pre_shop_info"];
            let common_data = {
                shop: data['shopData']["shop"],
                scid: data['shopData']["scid"],
                prepostShopInfo: pre_shop_info ? JSON.parse(data['shopData']["pre_shop_info"]) : ""
            }
            delete (data['shopData']);
            if (common_data['shop'] === getParameterByName("shop", window.location.href)) {
                let final_data = data ? Object.assign(data, common_data) : "";
                try {
                    let response = await axios({
                        method: method,
                        url: full_url,
                        data: final_data,
                        headers: final_headers,
                    })
                    if (response.data.status === 401) {
                        try {
                            counter++
                            let new_token = await generateTokenAPI()
                            let response_reguler_api = await axiosCall(method, data, extra_headers, counter)
                            resolve(response_reguler_api)
                        } catch (error) {
                            reject(`Token API Failed => ${error}`)
                        }
                    } else {
                        resolve(response)
                    }
                } catch (error) {
                    reject(`called api error:${error.message}`)
                    console.log(error);
                }
            } else {
                reject("shop name conflict")
                window.location = "https://accounts.shopify.com/store-login?redirect=apps"
            }
        } else {
            reject("API CALL LIMIT REACHED")
        }
    })
}

const service = axios.create({
    headers: {

    }
})
const handleSuccess = (response) => {
    return response
}

const handleError = (error) => {
    return Promise.reject(error)
}
service.interceptors.response.use(handleSuccess, handleError)

const generateTokenAPI = async (method, path, payload) => {
    try {
        const sessionToken = await getSessionToken(app);
        console.log("sessionToken apis",sessionToken)
        const responce = await service.request({
            method,
            url: tokenEndpoint + path,
            responseType: 'json',
            data: payload,
            headers:sessionToken
        })
        return responce
    } catch (error) {
        if (error.message === 'Network Error') {
            console.log(`${error}, Server is not responding, please try again after some time`)
        }
    }
}

export const ShopifyCall = async (method, path, payload, header) => {
    try {
        const sessionToken = await getSessionToken(app);
        header.Authorization = sessionToken;
        console.log("sessionToken apis",header)

        const responce = await service.request({
            method,
            url: tokenEndpoint + path,
            responseType: 'json',
            data: payload,
            headers: header
        })
        return responce
    } catch (error) {
        if (error.message === 'Network Error') {
            console.log(`${error}, Server is not responding, please try again after some time`)
        }
    }
}

export const ApiCall = async (method, path, payload, header = null) => {
    try {
        const sessionToken = await getSessionToken(app);
        header.Authorization = sessionToken;
        console.log("sessionToken apis",header)

        const responce = await service.request({
            method,
            url: tokenEndpoint + path,
            responseType: 'json',
            data: payload,
            headers: header
        })
        return responce
    } catch (error) {
        if (error.message === 'Network Error') {
            console.log(`${error}, Server is not responding, please try again after some time`)
        }
    }
}

export const GetApiCall = async (method, path, header = [], flag = false) => {
    try {
        const sessionToken = await getSessionToken(app);
        header.Authorization = sessionToken;
        console.log("sessionToken apis",header)

        const responce = await service.request({
            method,
            url: tokenEndpoint + path,
            responseType: 'json',
            headers: header
        })
        return responce
    } catch (error) {
        if (error.message === 'Network Error') {
            console.log(`${error}, Server is not responding, please try again after some time`)
        }
    }
}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase();
    name = name.replace(/[\[\]]/g, "\\$&").toLowerCase();
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
export { generateTokenAPI, axiosCall }