import { useEffect, useState } from "react";
import {
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  Collapsible,
  Text,
  TextField,
} from "@shopify/polaris";
import { useRecoilState } from "recoil";
import { appearanceFooterState } from "../../../../store/appearanceSettingAtom";

const Footer = ({ isOpen, toggle }) => {
  let [data, setData] = useRecoilState(appearanceFooterState);

  let [showFooter, setShowFooter] = useState(true);
  let [footerText, setFooterText] = useState(
    "We will send you an email once the product becomes available. Your email address will not be shared with anyone else"
  );

  useEffect(() => {
    setData({
      showFooter,
      footerText,
    });
  }, [showFooter, footerText]);

  return (
    <Card padding={"0"} roundedAbove="xs">
      <Button
        variant="tertiary"
        fullWidth
        size="large"
        textAlign="left"
        disclosure={isOpen ? "up" : "down"}
        onClick={toggle}
      >
        <Box paddingBlock={"150"} paddingInline={"100"}>
          <h1 className="h-16">Footer</h1>
        </Box>
      </Button>
      <Collapsible
        open={isOpen}
        transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
      >
        <Box paddingInline={"400"} paddingBlockEnd={"400"}>
          <BlockStack gap={"400"}>
            <Text as="p" variant="bodyMd">
              Change the footer content here
            </Text>
            <BlockStack gap={"200"}>
              <TextField
                multiline={3}
                value={footerText}
                onChange={setFooterText}
              />
              <Checkbox
                label="Show footer"
                checked={showFooter}
                onChange={setShowFooter}
              />
            </BlockStack>
          </BlockStack>
        </Box>
      </Collapsible>
    </Card>
  );
};

export default Footer;
