import React, { useState } from "react";

import { Banner, Button } from "@shopify/polaris";
import app_block_enable from "../../assets/images/app_block_enable.png";

import { LazyLoadImage } from "react-lazy-load-image-component";

const ThemeSetting = ({ handleNext, activeButton, loadingbtn }) => {
  return (
    <div className="p-4 w-100 d-grid gap-4">
      <Banner tone="info" title="App Embeds in Theme Setting">
        <p>
          Enable Pre-order app embed in theme setting to install the app to your
          theme
        </p>
      </Banner>

      <div
        className="d-flex justify-content-center w-100 overflow-hidden"
        style={{
          height: "250px",
          border: "1px solid #C4C4C4",
          borderRadius: "8px",
        }}
      >
        <LazyLoadImage
          style={{ width: "100%", maxWidth: "500px", height: "fit-content" }}
          src={`${app_block_enable}`}
          alt="Enable app embed"
        />
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p style={{ color: "#6D7175" }}>
          Go to theme setting{" > "}App Embeds{" > "}Click On Save Button{" > "}
          Manually Comeback to this Tab{" "}
        </p>
        <Button primary loading={loadingbtn} onClick={activeButton}>
          Activate Now
        </Button>
      </div>
    </div>
  );
};

export default ThemeSetting;
