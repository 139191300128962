import { useState } from "react";
import {
  Card,
  Button,
  Grid,
  InlineGrid,
  InlineStack,
  Page,
  Text,
  Thumbnail,
  BlockStack,
  Badge,
} from "@shopify/polaris";
import { ImageIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";

const SettingPage = () => {
  let navigate = useNavigate();
  let [isEnable, setIsEnable] = useState(false);

  let toggleStatus = () => {
    setIsEnable(!isEnable);
  };

  let data = [
    {
      title: "Appearance",
      description: "Search for products to make available for.",
      imgUrl: ImageIcon,
      url: "/back-in-stock/setting/appearance",
    },
    {
      title: "Conditions",
      description: "Search for products to make available for.",
      imgUrl: ImageIcon,
      url: "/back-in-stock/setting/conditions",
    },
    {
      title: "Delivery policy",
      description: "Search for products to make available for.",
      imgUrl: ImageIcon,
      url: "/back-in-stock/setting/delivery-policy",
    },
    {
      title: "Campaigns",
      description: "Search for products to make available for.",
      imgUrl: ImageIcon,
      url: "/back-in-stock/setting/campaigns",
    },
    {
      title: "Activity updates",
      description: "Search for products to make available for.",
      imgUrl: ImageIcon,
      url: "/back-in-stock/setting/activity",
    },
    {
      title: "Locations",
      description: "Search for products to make available for.",
      imgUrl: ImageIcon,
      url: "/back-in-stock/setting/locations",
    },
    {
      title: "Additional",
      description: "Search for products to make available for.",
      imgUrl: ImageIcon,
      url: "/back-in-stock/setting/additional",
    },
  ];

  return (
    <Page>
      <BlockStack gap={"400"}>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <BlockStack gap={"100"}>
              <Text as="h1" variant="headingSm">
                Status
              </Text>
              <Text as="h1" variant="bodySm">
                Enable or disable back in stock in your app
              </Text>
            </BlockStack>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card padding={"400"} roundedAbove="xs">
              <InlineStack
                align="space-between"
                blockAlign="center"
                gap={"200"}
              >
                <Text as="h1" variant="headingSm">
                  Back in stock is now{" "}
                  {isEnable ? <Badge>enabled</Badge> : <Badge>disabled</Badge>}
                </Text>
                <Button
                  variant={isEnable ? "secondary" : "primary"}
                  onClick={toggleStatus}
                >
                  {isEnable ? "Disable" : "Enable"}
                </Button>
              </InlineStack>
            </Card>
          </Grid.Cell>
        </Grid>
        <Card roundedAbove="xs" padding={{ xs: "0", sm: "400" }}>
          <InlineGrid
            gap={"300"}
            columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
          >
            {data.map((item, index) => (
              <div
                className="back-in-stock-setting-div"
                onClick={() => navigate(item.url)}
                key={index}
              >
                <InlineStack gap={"400"} wrap={false} blockAlign="center">
                  <Thumbnail source={item.imgUrl} size="small" />
                  <BlockStack gap={"100"}>
                    <Text as="h1" variant="headingSm">
                      {item.title}
                    </Text>
                    <Text as="p" variant="bodySm">
                      {item.description}
                    </Text>
                  </BlockStack>
                </InlineStack>
              </div>
            ))}
          </InlineGrid>
        </Card>
      </BlockStack>
    </Page>
  );
};

export default SettingPage;
