import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Banner,
  Box,
  ChoiceList,
  DropZone,
  BlockStack,
  Link,
  Page,
  Text,
  Thumbnail,
} from "@shopify/polaris";
import { ExportIcon, ImportIcon, NoteIcon } from "@shopify/polaris-icons";
import MainTable from "./MainTable";
import { Modal, TitleBar, useAppBridge } from "@shopify/app-bridge-react";

const SubmissionPage = () => {
  const shopify = useAppBridge();
  let navigate = useNavigate();
  let [isBannerVisible, setIsBannerVisible] = useState(true);
  let [selectedModalExportOption, setSelectedModalExportOption] = useState("");
  const [files, setFiles] = useState([]);

  let handleAddSubscriptionBtnClick = () => {
    navigate("/back-in-stock/add-subscriber");
  };
  let showModal = (modalId) => {
    shopify.modal.show(modalId);
  };
  let hideModal = (modalId) => {
    shopify.modal.hide(modalId);
  };
  let handleModalExportBtnClick = () => {
    console.log(selectedModalExportOption[0]);
  };
  let handleModalImportBtnClick = () => {
    console.log("handleModalImportBtnClick");
  };
  //   drop zone code
  const handleDropZoneDrop = (_dropFiles, acceptedFiles, _rejectedFiles) => {
    setFiles(acceptedFiles);
  };
  const fileUpload = !files.length && <DropZone.FileUpload />;
  const uploadedFiles = files.length > 0 && (
    <Box padding={"4"}>
      <BlockStack>
        {files.map((file, index) => (
          <BlockStack align="center" key={index}>
            <Thumbnail size="small" alt={file.name} source={NoteIcon} />
            <div>
              {file.name}{" "}
              <Text variant="bodySm" as="p">
                {file.size} bytes
              </Text>
            </div>
          </BlockStack>
        ))}
      </BlockStack>
    </Box>
  );
  // banner
  let closeBanner = () => {
    setIsBannerVisible(false);
  };
  let handleGotoSettingClick = () => {
    console.log("handleGotoSettingClick");
  };
  let handleContactUsClick = () => {
    console.log("handleContactUsClick");
  };

  return (
    <Page
      title="Submission"
      primaryAction={{
        content: "Add subscription",
        onAction: handleAddSubscriptionBtnClick,
      }}
      secondaryActions={[
        {
          content: "Export",
          icon: ExportIcon,
          onAction: () => showModal("export-submission-modal"),
        },
        {
          content: "Import",
          icon: ImportIcon,
          onAction: () => showModal("import-submissions-modal"),
        },
      ]}
    >
      <BlockStack gap={"300"}>
        {isBannerVisible && (
          <Banner
            tone="warning"
            title="Discontinuation of SMS product restock notifications"
            onDismiss={closeBanner}
            action={{
              content: "Go to Setting > Notification Channels",
              onAction: handleGotoSettingClick,
            }}
            secondaryAction={{
              content: "Contact us",
              onAction: handleContactUsClick,
            }}
          >
            <Text as="p" variant="bodyMd">
              We regret to inform you that the SMS notification feature for
              product restocks in our application has been discontinued due to
              restrictions on sending permissions in certain countries. Please
              disable the SMS channel in the channel settings.
            </Text>
            <Text as="p" variant="bodyMd">
              We apologize for any inconvenience this may cause. If you have any
              questions or concerns, please feel free to reach out to our
              support team. Thank you for your understanding and patience.
            </Text>
          </Banner>
        )}
        <MainTable />
      </BlockStack>
      {/* export modal */}
      <Modal id="export-submission-modal">
        <TitleBar title="Export submission">
          <button variant="primary" onClick={handleModalExportBtnClick}>
            Export
          </button>
          <button onClick={() => hideModal("export-submission-modal")}>
            Close
          </button>
        </TitleBar>
        <Box padding={"400"}>
          <ChoiceList
            title={
              <Text as="h1" variant="headingMd">
                Export
              </Text>
            }
            choices={[
              {
                label: "All submissions",
                value: "all",
              },
              {
                label: "Submissions matching your search",
                value: "partial",
              },
            ]}
            selected={selectedModalExportOption}
            onChange={setSelectedModalExportOption}
          />
        </Box>
      </Modal>
      {/* import modal */}
      <Modal id="import-submissions-modal">
        <TitleBar title="Import submissions by CSV">
          <button variant="primary" onClick={handleModalImportBtnClick}>
            Import
          </button>
          <button onClick={() => hideModal("import-submissions-modal")}>
            Close
          </button>
        </TitleBar>
        <Box padding={"400"}>
          <BlockStack gap={"1"}>
            <DropZone
              label={
                <Text as="p" variant="bodyMd">
                  Download <Link>a sample CSV template</Link> to see an example
                  of the format required
                </Text>
              }
              accept=".csv"
              onDrop={handleDropZoneDrop}
            >
              {uploadedFiles}
              {fileUpload}
            </DropZone>
          </BlockStack>
        </Box>
      </Modal>
    </Page>
  );
};

export default SubmissionPage;
