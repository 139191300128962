import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Checkbox,
  InlineGrid,
  InlineStack,
  Icon,
  Page,
  ResourceItem,
  ResourceList,
  Text,
  TextField,
  BlockStack,
  Thumbnail,
} from "@shopify/polaris";
import { SearchIcon, DeleteIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
// import { ResourcePicker } from "@shopify/app-bridge-react";

const AddSubscriber = () => {
  let navigate = useNavigate();

  let [email, setEmail] = useState("");
  let [phone, setPhone] = useState("");
  let [notification, setNotification] = useState(false);
  let [products, setProducts] = useState([]);
  // const [isResourcePickerOpen, setIsResourcePickerOpen] = useState(false);
  // const [selectedResources, setSelectedResources] = useState([]);

  let dummyProducts = [
    {
      id: "100",
      name: "Mae Jemison",
      imgUrl: "https://picsum.photos/id/100/200",
    },
    {
      id: "200",
      name: "Ellen Ochoa",
      imgUrl: "https://picsum.photos/id/200/200",
    },
  ];

  useEffect(() => {
    setProducts(dummyProducts);
  }, []);

  let handleSubmit = () => {
    console.log("handleSubmit");
  };

  let removeProduct = (id) => {
    setProducts(products.filter((product) => product.id != id));
  };

  // const handleSelection = (resources) => {
  //   setSelectedResources(resources.selection);
  //   setIsResourcePickerOpen(false); // Close picker after selection
  //   console.log("Selected resources:", resources.selection);
  // };

  let handleInputFocus = () => {
    // setIsResourcePickerOpen(true);
  };

  return (
    <Page
      title="Add subscriber"
      backAction={{ onAction: () => navigate("/back-in-stock") }}
    >
      {/* <ResourcePicker resourceType="Product" open /> */}
      <BlockStack gap={"300"}>
        <InlineGrid
          gap={"300"}
          alignItems="start"
          columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
        >
          <Card roundedAbove="xs">
            <BlockStack gap={"400"}>
              <Text as="h1" variant="headingSm">
                Choose product
              </Text>
              <BlockStack gap={"300"}>
                <TextField
                  prefix={<Icon source={SearchIcon} />}
                  placeholder="Search"
                  onFocus={handleInputFocus}
                />
                {products.map((item, index) => (
                  <Card padding={"300"} roundedAbove="xs" key={index}>
                    <InlineStack gap={"300"} blockAlign="center">
                      <Thumbnail source={item.imgUrl} size="small" />
                      <Text as="p" variant="bodyLg">
                        {item.name}
                      </Text>
                      <div style={{ marginLeft: "auto" }}>
                        <Button
                          variant="tertiary"
                          icon={DeleteIcon}
                          onClick={() => removeProduct(item.id)}
                        />
                      </div>
                    </InlineStack>
                  </Card>
                ))}
              </BlockStack>
              <Checkbox
                label="Send product registration notifications to customers."
                checked={notification}
                onChange={(value, id) => setNotification(value)}
              />
            </BlockStack>
          </Card>
          <Card roundedAbove="xs">
            <BlockStack gap={"400"}>
              <Text as="h1" variant="headingSm">
                Subscriber information
              </Text>
              <BlockStack gap={"200"}>
                <TextField
                  label="Email address"
                  type="email"
                  value={email}
                  onChange={setEmail}
                  placeholder="Enter customer email address"
                />
                <TextField
                  label="Phone Number"
                  type="email"
                  value={phone}
                  onChange={setPhone}
                  placeholder="(201) 555-0123s"
                />
              </BlockStack>
            </BlockStack>
          </Card>
        </InlineGrid>
        <InlineStack align="end">
          <Button variant="primary" onClick={handleSubmit}>
            Add
          </Button>
        </InlineStack>
      </BlockStack>
    </Page>
  );
};

export default AddSubscriber;
