import React, { useState, useEffect } from "react";
import { AppProvider, LegacyCard, Page, Banner } from "@shopify/polaris";
import CustomHeader from "../components/CustomHeader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ApplicationStatus from "../components/ApplicationStatus";
import Footer from "./Footer";
import {
  Provider as AppBridgeProvider,
  TitleBar,
} from "@shopify/app-bridge-react";
import { config } from "../config";

const Setting = () => {
  const navigate = useNavigate();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const header = { authentication: shopinfo.auth_token };
  const shop_data = shopinfo.shopInfo;
  let block_status = shopinfo.block_status;
  let store_client_id = shop_data.store_client_id;
  const [blockStatus, setBlockStatus] = useState(false);

  useEffect(() => {
    if (block_status === false && Number(shop_data.app_status) === 0) {
      setBlockStatus(true);
    } else if (block_status === true && Number(shop_data.app_status) === 1) {
      setBlockStatus(true);
    }
  }, [store_client_id, shop_data]);

  return (
    <>
      {window.location.hostname === "localhost" ||
      window.location.href.indexOf("ngrok") > 1 ? (
        <CustomHeader
          Header="Setting"
          ButtonName="Support/FAQ"
          Pricingbtn="Upgrade plan"
        />
      ) : (
        ""
      )}
      {window.location.hostname !== "localhost" ? (
        <>
          <AppProvider>
            {/* <AppBridgeProvider config={config}> */}
            <Page>
              <TitleBar title="Settings" />
              {/* {blockStatus ? (
                  <Banner
                    title="ACTION REQUIRED : Activate Pre Order in the Shopify theme Editor."
                    status="warning"
                  >
                    <p>
                      We noticed you have disabled Pre order feature but app
                      embeds of Pre order is enabled. So please correct it by
                      clicking here.
                    </p>
                  </Banner>
                ) : (
                  ""
                )} */}
              <ApplicationStatus header={header} shop_data={shopinfo} />
              <LegacyCard>
                <LegacyCard.Section>
                  <div className="settings-page">
                    <div className="setting-page-item">
                      <a
                        href="javascript:void(0)"
                        data-polaris-unstyled="true"
                        className="Polaris-Link Polaris-Link--removeUnderline d-flex"
                        onClick={() => navigate("/defaultSetting")}
                      >
                        <div className="icon">
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9.027 0a1 1 0 00-.99.859l-.37 2.598A6.993 6.993 0 005.742 4.57L3.305 3.59a1 1 0 00-1.239.428L.934 5.981a1 1 0 00.248 1.287l2.066 1.621a7.06 7.06 0 000 2.222l-2.066 1.621a1 1 0 00-.248 1.287l1.132 1.962a1 1 0 001.239.428l2.438-.979a6.995 6.995 0 001.923 1.113l.372 2.598a1 1 0 00.99.859h2.265a1 1 0 00.99-.859l.371-2.598a6.995 6.995 0 001.924-1.112l2.438.978a1 1 0 001.238-.428l1.133-1.962a1 1 0 00-.249-1.287l-2.065-1.621a7.063 7.063 0 000-2.222l2.065-1.621a1 1 0 00.249-1.287l-1.133-1.962a1 1 0 00-1.239-.428l-2.437.979a6.994 6.994 0 00-1.924-1.113L12.283.86a1 1 0 00-.99-.859H9.027zm1.133 13a3 3 0 100-6 3 3 0 000 6z"
                            />
                          </svg>
                        </div>
                        <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingExtraTight">
                          <div className="Polaris-Stack__Item setting_text">
                            <span className="Polaris-TextStyle--variationStrong">
                              General Setting
                            </span>
                          </div>
                          <span className="Polaris-TextStyle--variationSubdued">
                            Update your general details i.e Application status,
                            Email settings, Billing settings and Customer portal
                          </span>
                        </div>
                      </a>
                    </div>
                    <div className="setting-page-item">
                      <a
                        onClick={() => navigate("/style-setting")}
                        data-polaris-unstyled="true"
                        className="Polaris-Link Polaris-Link--removeUnderline d-flex"
                        href="javascript:void(0)"
                      >
                        <div className="icon">
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M0 1.5A1.5 1.5 0 011.5 0h5A1.5 1.5 0 018 1.5V2h5a1 1 0 110 2H8v1.5A1.5 1.5 0 016.5 7H5v2H3V7H1.5A1.5 1.5 0 010 5.5v-4zM2 5V2h4v3H2z"
                            />
                            <path d="M5 17v1h1v2H4.5A1.5 1.5 0 013 18.5V17h2zM3 14v-3h2v3H3zM12 20H8v-2h4v2zM14 18h1v-1h2v1.5a1.5 1.5 0 01-1.5 1.5H14v-2zM16.907 4.841l2.252 2.252.375-.376a1.592 1.592 0 00-2.252-2.25l-.375.374zM18.125 8.124l-2.251-2.251-7.143 7.143L8 16l2.982-.731 7.143-7.144z" />
                          </svg>
                        </div>
                        <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingExtraTight">
                          <div className="Polaris-Stack__Item setting_text">
                            <span className="Polaris-TextStyle--variationStrong">
                              Style Setting
                            </span>
                          </div>
                          <span className="Polaris-TextStyle--variationSubdued">
                            View and update personalize your design
                          </span>
                        </div>
                      </a>
                    </div>
                    <div className="setting-page-item">
                      <a
                        onClick={() => navigate("/advanced-setting")}
                        data-polaris-unstyled="true"
                        className="Polaris-Link Polaris-Link--removeUnderline d-flex"
                        href="javascript:void(0)"
                      >
                        <div className="icon">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.4139 1.58602C18.7888 1.96108 18.9995 2.46969 18.9995 3.00002C18.9995 3.53035 18.7888 4.03897 18.4139 4.41402L15.4139 7.41402L14.2989 8.52902L11.4709 5.70102L12.7029 4.46802L12.7179 4.45302L15.5859 1.58602C15.9609 1.21108 16.4696 1.00045 16.9999 1.00045C17.5302 1.00045 18.0388 1.21108 18.4139 1.58602ZM9.94389 12.885L7.15589 10.098C6.33307 10.3746 5.44946 10.4163 4.60424 10.2186C3.75901 10.0209 2.98563 9.59145 2.37089 8.97859C1.75616 8.36572 1.3244 7.59365 1.12409 6.74903C0.923777 5.90441 0.962837 5.02068 1.23689 4.19702L3.75989 6.71902C4.04118 7.00023 4.42264 7.1582 4.82039 7.1582C5.21813 7.1582 5.59959 7.00023 5.88089 6.71902L6.72089 5.87902C7.00179 5.59777 7.15957 5.21652 7.15957 4.81902C7.15957 4.42152 7.00179 4.04027 6.72089 3.75902L4.19689 1.23602C5.02048 0.962226 5.90408 0.923359 6.74853 1.12378C7.59299 1.32421 8.36488 1.75599 8.9776 2.37068C9.59032 2.98538 10.0196 3.75865 10.2173 4.60375C10.415 5.44884 10.3733 6.33231 10.0969 7.15502L12.8839 9.94202L18.3899 15.448C18.78 15.8382 18.9992 16.3673 18.9992 16.919C18.9992 17.4708 18.78 17.9999 18.3899 18.39C17.9998 18.7802 17.4706 18.9993 16.9189 18.9993C16.3672 18.9993 15.838 18.7802 15.4479 18.39L9.94189 12.884L9.94389 12.885ZM8.52889 14.299L5.24189 17.586L0.999886 19L2.41389 14.757L5.70089 11.471L8.52889 14.299Z"
                              fill="#919eab"
                            />
                          </svg>
                        </div>
                        <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingExtraTight">
                          <div className="Polaris-Stack__Item setting_text">
                            <span className="Polaris-TextStyle--variationStrong">
                              Advance Setting
                            </span>
                          </div>
                          <span className="Polaris-TextStyle--variationSubdued">
                            View and update personalize your design
                          </span>
                        </div>
                      </a>
                    </div>
                    <div className="setting-page-item">
                      <a
                        onClick={() => navigate("/install-uninstall")}
                        data-polaris-unstyled="true"
                        className="Polaris-Link Polaris-Link--removeUnderline d-flex"
                        href="javascript:void(0)"
                      >
                        <div className="icon">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M3 4.5A1.5 1.5 0 014.5 3h11A1.5 1.5 0 0117 4.5v11a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 013 15.5v-11zM5 13v2h10v-2H5zm2.207-7.207a1 1 0 00-1.414 1.414l3.5 3.5a1 1 0 001.414 0l3.5-3.5a1 1 0 00-1.414-1.414L10 8.586 7.207 5.793z" />
                          </svg>
                        </div>
                        <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingExtraTight">
                          <div className="Polaris-Stack__Item setting_text">
                            <span className="Polaris-TextStyle--variationStrong">
                              Installation Page
                            </span>
                          </div>
                          <span className="Polaris-TextStyle--variationSubdued">
                            View and select theme to enable theme extenssion
                          </span>
                        </div>
                      </a>
                    </div>
                    {/* <div className="setting-page-item">
                        <a
                          onClick={() => navigate("/email-setting")}
                          data-polaris-unstyled="true"
                          className="Polaris-Link Polaris-Link--removeUnderline d-flex"
                          href="javascript:void(0)"
                        >
                          <div className="icon">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              focusable="false"
                              aria-hidden="true"
                            >
                              <path d="M0 5.324v10.176a1.5 1.5 0 0 0 1.5 1.5h17a1.5 1.5 0 0 0 1.5-1.5v-10.176l-9.496 5.54a1 1 0 0 1-1.008 0l-9.496-5.54z"></path>
                              <path d="M19.443 3.334a1.494 1.494 0 0 0-.943-.334h-17a1.49 1.49 0 0 0-.943.334l9.443 5.508 9.443-5.508z"></path>
                            </svg>
                          </div>
                          <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingExtraTight">
                            <div className="Polaris-Stack__Item setting_text">
                              <span className="Polaris-TextStyle--variationStrong">
                                Email Setting
                              </span>
                            </div>
                            <span className="Polaris-TextStyle--variationSubdued">
                              send order email
                            </span>
                          </div>
                        </a>
                      </div> */}
                  </div>
                </LegacyCard.Section>
              </LegacyCard>
              <Footer />
            </Page>
            {/* </AppBridgeProvider> */}
          </AppProvider>
        </>
      ) : (
        <>
          <Page>
            {/* {blockStatus ? (
              <Banner
                title="ACTION REQUIRED : Activate Pre Order in the Shopify theme Editor."
                status="warning"
              >
                <p>
                  We noticed you have disabled Pre order feature but app embeds
                  of Pre order is enabled. So please correct it by clicking
                  here.
                </p>
              </Banner>
            ) : (
              ""
            )} */}
            <div className="pre-setting-layout">
              <ApplicationStatus header={header} shop_data={shopinfo} />
            </div>
            <div className="pt-3"></div>
            <LegacyCard>
              <LegacyCard.Section>
                <div className="settings-page">
                  <div className="setting-page-item">
                    <a
                      href="javascript:void(0)"
                      data-polaris-unstyled="true"
                      className="Polaris-Link Polaris-Link--removeUnderline d-flex"
                      onClick={() => navigate("/defaultSetting")}
                    >
                      <div className="icon">
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9.027 0a1 1 0 00-.99.859l-.37 2.598A6.993 6.993 0 005.742 4.57L3.305 3.59a1 1 0 00-1.239.428L.934 5.981a1 1 0 00.248 1.287l2.066 1.621a7.06 7.06 0 000 2.222l-2.066 1.621a1 1 0 00-.248 1.287l1.132 1.962a1 1 0 001.239.428l2.438-.979a6.995 6.995 0 001.923 1.113l.372 2.598a1 1 0 00.99.859h2.265a1 1 0 00.99-.859l.371-2.598a6.995 6.995 0 001.924-1.112l2.438.978a1 1 0 001.238-.428l1.133-1.962a1 1 0 00-.249-1.287l-2.065-1.621a7.063 7.063 0 000-2.222l2.065-1.621a1 1 0 00.249-1.287l-1.133-1.962a1 1 0 00-1.239-.428l-2.437.979a6.994 6.994 0 00-1.924-1.113L12.283.86a1 1 0 00-.99-.859H9.027zm1.133 13a3 3 0 100-6 3 3 0 000 6z"
                          />
                        </svg>
                      </div>
                      <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingExtraTight">
                        <div className="Polaris-Stack__Item setting_text">
                          <span className="Polaris-TextStyle--variationStrong">
                            General Setting
                          </span>
                        </div>
                        <span className="Polaris-TextStyle--variationSubdued">
                          Update your general settings i.e Button settings,
                          product badge, stock settings and collection page
                          settings.
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="setting-page-item">
                    <a
                      onClick={() => navigate("/style-setting")}
                      data-polaris-unstyled="true"
                      className="Polaris-Link Polaris-Link--removeUnderline d-flex"
                      href="javascript:void(0)"
                    >
                      <div className="icon">
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M0 1.5A1.5 1.5 0 011.5 0h5A1.5 1.5 0 018 1.5V2h5a1 1 0 110 2H8v1.5A1.5 1.5 0 016.5 7H5v2H3V7H1.5A1.5 1.5 0 010 5.5v-4zM2 5V2h4v3H2z"
                          />
                          <path d="M5 17v1h1v2H4.5A1.5 1.5 0 013 18.5V17h2zM3 14v-3h2v3H3zM12 20H8v-2h4v2zM14 18h1v-1h2v1.5a1.5 1.5 0 01-1.5 1.5H14v-2zM16.907 4.841l2.252 2.252.375-.376a1.592 1.592 0 00-2.252-2.25l-.375.374zM18.125 8.124l-2.251-2.251-7.143 7.143L8 16l2.982-.731 7.143-7.144z" />
                        </svg>
                      </div>
                      <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingExtraTight">
                        <div className="Polaris-Stack__Item setting_text">
                          <span className="Polaris-TextStyle--variationStrong">
                            Style Setting
                          </span>
                        </div>
                        <span className="Polaris-TextStyle--variationSubdued">
                          View and update personalize your design
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="setting-page-item">
                    <a
                      onClick={() => navigate("/advanced-setting")}
                      data-polaris-unstyled="true"
                      className="Polaris-Link Polaris-Link--removeUnderline d-flex"
                      href="javascript:void(0)"
                    >
                      <div className="icon">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.4139 1.58602C18.7888 1.96108 18.9995 2.46969 18.9995 3.00002C18.9995 3.53035 18.7888 4.03897 18.4139 4.41402L15.4139 7.41402L14.2989 8.52902L11.4709 5.70102L12.7029 4.46802L12.7179 4.45302L15.5859 1.58602C15.9609 1.21108 16.4696 1.00045 16.9999 1.00045C17.5302 1.00045 18.0388 1.21108 18.4139 1.58602ZM9.94389 12.885L7.15589 10.098C6.33307 10.3746 5.44946 10.4163 4.60424 10.2186C3.75901 10.0209 2.98563 9.59145 2.37089 8.97859C1.75616 8.36572 1.3244 7.59365 1.12409 6.74903C0.923777 5.90441 0.962837 5.02068 1.23689 4.19702L3.75989 6.71902C4.04118 7.00023 4.42264 7.1582 4.82039 7.1582C5.21813 7.1582 5.59959 7.00023 5.88089 6.71902L6.72089 5.87902C7.00179 5.59777 7.15957 5.21652 7.15957 4.81902C7.15957 4.42152 7.00179 4.04027 6.72089 3.75902L4.19689 1.23602C5.02048 0.962226 5.90408 0.923359 6.74853 1.12378C7.59299 1.32421 8.36488 1.75599 8.9776 2.37068C9.59032 2.98538 10.0196 3.75865 10.2173 4.60375C10.415 5.44884 10.3733 6.33231 10.0969 7.15502L12.8839 9.94202L18.3899 15.448C18.78 15.8382 18.9992 16.3673 18.9992 16.919C18.9992 17.4708 18.78 17.9999 18.3899 18.39C17.9998 18.7802 17.4706 18.9993 16.9189 18.9993C16.3672 18.9993 15.838 18.7802 15.4479 18.39L9.94189 12.884L9.94389 12.885ZM8.52889 14.299L5.24189 17.586L0.999886 19L2.41389 14.757L5.70089 11.471L8.52889 14.299Z"
                            fill="#919eab"
                          />
                        </svg>
                      </div>
                      <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingExtraTight">
                        <div className="Polaris-Stack__Item setting_text">
                          <span className="Polaris-TextStyle--variationStrong">
                            Advance Setting
                          </span>
                        </div>
                        <span className="Polaris-TextStyle--variationSubdued">
                          Update your advanced settings i.e Stock alert, mix
                          cart alert, order tags and pre order period.
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className="setting-page-item">
                    <a
                      onClick={() => navigate("/install-uninstall")}
                      data-polaris-unstyled="true"
                      className="Polaris-Link Polaris-Link--removeUnderline d-flex"
                      href="javascript:void(0)"
                    >
                      <div className="icon">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M3 4.5A1.5 1.5 0 014.5 3h11A1.5 1.5 0 0117 4.5v11a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 013 15.5v-11zM5 13v2h10v-2H5zm2.207-7.207a1 1 0 00-1.414 1.414l3.5 3.5a1 1 0 001.414 0l3.5-3.5a1 1 0 00-1.414-1.414L10 8.586 7.207 5.793z" />
                        </svg>
                      </div>
                      <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingExtraTight">
                        <div className="Polaris-Stack__Item setting_text">
                          <span className="Polaris-TextStyle--variationStrong">
                            Installation Page
                          </span>
                        </div>
                        <span className="Polaris-TextStyle--variationSubdued">
                          View and select theme to enable theme extenssion
                        </span>
                      </div>
                    </a>
                  </div>
                  {/* <div className="setting-page-item">
                    <a
                      onClick={() => navigate("/email-setting")}
                      data-polaris-unstyled="true"
                      className="Polaris-Link Polaris-Link--removeUnderline d-flex"
                      href="javascript:void(0)"
                    >
                      <div className="icon">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          focusable="false"
                          aria-hidden="true"
                        >
                          <path d="M0 5.324v10.176a1.5 1.5 0 0 0 1.5 1.5h17a1.5 1.5 0 0 0 1.5-1.5v-10.176l-9.496 5.54a1 1 0 0 1-1.008 0l-9.496-5.54z"></path>
                          <path d="M19.443 3.334a1.494 1.494 0 0 0-.943-.334h-17a1.49 1.49 0 0 0-.943.334l9.443 5.508 9.443-5.508z"></path>
                        </svg>
                      </div>
                      <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingExtraTight">
                        <div className="Polaris-Stack__Item setting_text">
                          <span className="Polaris-TextStyle--variationStrong">
                            Email Setting
                          </span>
                        </div>
                        <span className="Polaris-TextStyle--variationSubdued">
                          send order email
                        </span>
                      </div>
                    </a>
                  </div> */}
                </div>
              </LegacyCard.Section>
            </LegacyCard>
            <Footer />
          </Page>
        </>
      )}
    </>
  );
};

export default Setting;
