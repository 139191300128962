import { React, useState, useCallback, useEffect } from "react";
import CustomHeader from "../components/CustomHeader";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { useDispatch, useSelector } from "react-redux";
import {
  Page,
  LegacyCard,
  Banner,
  Checkbox,
  Button,
  Toast,
  Layout,
  FormLayout,
  AppProvider,
} from "@shopify/polaris";
import { setShopData } from "../redux/commonSlice";
import ApplicationStatus from "../components/ApplicationStatus";
import Footer from "./Footer";
import {
  Provider as AppBridgeProvider,
  TitleBar,
} from "@shopify/app-bridge-react";
import { config } from "../config";
import { useNavigate } from "react-router-dom";

const AllProduct = () => {
  const dispatch = useDispatch();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const header = { authentication: shopinfo.auth_token };
  const shop_data = shopinfo.shopInfo;
  const is_free = shop_data?.is_freeplan;
  const navigate = useNavigate();

  const [bannerStatus, setBannerStatus] = useState("1");
  const handleChange = useCallback((newChecked) => setChecked(newChecked), []);
  const [activeToast, setActiveToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  let is_all_product = shop_data.is_all_product == "0" ? false : true;
  let store_client_id = shop_data.store_client_id;
  let app_status = shop_data.app_status;
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log(is_free);
  useEffect(() => {
    setChecked(shop_data.is_all_product == "0" ? false : true);
    getStoreData();
    addSvg();
  }, [shop_data]);

  const toggleActiveToast = useCallback(
    () => setActiveToast((active) => !active),
    []
  );

  const addSvg = async () => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Target element is now visible in the viewport
          const targetSpan = document.querySelector(
            ".Polaris-Icon.Polaris-Icon--colorHighlight.Polaris-Icon--applyColor"
          );
          if (targetSpan) {
            // Define the new SVG code
            const newSVG = `
                      <span class="Polaris-Text--root Polaris-Text--visuallyHidden"></span>
                      <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                          <path d="M10 14a.75.75 0 0 1-.75-.75v-3.5a.75.75 0 0 1 1.5 0v3.5a.75.75 0 0 1-.75.75Z"></path>
                          <path d="M9 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"></path>
                          <path fill-rule="evenodd" d="M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-1.5 0a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Z"></path>
                      </svg>
                  `;

            // Replace the inner HTML of the target span element with the new SVG code
            targetSpan.innerHTML = newSVG;

            // Stop observing once the target span element is replaced
            observer.unobserve(entry.target);
          }
        }
      });
    });

    // Start observing the target span element
    const targetSpan = document.querySelector(
      ".Polaris-Icon.Polaris-Icon--colorHighlight.Polaris-Icon--applyColor"
    );
    if (targetSpan) {
      observer.observe(targetSpan);
    } else {
      console.error("Target span element not found.");
    }
  };

  const toastMarkup = activeToast ? (
    <Toast
      content={toastMessage}
      onDismiss={toggleActiveToast}
      duration={5000}
    />
  ) : null;

  const handleSave = async () => {
    setLoading(true);
    let data = {
      shop_info: shop_data,
      is_all_product: checked ? "1" : "0",
    };
    let response = await ApiCall(
      "PUT",
      "/productVariant_bulk_update",
      data,
      header
    );
    if (response.data.statusCode === 200) {
      let shop_Data = Object.assign({}, shop_data);
      shop_Data.is_all_product = checked ? "1" : "0";
      let shopInf = {
        shopInfo: shop_Data,
        auth_token: shop_data.auth_token,
      };
      dispatch(setShopData(shopInf));
      setToastMessage("Update successfully.");
      toggleActiveToast();
      setLoading(false);
    }
  };
  const getStoreData = async () => {
    if (Object.keys(shop_data).length > 0) {
      let res = await GetApiCall(
        "GET",
        `/store_data?store_client_id=${store_client_id}`,
        header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        console.log(res);
        setBannerStatus(res?.data?.data[0].all_pro_bnr_status);
      }
    }
  };
  const closeBanner = async () => {
    let response = await ApiCall("PUT", "/product_banner_status", {}, header);
    if (response.data.statusCode === 200) {
      setBannerStatus(response.data?.data?.all_pro_bnr_status);
    }
  };

  return (
    <>
      {window.location.hostname === "localhost" ||
      window.location.href.indexOf("ngrok") > 1 ? (
        <CustomHeader
          Header="All Products"
          ButtonName="Support/FAQ"
          Pricingbtn="Upgrade plan"
        />
      ) : (
        ""
      )}
      {window.location.hostname !== "localhost" ? (
        <>
          <AppProvider>
            {/* <AppBridgeProvider config={config}> */}
            <Page>
              <TitleBar title="All settings for a product" />
              <Layout>
                {bannerStatus === "0" && (
                  <>
                    <Layout.Section>
                      {/* <Banner
                        title="Enable / disable pre-order for all products using default settings takes awhile to take effect."
                        status="info"
                        onDismiss={() => { closeBanner() }}
                      >
                        <p className="custom-banner-description">
                          Please refrain to immediately changing status while the
                          system is syncing the product. This is to prevent to
                          have unsync products setting.
                        </p>
                        <p>
                          Once you enabled or disabled, the save button will be
                          disabled within 10 mins. while settings are syncing.
                        </p>
                      </Banner> */}
                      <div class="banner">
                        <div class="banner-head">
                          <div class="banner-heading">
                            <span class="banner-Icon">
                              <svg class="banner-Icon__Svg">
                                <path
                                  d="M10 14a.75.75 0 0 1-.75-.75v-3.5a.75.75 0 0 1 1.5 0v3.5a.75.75 0 0 1-.75.75Z"
                                  fill="rgb(0 33 51)"
                                ></path>
                                <path
                                  d="M9 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                                  fill="rgb(0 33 51)"
                                ></path>
                                <path
                                  fill-rule="evenodd"
                                  d="M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-1.5 0a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Z"
                                  fill="rgb(0 33 51)"
                                ></path>
                              </svg>
                            </span>
                            <h2>
                              Enable / disable pre-order for all products using
                              default settings takes awhile to take effect.
                            </h2>
                          </div>
                          <button
                            class="banner-close-btn"
                            onClick={() => {
                              closeBanner();
                            }}
                          >
                            <span class="banner-Icon">
                              <svg
                                viewBox="0 0 20 20"
                                class="banner-Icon__Svg"
                                fill="rgb(0 33 51)"
                              >
                                <path
                                  fill="rgb(0 33 51)"
                                  d="M12.72 13.78a.75.75 0 1 0 1.06-1.06l-2.72-2.72 2.72-2.72a.75.75 0 0 0-1.06-1.06l-2.72 2.72-2.72-2.72a.75.75 0 0 0-1.06 1.06l2.72 2.72-2.72 2.72a.75.75 0 1 0 1.06 1.06l2.72-2.72 2.72 2.72Z"
                                ></path>
                              </svg>
                            </span>
                          </button>
                        </div>
                        <div class="banner-content">
                          <span>
                            <p className="custom-banner-description">
                              Please refrain to immediately changing status
                              while the system is syncing the product. This is
                              to prevent to have unsync products setting.Once
                              you enabled or disabled, the save button will be
                              disabled within 10 mins. while settings are
                              syncing.
                            </p>
                          </span>
                        </div>
                      </div>
                    </Layout.Section>
                  </>
                )}
                {/* {app_status == "0" ? (
                    <ApplicationStatus header={header} shop_data={shopinfo} />
                  ) : (
                    ""
                  )} */}
                <Layout.AnnotatedSection title="General">
                  <LegacyCard sectioned>
                    <FormLayout>
                      <FormLayout.Group condensed>
                        <Checkbox
                          label="Enable pre-order for all products using Default settings"
                          checked={checked}
                          onChange={handleChange}
                        />
                      </FormLayout.Group>
                    </FormLayout>
                  </LegacyCard>
                </Layout.AnnotatedSection>
              </Layout>
              {shop_data.plan_type >= 1 || Number(is_free) === 0 ? (
                <div className="text-end pt-3">
                  <Button
                    onClick={() => handleSave()}
                    disabled={is_all_product == checked ? true : false}
                    loading={loading}
                    primary
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <div className="text-end pt-3">
                  <Button
                    onClick={() => {
                      navigate("/pricingplan");
                    }}
                    primary
                  >
                    Save
                  </Button>
                </div>
              )}

              {toastMarkup}
              {/* <Footer /> */}
            </Page>
            {/* </AppBridgeProvider> */}
          </AppProvider>
        </>
      ) : (
        <>
          <Page title="All settings for a product">
            <Layout>
              {bannerStatus === "0" && (
                <>
                  <Layout.Section>
                    {/* <Banner
                    title="Enable / disable pre-order for all products using default settings takes awhile to take effect."
                    status="info"
                    onDismiss={() => { closeBanner() }}
                  >
                    <p className="custom-banner-description">
                      Please refrain to immediately changing status while the
                      system is syncing the product. This is to prevent to
                      have unsync products setting.
                    </p>
                    <p>
                      Once you enabled or disabled, the save button will be
                      disabled within 10 mins. while settings are syncing.
                    </p>
                  </Banner> */}
                    <div class="banner">
                      <div class="banner-head">
                        <div class="banner-heading">
                          <span class="banner-Icon">
                            <svg class="banner-Icon__Svg">
                              <path
                                d="M10 14a.75.75 0 0 1-.75-.75v-3.5a.75.75 0 0 1 1.5 0v3.5a.75.75 0 0 1-.75.75Z"
                                fill="rgb(0 33 51)"
                              ></path>
                              <path
                                d="M9 7a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                                fill="rgb(0 33 51)"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                d="M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-1.5 0a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Z"
                                fill="rgb(0 33 51)"
                              ></path>
                            </svg>
                          </span>
                          <h2>
                            Enable / disable pre-order for all products using
                            default settings takes awhile to take effect.
                          </h2>
                        </div>
                        <button
                          class="banner-close-btn"
                          onClick={() => {
                            closeBanner();
                          }}
                        >
                          <span class="banner-Icon">
                            <svg
                              viewBox="0 0 20 20"
                              class="banner-Icon__Svg"
                              fill="rgb(0 33 51)"
                            >
                              <path
                                fill="rgb(0 33 51)"
                                d="M12.72 13.78a.75.75 0 1 0 1.06-1.06l-2.72-2.72 2.72-2.72a.75.75 0 0 0-1.06-1.06l-2.72 2.72-2.72-2.72a.75.75 0 0 0-1.06 1.06l2.72 2.72-2.72 2.72a.75.75 0 1 0 1.06 1.06l2.72-2.72 2.72 2.72Z"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                      <div class="banner-content">
                        <span>
                          <p className="custom-banner-description">
                            Please refrain to immediately changing status while
                            the system is syncing the product. This is to
                            prevent to have unsync products setting.
                          </p>
                          <p>
                            Once you enabled or disabled, the save button will
                            be disabled within 10 mins. while settings are
                            syncing.
                          </p>
                        </span>
                      </div>
                    </div>
                  </Layout.Section>
                </>
              )}
              {app_status == "0" ? (
                <ApplicationStatus header={header} shop_data={shopinfo} />
              ) : (
                ""
              )}
              <Layout.AnnotatedSection title="All Products Pre-Order Status">
                <LegacyCard sectioned>
                  <FormLayout>
                    <FormLayout.Group condensed>
                      <Checkbox
                        label="Enable pre-order for all products using Default settings"
                        checked={checked}
                        onChange={handleChange}
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </LegacyCard>
              </Layout.AnnotatedSection>
            </Layout>
            <div className="text-end pt-3">
              <Button
                onClick={() => handleSave()}
                disabled={is_all_product == checked ? true : false}
                loading={loading}
                primary
              >
                Save
              </Button>
            </div>
            {toastMarkup}
            <Footer />
          </Page>
        </>
      )}
    </>
  );
};

export default AllProduct;
