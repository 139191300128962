import { Page } from "@shopify/polaris";
import MainTable from "./MainTable";
import { useNavigate } from "react-router-dom";

const SubscribedProductPage = () => {
  let navigate = useNavigate();

  let handleOutOfStockClick = () => {
    navigate("/back-in-stock/out-of-stock");
  };

  return (
    <Page
      title="Subscribed products"
      primaryAction={{
        content: "Out of stock products",
        onAction: handleOutOfStockClick,
      }}
    >
      <MainTable />
    </Page>
  );
};

export default SubscribedProductPage;
