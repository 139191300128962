import { useCallback, useEffect, useState } from "react";
import {
  Banner,
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  ChoiceList,
  Collapsible,
  DropZone,
  InlineGrid,
  InlineStack,
  List,
  Text,
  Thumbnail,
} from "@shopify/polaris";
import { DeleteIcon } from "@shopify/polaris-icons";
import { useRecoilState } from "recoil";
import { appearanceHeaderState } from "../../../../store/appearanceSettingAtom";

const HeaderAppearance = ({ isOpen, toggle }) => {
  let [headerData, setHeaderData] = useRecoilState(appearanceHeaderState);
  let [headerContent, setHeaderContent] = useState([
    "header-graphic",
    "header-title",
    "header-description",
    "product-name",
    "product-image",
    "product-price",
  ]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setHeaderData({
      headerContent,
      files,
    });
  }, [files, headerContent]);

  const [rejectedFiles, setRejectedFiles] = useState([]);
  const hasError = rejectedFiles.length > 0;

  const handleDrop = useCallback(
    (_droppedFiles, acceptedFiles, rejectedFiles) => {
      setFiles(acceptedFiles);
      setRejectedFiles(rejectedFiles);
    },
    []
  );

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  let removeFile = (e, index) => {
    e.stopPropagation();
    console.log(e);
    setFiles(files.toSpliced(index, 1));
  };

  const fileUpload = !files.length && (
    <DropZone.FileUpload actionHint="Accepts .gif, .jpg, .png and .svg," />
  );
  const uploadedFiles = files.length > 0 && (
    <Box paddingInline={"300"} paddingBlock={"300"}>
      <BlockStack gap={"300"}>
        {files.map((file, index) => (
          <InlineStack blockAlign="center" gap={"300"} key={index}>
            <Thumbnail
              size="small"
              alt={file.name}
              source={window.URL.createObjectURL(file)}
            />
            <div style={{ marginRight: "auto" }}>
              {file.name}{" "}
              <Text variant="bodySm" as="p">
                {file.size} bytes
              </Text>
            </div>
            <div className="delete-btn" onClick={(e) => removeFile(e, index)}>
              <Button variant="primary" icon={DeleteIcon} />
            </div>
          </InlineStack>
        ))}
      </BlockStack>
    </Box>
  );

  const errorMessage = hasError && (
    <Banner title="The following images couldn’t be uploaded:" tone="critical">
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" is not supported. File type must be .gif, .jpg, .png or .svg.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  return (
    <Card roundedAbove="xs">
      <Bleed marginInline={"400"} marginBlock={"400"}>
        <Button
          variant="tertiary"
          fullWidth
          size="large"
          textAlign="left"
          disclosure={isOpen ? "up" : "down"}
          onClick={toggle}
        >
          <Box paddingBlock={"150"} paddingInline={"100"}>
            <h1 className="h-16">Header</h1>
          </Box>
        </Button>
      </Bleed>
      <Collapsible
        open={isOpen}
        transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
      >
        <Box paddingBlockStart={"400"}>
          <BlockStack gap={"400"}>
            <Text as="p" variant="bodyMd">
              Change the header content here
            </Text>
            <InlineGrid
              gap={{ xs: "400", sm: "400", md: "0", lg: "0", xl: "0" }}
              columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
            >
              <ChoiceList
                allowMultiple
                choices={[
                  {
                    label: "Show header graphic",
                    value: "header-graphic",
                  },
                  { label: "Show header title", value: "header-title" },
                  {
                    label: "Show header description",
                    value: "header-description",
                  },
                ]}
                selected={headerContent}
                onChange={setHeaderContent}
              />
              <ChoiceList
                allowMultiple
                choices={[
                  {
                    label: "Show product information",
                    value: "product-name",
                  },
                  {
                    label: "Show product price",
                    value: "product-price",
                  },
                  {
                    label: "Show product image",
                    value: "product-image",
                  },
                ]}
                selected={headerContent}
                onChange={setHeaderContent}
              />
            </InlineGrid>
            {errorMessage}
            <DropZone
              label="Header graphic"
              accept="image/*"
              type="image"
              onDrop={handleDrop}
              variableHeight
              allowMultiple={false}
            >
              {uploadedFiles}
              {fileUpload}
            </DropZone>
          </BlockStack>
        </Box>
      </Collapsible>
    </Card>
  );
};

export default HeaderAppearance;
