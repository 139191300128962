import React, { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  InlineStack,
  LegacyCard,
  Modal,
  Page,
  Text,
  TextField,
  BlockStack,
} from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";

const SubmissionDetail = () => {
  let navigate = useNavigate();
  let { id } = useParams();

  let [data, setData] = useState({});
  let [note, setNote] = useState("");
  let [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  let [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  let handleBackAction = () => {
    navigate("/back-in-stock");
  };

  let dummyData = {
    email: "abc@abc.com",
    productName: "swiss knife",
  };

  useEffect(() => {
    setData(dummyData);
  }, []);

  let handleSendEmailClick = () => {
    toggleEmailModal();
  };

  let handleDeleteSubmissionClick = () => {
    toggleDeleteModal();
  };

  let toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };
  let toggleEmailModal = () => {
    setIsEmailModalOpen(!isEmailModalOpen);
  };
  let handleDeleteSubmit = () => {
    console.log("handleDeleteSubmit");
  };
  let handleEmailSubmit = () => {
    console.log("handleEmailSubmit");
  };

  let handleSubmit = () => {
    console.log(note);
  };

  return (
    <Page
      title={data.email}
      subtitle="Nov 25 at 02:26 AM"
      backAction={{ onAction: handleBackAction }}
      actionGroups={[
        {
          title: "More actions",
          actions: [
            {
              content: "Send email",
              onAction: handleSendEmailClick,
            },
            {
              content: "Delete submission",
              destructive: true,
              onAction: handleDeleteSubmissionClick,
            },
          ],
        },
      ]}
    >
      <BlockStack gap={"3"}>
        <LegacyCard>
          <Box borderColor="border" borderBlockEndWidth="1" padding={"4"}>
            <InlineStack gap={"4"}>
              <BlockStack gap={"4"}>
                <Text as="h3" variant="headingSm">
                  User email
                </Text>
                <Text as="h3" variant="headingSm">
                  Product
                </Text>
                <Text as="h3" variant="headingSm">
                  SKU
                </Text>
                <Text as="h3" variant="headingSm">
                  Status
                </Text>
                <Text as="h3" variant="headingSm">
                  Locale
                </Text>
                <Text as="h3" variant="headingSm">
                  Subscribed date
                </Text>
                <Text as="h3" variant="headingSm">
                  Email last send
                </Text>
                <Text as="h3" variant="headingSm">
                  Email last subscribed
                </Text>
              </BlockStack>
              <BlockStack gap={"4"}>
                <Text as="p" variant="bodyMd">
                  {data.email}
                </Text>
                <Text as="p" variant="bodyMd">
                  {data.productName}
                </Text>
                <Text as="p" variant="bodyMd">
                  SKU
                </Text>
                <Text as="p" variant="bodyMd">
                  <Badge>Pending</Badge>
                </Text>
                <Text as="p" variant="bodyMd">
                  Default
                </Text>
                <Text as="p" variant="bodyMd">
                  Nov 25 at 02:26 AM
                </Text>
                <Text as="p" variant="bodyMd">
                  Nov 25 at 02:26 AM
                </Text>
                <Text as="p" variant="bodyMd">
                  -
                </Text>
              </BlockStack>
            </InlineStack>
          </Box>
          <Box padding={"4"}>
            <TextField
              type="text"
              label="Note"
              value={note}
              onChange={setNote}
              multiline={5}
            />
          </Box>
        </LegacyCard>
        <InlineStack align="end">
          <Button primary onClick={handleSubmit}>
            Save
          </Button>
        </InlineStack>
      </BlockStack>

      {/* delete confirmation modal */}
      <Modal
        open={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        title="Delete submission?"
        primaryAction={{
          content: "Delete",
          destructive: true,
          onAction: handleDeleteSubmit,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: toggleDeleteModal,
          },
        ]}
      >
        <Box padding={"5"}>
          All submissions data also are removed. Once deleted, it cannot be
          completed. Are you sure you want to delete?
        </Box>
      </Modal>
      {/* email confirmation modal */}
      <Modal
        open={isEmailModalOpen}
        onClose={toggleEmailModal}
        title="Are you sure you want to send email?"
        primaryAction={{
          content: "Send",
          onAction: handleEmailSubmit,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: toggleEmailModal,
          },
        ]}
      >
        <Box padding={"5"}>
          You're about to send out an email, are you sure you want to send it
          out?
        </Box>
      </Modal>
    </Page>
  );
};

export default SubmissionDetail;
