import React, { useCallback, useEffect, useState } from "react";
import {
  InlineStack,
  IndexFilters,
  IndexFiltersMode,
  IndexTable,
  Card,
  Page,
  Text,
  Thumbnail,
  useSetIndexFiltersMode,
} from "@shopify/polaris";
import { useNavigate } from "react-router-dom";

const OutOfStock = () => {
  let navigate = useNavigate();
  let handleBackAction = () => {
    navigate("/back-in-stock");
  };

  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];
  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);
  const [queryValue, setQueryValue] = useState("");

  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );

  const [tableData, setTableData] = useState([]);
  const resourceName = {
    singular: "product",
    plural: "products",
  };

  const dummyData = [
    {
      id: "1",
      name: "product 1",
      imgUrl: "https://picsum.photos/id/1/200",
    },
    {
      id: "2",
      name: "product 2",
      imgUrl: "https://picsum.photos/id/2/200",
    },
    {
      id: "3",
      name: "product 3",
      imgUrl: "https://picsum.photos/id/3/200",
    },
  ];

  useEffect(() => {
    setTableData(dummyData);
  }, []);

  return (
    <Page
      title="Out of stock product"
      backAction={{ onAction: handleBackAction }}
    >
      <Card padding={"0"}>
        <IndexFilters
          sortOptions={sortOptions}
          sortSelected={sortSelected}
          onSort={setSortSelected}
          queryValue={queryValue}
          queryPlaceholder="Search by product title"
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => setQueryValue("")}
          tabs={[]}
          filters={[]}
          mode={mode}
          setMode={setMode}
        />
        <IndexTable
          resourceName={resourceName}
          itemCount={tableData.length}
          selectable={false}
          headings={[{ title: "Products" }]}
        >
          {tableData.map((item, index) => (
            <IndexTable.Row id={item.id} key={item.id} position={index}>
              <IndexTable.Cell>
                <InlineStack blockAlign="center" gap={"500"}>
                  <Thumbnail source={item.imgUrl} size="small" />
                  <Text as="p" variant="bodyMd">
                    {item.name}
                  </Text>
                </InlineStack>
              </IndexTable.Cell>
            </IndexTable.Row>
          ))}
        </IndexTable>
      </Card>
    </Page>
  );
};

export default OutOfStock;
