import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  InlineStack,
  Page,
  BlockStack,
  InlineGrid,
  ChoiceList,
} from "@shopify/polaris";
import HeaderAppearance from "../components/back-in-stock/setting-page/appearance/HeaderAppearance";
import ButtonStyles from "../components/back-in-stock/setting-page/appearance/ButtonStyles";
import SubscribeButtonStyles from "../components/back-in-stock/setting-page/appearance/SubscribeButtonStyles";
import Footer from "../components/back-in-stock/setting-page/appearance/Footer";
import FieldsSetting from "../components/back-in-stock/setting-page/appearance/FieldsSetting";
import { useRecoilState } from "recoil";
import {
  appearanceEmailButtonState,
  appearanceFieldsState,
  appearanceFooterState,
  appearanceHeaderState,
  appearanceSubscribeButtonState,
} from "../store/appearanceSettingAtom";
import Preview from "../components/back-in-stock/setting-page/appearance/Preview";

const BackInStock_setting_appearance = () => {
  let navigate = useNavigate();
  let handleBackAction = () => {
    navigate("/back-in-stock");
  };

  let [showOnProductPge, setShowOnProductPge] = useState(["button_&_popup"]);
  let [showOnCollectionPge, setShowOnCollectionPge] = useState(["hide"]);
  let [accord, setAccord] = useState("");

  let toggleAccord = (name) => {
    if (accord == name) {
      setAccord("");
    } else {
      setAccord(name);
    }
  };

  // all data
  let [headerData] = useRecoilState(appearanceHeaderState);
  let [emailButtonStyle] = useRecoilState(appearanceEmailButtonState);
  let [subscribeButtonStyle] = useRecoilState(appearanceSubscribeButtonState);
  let [fields] = useRecoilState(appearanceFieldsState);
  let [footerData] = useRecoilState(appearanceFooterState);

  let handleSubmit = () => {
    let allData = {
      other: {
        showOnProductPge: showOnProductPge[0],
        showOnCollectionPge: showOnCollectionPge[0],
      },
      header: headerData,
      emailButtonStyle,
      subscribeButtonStyle,
      fields,
      footer: footerData,
    };
    console.log(allData);
  };

  return (
    <Page title="Appearance" backAction={{ onAction: handleBackAction }}>
      <BlockStack gap={"300"}>
        <InlineGrid
          gap={"400"}
          alignItems="start"
          columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
        >
          <div className="order-last order-md-first">
            <BlockStack gap={"400"}>
              <Card roundedAbove="xs">
                <BlockStack gap={"400"}>
                  <ChoiceList
                    title={
                      <h1 className="h-16">Show on product detail page</h1>
                    }
                    choices={[
                      {
                        label: "Show button and popup",
                        value: "button_&_popup",
                      },
                      { label: "Show from", value: "form" },
                      { label: "Hidden", value: "hide" },
                    ]}
                    selected={showOnProductPge}
                    onChange={setShowOnProductPge}
                  />
                  <ChoiceList
                    title={<h1 className="h-16">Show on collection page</h1>}
                    choices={[
                      {
                        label: "Show button and popup",
                        value: "button_&_popup",
                      },
                      { label: "Hidden", value: "hide" },
                    ]}
                    selected={showOnCollectionPge}
                    onChange={setShowOnCollectionPge}
                  />
                </BlockStack>
              </Card>
              <HeaderAppearance
                isOpen={accord == "header"}
                toggle={() => toggleAccord("header")}
              />
              <ButtonStyles
                isOpen={accord == "button1"}
                toggle={() => toggleAccord("button1")}
              />
              <SubscribeButtonStyles
                isOpen={accord == "button2"}
                toggle={() => toggleAccord("button2")}
              />
              <FieldsSetting
                isOpen={accord == "fields"}
                toggle={() => toggleAccord("fields")}
              />
              <Footer
                isOpen={accord == "footer"}
                toggle={() => toggleAccord("footer")}
              />
            </BlockStack>
          </div>
          <Card roundedAbove="xs">
            <Preview showOnProductPge={showOnProductPge[0]} />
          </Card>
        </InlineGrid>
        <InlineStack align="end">
          <Button onClick={handleSubmit} variant="primary">
            Save
          </Button>
        </InlineStack>
      </BlockStack>
    </Page>
  );
};

export default BackInStock_setting_appearance;
