import {
  LegacyCard,
  TextField,
  Icon,
  IndexTable,
  useIndexResourceState,
  Button,
  Modal,
  Text,
  EmptySearchResult,
  Toast,
  DataTable,
  Pagination,
  Badge,
  Banner,
  ButtonGroup,
  Spinner,
  Grid,
  Layout,
  FormLayout,
  Checkbox,
} from "@shopify/polaris";
import { SearchIcon, EditIcon, DeleteIcon } from "@shopify/polaris-icons";
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall, ShopifyCall } from "../helpers/axios";
import { setSingeProductData } from "../redux/singleProductSlice";
import { useNavigate } from "react-router-dom";
import noimg from "../assets/images/noimg.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { setShopData } from "../redux/commonSlice";

const CommonSingleProduct = ({
  productData = null,
  totalPage,
  currentpage,
  GetProductList,
}) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  const is_free = shop_data?.is_freeplan;
  const store_client_id = shop_data.store_client_id;
  const store_name = shop_data.store_name;
  const [searchValue, setSearchValue] = useState("");
  const [productSearchValue, setProductSearchValue] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [active, setActive] = useState(false);
  const [deleteId, setDeleteId] = useState([]);
  const [getProductData, setgetProductData] = useState([]);
  const header = { authentication: shopinfo.auth_token };
  const [activeModel, setActiveModel] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(0);
  const [cursor, setCursor] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [checkType, setcheckType] = useState(0);
  const [searchLoader, setSearchLoader] = useState(false);
  const [loading, setLoading] = useState(false); // Initialize loading state
  const [loadingAll, setLoadingAll] = useState(false); // Initialize loading state
  let is_all_product = shop_data.is_all_product == "0" ? false : true;
  const [checked, setChecked] = useState(false);

  const resourceName = {
    singular: "product",
    plural: "products",
  };

  const emptyStateMarkup = (
    <div className="pt-4">
      <EmptySearchResult
        title={"No products yet"}
        description={"Try changing the filters or search term"}
        withIllustration
      />
    </div>
  );
  const handleChangeAll = useCallback(
    (newChecked) => setChecked(newChecked),
    []
  );
  console.log("common single product");
  const handleSave = async () => {
    setLoadingAll(true);
    let data = {
      shop_info: shop_data,
      is_all_product: checked ? "1" : "0",
    };
    let response = await ApiCall(
      "PUT",
      "/productVariant_bulk_update",
      data,
      header
    );
    if (response.data.statusCode === 200) {
      let shop_Data = Object.assign({}, shop_data);
      shop_Data.is_all_product = checked ? "1" : "0";
      let shopInf = {
        shopInfo: shop_Data,
        auth_token: shop_data.auth_token,
      };
      dispatch(setShopData(shopInf));
      setToastMessage("Update successfully.");
      toggleActiveToast();
      setLoadingAll(false);
    }
  };
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(productData);
  const rowMarkup =
    productData &&
    productData.map((data, index) => (
      <IndexTable.Row
        id={data.id}
        key={data.id}
        selected={selectedResources.includes(data.id)}
        position={index}
      >
        <IndexTable.Cell>
          <div className="d-flex align-items-center">
            {data.product_detail &&
            JSON.parse(data.product_detail) !== "" &&
            JSON.parse(data.product_detail).image ? (
              <>
                <LazyLoadImage
                  width={"40px"}
                  height={"40px"}
                  src={JSON.parse(data.product_detail).image}
                  alt="image"
                />
              </>
            ) : (
              <>
                <LazyLoadImage
                  width={"40px"}
                  height={"40px"}
                  src={`${noimg}`}
                  alt="image"
                />
              </>
            )}
            <div className="px-3">
              {data.product_detail && JSON.parse(data.product_detail).title}
            </div>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Badge
            status={
              data.product_variant_status == 2
                ? "attention"
                : data.product_variant_status == 0
                ? "critical"
                : "success"
            }
          >
            {data.product_variant_status == 1
              ? "enabled"
              : data.product_variant_status == 2
              ? "Pre-order enabled but Inactive on some variants"
              : "disabled"}
          </Badge>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <ButtonGroup noWrap>
            {/* {shop_data.plan_type >= 1 ||
            checkType == 0 || Number(is_free) === 0 || Number(is_free) === 1 ? (
              <div className="edit_btn">
                <Button
                  onClick={() => {
                    navigate("/edit-single-product");
                    dispatch(setSingeProductData(data));
                  }}
                >
                  <Icon source={EditIcon} color="base" />
                </Button>
              </div>
            ) : (
              <div className="edit_btn">
                <Button
                  onClick={() => {
                    navigate("/pricingplan");
                    dispatch(setSingeProductData(data));
                  }}
                  plain
                >
                  <Icon source={EditIcon} color="base" />
                </Button>
              </div>
            )} */}
            <div className="edit_btn">
              <Button
                onClick={() => {
                  navigate("/edit-single-product");
                  dispatch(setSingeProductData(data));
                }}
              >
                <Icon source={EditIcon} color="base" />
              </Button>
            </div>
            <div className="edit_btn">
              <Button
                onClick={() => {
                  handleChange(data.id);
                }}
              >
                <Icon source={DeleteIcon} destructive />
              </Button>
            </div>
          </ButtonGroup>
          <div className="d-flex justify-content-end"></div>
        </IndexTable.Cell>
      </IndexTable.Row>
    ));

  const promotedBulkActions = [
    {
      content: "Delete",
      onAction: () => handleChange(selectedResources),
      icon: DeleteIcon,
      destructive: true,
    },
  ];

  const toggleActiveToast = useCallback(
    () => setActiveToast((active) => !active),
    []
  );

  const toastMarkup = activeToast ? (
    <Toast
      content={toastMessage}
      onDismiss={toggleActiveToast}
      duration={1000}
    />
  ) : null;

  const handleDeleteAction = async (id) => {
    let res = await ApiCall("DELETE", "/single-product", { id: id }, header);
    if (res?.data?.statusCode === 200) {
      productData = null;
      setToastMessage(res.data.message);
      setActive(!active);
      toggleActiveToast();
      GetProductList();
      toggleActive();
      getProductApiList();
    } else {
      setToastMessage(res.data.message);
      setActive(!active);
      toggleActiveToast();
      toggleActive();
      getProductApiList();
    }
    setcheckType(0);
    console.log("checkType", checkType);
  };

  const handleChange = (id, flag = false) => {
    console.log("setcheckType", checkType);
    setDeleteId(id);
    if (flag) {
      setcheckType(0);

      handleDeleteAction(deleteId);
    } else {
      setActive(!active);
    }
  };

  const toggleActive = useCallback(
    () => setActiveModel((activeModel) => !activeModel),
    []
  );

  const getProductApiList = async (query) => {
    let res = "";
    setSearchLoader(true);
    if (Object.keys(shop_data).length > 0) {
      let data = {
        store_client_id: store_client_id,
        shop_info: shop_data,
        shop: store_name,
        cursor: cursor,
        page_query: query ? query : "after",
      };
      if (productSearchValue.length > 0) {
        res = await ShopifyCall(
          "POST",
          `/search-product?search_key=${productSearchValue}`,
          data,
          header
        );
      } else {
        res = await ShopifyCall("POST", `/product-api-list`, data, header);
      }

      const rows = [];
      setcheckType(0);
      if (res?.data?.status === "success" && res?.data?.statusCode === 200) {
        let produts = res?.data?.data?.products;
        setcheckType(res?.data?.data?.check_type);
        if (produts.length > 0) {
          setHasPreviousPage(res?.data?.data?.is_previous_page);
          setHasNextPage(res?.data?.data?.is_next_page);
          setCursor(res?.data?.data?.nxt_page_cursor);
          produts.map(({ featuredImage, title, id }) => {
            rows.push([
              <div className="d-flex align-items-center">
                {featuredImage != "" ? (
                  <>
                    <LazyLoadImage
                      width={"40px"}
                      height={"40px"}
                      src={`${
                        featuredImage ? featuredImage.originalSrc : noimg
                      }`}
                      alt="image"
                    />
                  </>
                ) : (
                  <>
                    <LazyLoadImage
                      width={"40px"}
                      height={"40px"}
                      src={`${noimg}`}
                      alt="image"
                    />
                  </>
                )}
                <div className="px-3">{title}</div>
              </div>,
              <>
                <div className="edit_btn action_edit">
                  {shop_data.plan_type >= 1 ||
                  res?.data?.data?.check_type === 0 ||
                  Number(is_free) === 1 ? (
                    <Button
                      onClick={() =>
                        navigate(
                          `/edit-single-product/${id.split("/").reverse()[0]}`
                        )
                      }
                    >
                      <Icon source={EditIcon} color="base" />
                    </Button>
                  ) : (
                    <Button onClick={() => navigate(`/pricingplan`)}>
                      <Icon source={EditIcon} color="base" />
                    </Button>
                  )}
                </div>
              </>,
            ]);
          });
          setSearchLoader(false);
          setgetProductData(rows);
        } else {
          rows.push([
            <div className="no-data-found">
              No product available for pre-order.
            </div>,
          ]);
          setHasPreviousPage(0);
          setHasNextPage(0);
          setgetProductData(rows);
          setSearchLoader(false);
        }
      } else {
        rows.push([<div className="no-data-found">No data found.</div>]);
        setHasPreviousPage(0);
        setHasNextPage(0);
        setgetProductData(rows);
        setSearchLoader(false);
      }
    }
  };
  const handlePrice = () => {
    navigate("/pricingplan");
  };
  useEffect(() => {
    // Perform actions after checkType becomes 0
    console.log("checkType is now 0");
  }, [checkType]);

  useEffect(() => {
    if (searchValue.length >= 3) {
      setLoading(true); // Set loading to true before calling GetProductList
      GetProductList(currentpage, searchValue).finally(() => {
        setLoading(false); // Set loading to false after GetProductList completes
      });
    } else {
      setLoading(true); // Set loading to true before calling GetProductList
      GetProductList(currentpage).finally(() => {
        setLoading(false); // Set loading to false after GetProductList completes
        setFilteredResults(productData);
      });
    }
  }, [searchValue]);

  useEffect(() => {
    if (productSearchValue.length === 0 || productSearchValue.length > 2) {
      getProductApiList();
    }
  }, [productSearchValue, shop_data]);

  return (
    <>
      {checkType == 1 && Number(is_free) === 0 ? (
        <Banner
          title="Please sign up to our 14 days free trial to unlock preorder for more than 1 product"
          action={{ content: "Upgrade Plan", onAction: handlePrice }}
        >
          <p>
            Subscribe to our status page to be automatically notified of
            scheduled maintenance, or issues that may affect your store.
          </p>
        </Banner>
      ) : (
        ""
      )}
      {/* <Grid>
          <Grid.Cell columnSpan={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
            <Layout>
              <Layout.Section>
                <LegacyCard sectioned>
                  <FormLayout>
                    <FormLayout.Group condensed>
                      <Checkbox
                        label="Enable pre-order for all products using default settings"
                        checked={checked}
                        onChange={handleChangeAll}
                      />
                      {shop_data.plan_type >= 1 || Number(is_free) === 1 ? (
                        <div className="text-end">
                          <Button
                            onClick={() => handleSave()}
                            disabled={is_all_product == checked ? true : false}
                            loading={loadingAll}
                            primary
                          >
                            Save
                          </Button>
                        </div>
                      ) : (
                        <div className="text-end">
                          <Button
                            onClick={() => {
                              navigate("/pricingplan");
                            }}
                            primary
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </FormLayout.Group>
                  </FormLayout>
                </LegacyCard>
              </Layout.Section>
            </Layout>
          </Grid.Cell>
        </Grid> */}
      <div className="card-border-setting">
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <div className="card-border-setting card-flex-item">
              <LegacyCard title="Search for products to make available for pre-order">
                <LegacyCard.Section>
                  <TextField
                    prefix={<Icon source={SearchIcon} color="base" />}
                    value={productSearchValue}
                    onChange={(value) => setProductSearchValue(value)}
                    clearButton
                    onClearButtonClick={() => setProductSearchValue("")}
                    autoComplete="off"
                    placeholder="Search product"
                  />
                  <div className="pt-3 pre-product-list">
                    {searchLoader ? (
                      <Spinner
                        accessibilityLabel="Spinner example"
                        size="large"
                      />
                    ) : (
                      <DataTable
                        columnContentTypes={["text", "text"]}
                        headings={[
                          <div className="preorder_pro_heading">Product</div>,
                          <div className="preorder_pro_heading action_edit">
                            Action
                          </div>,
                        ]}
                        rows={getProductData}
                      />
                    )}
                    <div className="single-product-pagination">
                      {hasNextPage || hasPreviousPage ? (
                        <Pagination
                          hasPrevious={hasPreviousPage}
                          previousTooltip="Previous"
                          onPrevious={() => {
                            getProductApiList("before");
                          }}
                          hasNext={hasNextPage}
                          nextTooltip="Next"
                          onNext={() => {
                            getProductApiList("after");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </LegacyCard.Section>
              </LegacyCard>
            </div>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <div className="card-border-setting card-flex-item">
              {/* <LegacyCard title="Pre-order products" sectioned>
            <TextField
              prefix={<Icon source={SearchIcon} color="base" />}
              value={searchValue}
              onChange={(value) => {
                setSearchValue(value);
              }}
              clearButton
              onClearButtonClick={() => setSearchValue("")}
              autoComplete="off"
              placeholder="Search product"
            />
            {rowMarkup && rowMarkup.length ? (
              <div className="pt-3 pre-order-table pre-order-pro-reponsive">
                <IndexTable
                  resourceName={resourceName}
                  itemCount={
                    searchValue.length > 1
                      ? filteredResults.length
                      : productData?.length
                  }
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources?.length
                  }
                  promotedBulkActions={promotedBulkActions}
                  emptyState={emptyStateMarkup}
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: "Product" },
                    { title: "Status" },
                    { title: "Action" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
                <div className="single-product-pagination">
                  {totalPage > 1 ? (
                    <Pagination
                      hasPrevious={
                        currentpage === 1 || currentpage > totalPage
                          ? false
                          : true
                      }
                      previousTooltip="Previous"
                      onPrevious={() => {
                        GetProductList(currentpage - 1, searchValue);
                      }}
                      hasNext={totalPage === currentpage ? false : true}
                      nextTooltip="Next"
                      onNext={() => {
                        GetProductList(currentpage + 1, searchValue);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              emptyStateMarkup
            )}
          </LegacyCard> */}
              <LegacyCard title="Pre-order products" sectioned>
                <TextField
                  prefix={<Icon source={SearchIcon} color="base" />}
                  value={searchValue}
                  onChange={(value) => {
                    setSearchValue(value);
                  }}
                  clearButton
                  onClearButtonClick={() => setSearchValue("")}
                  autoComplete="off"
                  placeholder="Search product"
                />
                {loading ? ( // Show spinner if loading is true
                  <div className="d-flex justify-content-center pt-3">
                    <Spinner
                      accessibilityLabel="Loading products"
                      size="large"
                    />
                  </div>
                ) : rowMarkup && rowMarkup.length ? (
                  <div className="pt-3 pre-order-table pre-order-pro-reponsive">
                    <IndexTable
                      resourceName={resourceName}
                      itemCount={
                        searchValue.length > 1
                          ? filteredResults.length
                          : productData?.length
                      }
                      selectedItemsCount={
                        allResourcesSelected ? "All" : selectedResources?.length
                      }
                      promotedBulkActions={promotedBulkActions}
                      emptyState={emptyStateMarkup}
                      onSelectionChange={handleSelectionChange}
                      headings={[
                        { title: "Product" },
                        { title: "Status" },
                        { title: "Action" },
                      ]}
                    >
                      {rowMarkup}
                    </IndexTable>
                    <div className="single-product-pagination">
                      {totalPage > 1 ? (
                        <Pagination
                          hasPrevious={
                            currentpage === 1 || currentpage > totalPage
                              ? false
                              : true
                          }
                          previousTooltip="Previous"
                          onPrevious={() => {
                            setLoading(true);
                            GetProductList(
                              currentpage - 1,
                              searchValue
                            ).finally(() => {
                              setLoading(false);
                            });
                          }}
                          hasNext={totalPage === currentpage ? false : true}
                          nextTooltip="Next"
                          onNext={() => {
                            setLoading(true);
                            GetProductList(
                              currentpage + 1,
                              searchValue
                            ).finally(() => {
                              setLoading(false);
                            });
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  emptyStateMarkup
                )}
              </LegacyCard>
            </div>
          </Grid.Cell>
        </Grid>
        <Modal
          open={active}
          onClose={handleChange}
          title="Delete Product"
          primaryAction={{
            content: "Delete",
            destructive: true,
            onAction: () => {
              handleChange("", true);
            },
          }}
          secondaryActions={[
            {
              content: "Cancle",
              onAction: handleChange,
            },
          ]}
        >
          <Modal.Section>
            <Text>
              Are you sure, you want to delete{" "}
              {selectedResources.length > 1
                ? "Selected products"
                : "this product"}
              ?
            </Text>
          </Modal.Section>
        </Modal>
        {toastMarkup}
      </div>
    </>
  );
};

export default CommonSingleProduct;
