import { useState } from "react";
import {
  Badge,
  BlockStack,
  Button,
  InlineStack,
  TextField,
} from "@shopify/polaris";
import { XIcon } from "@shopify/polaris-icons";

const TagsPicker = ({ label, placeholder, tags, onChange }) => {
  let [tagText, setTagText] = useState("");

  let addTag = () => {
    onChange(tags.concat(tagText));
    setTagText("");
  };

  let removeTag = (index) => {
    onChange(tags.toSpliced(index, 1));
  };

  return (
    <BlockStack gap={"200"}>
      <InlineStack gap={"200"} wrap={false} blockAlign="end">
        <div style={{ flexGrow: 1 }}>
          <TextField
            type="text"
            label={label}
            placeholder={placeholder}
            value={tagText}
            onChange={setTagText}
            autoComplete="off"
          />
        </div>
        <Button
          variant="primary"
          onClick={addTag}
          disabled={tagText == ""}
          size="large"
        >
          Add
        </Button>
      </InlineStack>
      {tags.length > 0 && (
        <InlineStack gap={"200"}>
          {tags.map((item, index) => {
            return (
              <Badge key={index}>
                <InlineStack blockAlign="center" gap={"100"}>
                  {item}
                  <Button
                    icon={XIcon}
                    variant="plain"
                    onClick={() => removeTag(index)}
                  />
                </InlineStack>
              </Badge>
            );
          })}
        </InlineStack>
      )}
    </BlockStack>
  );
};

export default TagsPicker;
